import React from 'react';
import SearchComponent from "../../search/search";
import TablePlans from "./../table/table-plans";
import {FilterComponent} from "../../filter/filter";
import ModalAddPlan from "../../4-Configuration/modals/add-plan";
import ModalAddSubCategory from "./../modals/add-subcategory";
import ModalEditSubCategory from "./../modals/edit-subcategory";
import {NotFound} from "../../../commons/errors/notFound";
import {connect} from "react-redux";
import {
    getSubscriptionRequesting,
    getStatesSubscriptionRequesting,
    changeStateSubscriptionRequesting,
    setFocusSubscription,
    searchSubscriptionRequesting,
    errorControlSubscription
} from "../../../redux/subscription/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {
    showModalConfig,
    hiddenModalConfig
} from "../../../redux/config/actions";
import {reduxForm} from "redux-form";
import {toastr} from "react-redux-toastr";

export class ConfigurationSubscriptionComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            getSubscriptionRequesting,
            getStatesSubscriptionRequesting,
            subscription: {filter, paginate},
            client: {token},
        } = this.props;
        getSubscriptionRequesting(paginate, filter, token);
        getStatesSubscriptionRequesting(token);
    }

    handleShowModal = (modal) => {
        const {showModalConfig} = this.props;
        showModalConfig(modal);
    };
    handleHiddenModal = () => {
        const {hiddenModalConfig} = this.props;
        hiddenModalConfig();
    };
    handleErrorModal = () => {
        const {subscription: {error}, errorControlSubscription} = this.props;

        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlSubscription();
    };
    handleSearch = (value) => {
        const {searchSubscriptionRequesting, client: {token}, subscription: {search}} = this.props;
        if ((search !== value.search) && value.search !== '')
            searchSubscriptionRequesting(value.search, token);
    };
    handleFilter = (value, type) => {
        const {getSubscriptionRequesting, subscription: {filter, paginate}, client: {token}} = this.props;
        if (filter !== value)
            getSubscriptionRequesting(paginate, value, token)
    };

    handleGetData = (paginate) => {
        const {getSubscriptionRequesting, paginateSubscription, subscription: {filter, hasMore, hasLess}, client: {token}} = this.props;
        if (paginate === 1 && hasMore)
            getSubscriptionRequesting(paginate + paginateSubscription, filter, token);
        if (paginate === -1 && hasLess && paginateSubscription > 1)
            getSubscriptionRequesting(paginate + paginateSubscription, filter, token);
    };
    handleChangeStateSubscription = (id) => {
        const {changeStateSubscriptionRequesting, client: {token}} = this.props;
        changeStateSubscriptionRequesting(id, token);
    };

    handleGetCsv = () => {
        const {me: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/exports/subscriptions?email=${user.correo}`, "_self")
    };

    handleFocusSubscription = (subscription) => {
        const {setFocusSubscription} = this.props;
        setFocusSubscription(subscription);
    };



    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            subscription: {
                subscriptions,
                statesSubscription,
                search,
                subscriptionsSearch,
                errorSubscription
            },
            config: {
                isModalAddSubscription,
            }
        } = this.props;
        return (
            <div className="styles-container">
                {errorSubscription && this.handleErrorModal()}
                <SearchComponent
                    type={'Configuración planes'}
                    placeholder={'Consultar en plan'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {search !== undefined && search !== '' && (
                        subscriptionsSearch.length > 0 ?
                            <TablePlans
                                subscriptions={subscriptionsSearch}
                                changeState={this.handleChangeStateSubscription.bind(this)}
                                focusSubscription={this.handleFocusSubscription.bind(this)}
                            /> : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'plan'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        handleGetData={this.handleGetData.bind(this)}
                        states={statesSubscription}
                    />
                    {subscriptions.length > 0 ?
                        (
                            <TablePlans
                                subscriptions={subscriptions}
                                changeState={this.handleChangeStateSubscription.bind(this)}
                                focusSubscription={this.handleFocusSubscription.bind(this)}
                            />
                        )
                        :
                        <NotFound/>
                    }
                    {isModalAddSubscription && (
                        <ModalAddPlan
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    subscription: state.subscription,
    client: state.client,
    paginateSubscription: state.subscription.paginate,
    config: state.config,
    me: state.me,
});
const connected = connect(mapStateToProps, {
    getSubscriptionRequesting,
    getStatesSubscriptionRequesting,
    changeStateSubscriptionRequesting,
    setFocusSubscription,
    searchSubscriptionRequesting,
    showModalConfig,
    hiddenModalConfig,
    errorControlSubscription,
})(ConfigurationSubscriptionComponent);

const formed = reduxForm({
    form: 'ConfigurationComponent'
})(connected);

export default formed;
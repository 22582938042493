import React from 'react';

export class TableSubCategories extends React.Component {

    render() {
        const {subcategories, handleChangeState, handleShowModal, handleFocus} = this.props;
        return (
            <div className="Conteiner-table">

                <table className="table-info-product1">
                    <thead>
                    <tr>
                        <th>Subcategoría</th>
                        <th>Foto</th>
                        <th>Categoría</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {subcategories.map((subcategory, index) => (
                        <tr key={index}>
                            <td>{subcategory.nombre}</td>
                            {subcategory.foto !== false && (
                                <td><img style={{width: '40%'}} src={subcategory.foto} alt={subcategory.nombre}/></td>
                            )}
                            {subcategory.foto === false && (
                                <td>Sin foto</td>
                            )}
                            <td>{subcategory.categoria.nombre}</td>
                            <td>
                                <input className="style-checkbox" type="checkbox"
                                       onClick={() => handleChangeState(subcategory.id)}
                                       checked={subcategory.estado.nombre === 'Habilitado'}
                                />
                                <p className={subcategory.estado.nombre !== 'Habilitado' ? "style-checkbox-on" : "style-checkbox-off"}>
                                    {subcategory.estado.nombre}
                                </p>
                            </td>
                            <td>
                                <button className="Btn-item-table"
                                        onClick={() => {
                                            handleFocus(subcategory);
                                            handleShowModal('edit');
                                        }}>
                                    Editar
                                    <i className="icon-pencil styles-icons"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

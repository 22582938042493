import {
    CHANGE_STATE_USER_ERROR,
    CHANGE_STATE_USER_REQUESTING,
    CHANGE_STATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    CREATE_USER_REQUESTING,
    CREATE_USER_SUCCESS,
    GET_STATE_USER_ERROR,
    GET_STATE_USER_REQUESTING,
    GET_STATE_USER_SUCCESS,
    GET_USER_ERROR,
    GET_USER_REQUESTING,
    GET_USER_SUCCESS,
    SEARCH_USER_ERROR,
    SEARCH_USER_REQUESTING,
    SEARCH_USER_SUCCESS,
    SET_FOCUS_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_REQUESTING,
    UPDATE_USER_SUCCESS,
    SHOW_MODAL_USER,
    HIDDEN_MODAL_USER,
    SHOW_MODAL_EDIT_USER,
    HIDDEN_MODAL_EDIT_USER,
    ERROR_USER, SHOW_MODAL_REVIEW_USER,
    HIDDEN_MODAL_REVIEW_USER,
    UPDATE_ADMIN_ERROR,
    UPDATE_ADMIN_REQUESTING,
    UPDATE_ADMIN_SUCCESS,
    ERROR_ADMiN
} from "./constants";

export const getUserRequesting = (cursor, filter, token) => ({
    type: GET_USER_REQUESTING,
    cursor,
    filter,
    token
});

export const getUserSuccess = (users) => ({
    type: GET_USER_SUCCESS,
    users
});

export const getUserError = (error) => ({
    type: GET_USER_ERROR,
    error
});

export const getStateUserRequesting = (token) => ({
    type: GET_STATE_USER_REQUESTING,
    token
});

export const getStateUserSuccess = (states) => ({
    type: GET_STATE_USER_SUCCESS,
    states
});

export const getStateUserError = (error) => ({
    type: GET_STATE_USER_ERROR,
    error
});

export const changeStateUserRequesting = (userId, token) => ({
    type: CHANGE_STATE_USER_REQUESTING,
    userId,
    token
});

export const changeStateUserSuccess = (user) => ({
    type: CHANGE_STATE_USER_SUCCESS,
    user
});

export const changeStateUserError = (error) => ({
    type: CHANGE_STATE_USER_ERROR,
    error
});

export const searchUserRequesting = (search, token) => ({
    type: SEARCH_USER_REQUESTING,
    search,
    token
});

export const searchUserSuccess = (user) => ({
    type: SEARCH_USER_SUCCESS,
    user
});

export const searchUserError = (error) => ({
    type: SEARCH_USER_ERROR,
    error
});

export const createUserRequesting= (user, token) => ({
    type: CREATE_USER_REQUESTING,
    user,
    token
});

export const createUserSuccess = (userCreated) => ({
    type: CREATE_USER_SUCCESS,
    userCreated
});

export const createUserError =(error) => ({
    type: CREATE_USER_ERROR,
    error
});

export const setFocusUser = (id = 0, user = {}) => ({
    type: SET_FOCUS_USER,
    id,
    user
});

export const updateUserRequesting = (user, token, focusUser) => ({
    type: UPDATE_USER_REQUESTING,
    user,
    token,
    focusUser
});

export const updateUserSuccess = (userUpdate) => ({
    type:  UPDATE_USER_SUCCESS,
    userUpdate
});

export const updateUserError = (error) => ({
    type: UPDATE_USER_ERROR,
    error
});
export const showModalUser = ()=>({
    type: SHOW_MODAL_USER
});

export const hiddenModalUser =()=>({
    type: HIDDEN_MODAL_USER
});

export const showEditModalUser = ()=>({
    type: SHOW_MODAL_EDIT_USER
});

export const hiddenEditModalUser =()=>({
    type: HIDDEN_MODAL_EDIT_USER
});
export const errorControlUser =()=>({
    type: ERROR_USER,
});
export const errorControlAdmin =()=>({
    type: ERROR_ADMiN,
});

export const showReviewModalUser = () => ({
    type: SHOW_MODAL_REVIEW_USER
});

export const hiddenReviewModalUser = () => ({
    type: HIDDEN_MODAL_REVIEW_USER
});

export const updateAdminRequesting = (token,admin) => ({
    type: UPDATE_ADMIN_REQUESTING,
    token,
    admin
});

export const updateAdminSuccess = (adminUpdate) => ({
    type:  UPDATE_ADMIN_SUCCESS,
    adminUpdate
});

export const updateAdminError = (error) => ({
    type: UPDATE_ADMIN_ERROR,
    error
});

import React from 'react';
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import ModuleSelectComponent from './../module/select';
import CategorySelectComponent from './../category/select';

class Filter3Component extends React.Component {

    handleSelectFilter = () => {
        const {
            stateForm: {FilterPublication},
            handleFilter,
        } = this.props;
        handleFilter(FilterPublication.values);
    };

    render() {
        const {
            stateForm: {FilterPublication},
            publication: {moduleId}
        } = this.props;
        return (
            <div className="Btn-general-filter">
                {FilterPublication !== undefined && FilterPublication.hasOwnProperty('values') && FilterPublication.values.module !== undefined && this.handleSelectFilter()}
                <div className={moduleId === 'Todos' ? "container-add-direction3" : "container-add-direction4"}>
                    <form>
                        <div className="div-btn-filter">
                            <ModuleSelectComponent type={'filter'}/>
                        </div>
                    </form>
                    {moduleId !== 'Todos' && (
                    <form>
                            <div className="div-btn-filter">
                                <CategorySelectComponent type={'filter'}/>
                            </div>
                    </form>
                    )}
                    <div className="div-btn-add">
                        <button className="Btn-general-add" onClick={() => this.props.handleGetCsv()}>
                            Descargar csv
                            <i className="icon-folder-open-empty styles-icons"/>
                        </button>
                    </div>
                    <div className="div-btn-direction">
                        <button className="Btn-general-direction" onClick={() => this.props.handleGetData(-1)}>
                            <i className="icon-left-big styles-icons"/>
                            Atrás
                        </button>
                        <button className="Btn-general-direction" onClick={() => this.props.handleGetData(1)}>
                            Adelante
                            <i className="icon-right-big styles-icons"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'FilterPublication',
    enableReinitialize: true
})(Filter3Component);

const mapStateToProps = state => ({
    client: state.client,
    stateForm: state.form,
    publication: state.publication
});

const connected = connect(mapStateToProps, {})(formed);

export default connected;
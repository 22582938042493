import {handleApiErrors} from "./../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {getCityError, getCitySuccess} from "./actions";
import {GET_CITY_REQUESTING} from "./constants";

const CITY_URL = `${process.env.REACT_APP_API_URL}/cities`;

const getCityAPI = () => {

    return fetch(CITY_URL, {
        method: 'GET'
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
        throw error;
    })
};

function* getCityFlow(action) {
    try{
        const {} = action;
        const cities = yield call(getCityAPI);
        yield put(getCitySuccess(cities));
    }catch (error) {
        yield put(getCityError(error));
    }
}

function* cityWatcher() {
    yield all([
        takeEvery(GET_CITY_REQUESTING, getCityFlow)
    ])
}

export default cityWatcher;
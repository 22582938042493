import {
    SHOW_MODAL_CONFIG,
    HIDDEN_MODAL_CONFIG
} from './constants';

const initialState = {
    isModalAddCategory: false,
    isModalAddSubCategory: false,
    isModalAddSubscription: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL_CONFIG :

            let visiblePlan=false;
            let visibleCategory=false;
            let visibleSubcategory=false;

            if (action.modal === 'plan')
                visiblePlan = true;
            if (action.modal === 'categoria')
                visibleCategory = true;
            if (action.modal === 'sub categoria')
                visibleSubcategory = true;
            return {
                ...state,
                isModalAddCategory: visibleCategory,
                isModalAddSubCategory: visibleSubcategory,
                isModalAddSubscription: visiblePlan,
            };
        case HIDDEN_MODAL_CONFIG :
            return {
                ...state,
                isModalAddCategory: false,
                isModalAddSubCategory: false,
                isModalAddSubscription: false,
            };
        default :
            return state;
    }
};

export default reducer;

import {GET_REVIEW_ERROR, GET_REVIEW_REQUESTING, GET_REVIEW_SUCCESS} from "./constants";

export const getReviewRequesting = (token, id) => ({
    type: GET_REVIEW_REQUESTING,
    token,
    id
});

export const getReviewSuccess = (reviews) => ({
    type: GET_REVIEW_SUCCESS,
    reviews
});

export const getReviewError = (error) => ({
    type: GET_REVIEW_ERROR,
    error
});
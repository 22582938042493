import {
    GET_ALL_PAYMENT_METHOD_ERROR,
    GET_ALL_PAYMENT_METHOD_REQUESTING,
    GET_ALL_PAYMENT_METHOD_SUCCESS
} from "./constants";


export const getAllPaymentMethodRequesting=(token) =>({
    type: GET_ALL_PAYMENT_METHOD_REQUESTING,
    token
});

export const getAllPaymentMethodSuccess=(paymentMethods) =>({
    type: GET_ALL_PAYMENT_METHOD_SUCCESS,
    paymentMethods
});

export const getAllPaymentMethodError=(error) =>({
    type: GET_ALL_PAYMENT_METHOD_ERROR,
    error
});
import React from 'react';
import {reduxForm} from "redux-form/lib/immutable";
import {connect} from "react-redux";
import {getReviewRequesting} from "../../../redux/review/actions";
import {NotFound} from "../../../commons/errors/notFound";

class ModalReviewUser extends React.Component {

    constructor(props) {
        super(props);
        const {getReviewRequesting,
        client: {token},
        user: {userDataFocus}} = this.props;
        getReviewRequesting(token, userDataFocus.id);
    }

    render() {
        const {review: {reviews}} = this.props;
        console.log(reviews);
        return (
            <div style={divStyle} className={'body-blur'} onClick={() => this.props.hidden()}>
                <div style={divStyle} className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="Conteiner-table">
                        {reviews.length > 0 ?
                        <table className="table-orders">
                            <thead>
                            <tr>
                                <th>Mensaje</th>
                                <th>Calificación</th>
                                <th>Usuario</th>
                                <th>Publicación</th>
                            </tr>
                            </thead>
                            <tbody>
                            {reviews.map((review, index) => (
                                <tr key={index}>
                                    <td>{review.mensaje}</td>
                                    <td>{review.puntuacion}</td>
                                    <td>{review.usuario_emisor.nombre}</td>
                                    <td>{review.publicacion.nombre}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                            : <NotFound/>}
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={() => this.props.hidden()}>Salir<i
                                    className="icon-cancel styles-icons"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const divStyle = {
    height: '300px',
};

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    review: state.review
});

const connected = connect(mapStateToProps, {
    getReviewRequesting
})(ModalReviewUser);

const formed = reduxForm({
    form: 'ReviewComponent'
})(connected);

export default formed;
import {
    GET_SUBSCRIPTION_REQUESTING,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    CHANGE_STATE_SUBSCRIPTION_ERROR,
    CHANGE_STATE_SUBSCRIPTION_REQUESTING,
    CHANGE_STATE_SUBSCRIPTION_SUCCESS,
    GET_STATES_SUBSCRIPTION_ERROR,
    GET_STATES_SUBSCRIPTION_REQUESTING,
    GET_STATES_SUBSCRIPTION_SUCCESS,
    CREATE_SUBSCRIPTION_ERROR,
    CREATE_SUBSCRIPTION_REQUESTING,
    CREATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION_REQUESTING,
    UPDATE_SUBSCRIPTION_SUCCESS,
    SEARCH_SUBSCRIPTION_ERROR,
    SEARCH_SUBSCRIPTION_REQUESTING,
    SEARCH_SUBSCRIPTION_SUCCESS,
    SET_FOCUS_SUBSCRIPTION,
    ERROR_SUBSCRIPTION,
    SHOW_MODAL_EDIT_SUBSCRIPTION,
    HIDDEN_MODAL_EDIT_SUBSCRIPTION,
    GET_ALL_SUBSCRIPTION_SUCCESS,
    GET_ALL_SUBSCRIPTION_REQUESTING,
    GET_ALL_SUBSCRIPTION_ERROR
} from './constants';

const initialState = {
    requesting: false,
    success: false,
    error: '',
    hasMore: true,
    hasLess: false,
    search: '',
    paginate: 1,
    filter: 'Todos',
    allSubscriptions:[],
    subscriptions: [],
    subscriptionsSearch: [],
    subscriptionFocus: [],
    subscriptionId: 0,
    statesSubscription: [],
    errorSubscription : false,
    isModalEditSubscription: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                error: '',
                success: false,
                requesting: false
            };
        case GET_ALL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                allSubscriptions: action.subscriptions,
                error: '',
                success: true,
                requesting: false
            };
        case GET_ALL_SUBSCRIPTION_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                AllSubscriptions: []
            };
        case GET_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                paginate: action.paginate,
                filter: action.filter,
                error: '',
                success: false,
                requesting: false
            };
        case GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptions: action.subscriptions,
                hasLess: state.paginate + 1 > 1,
                hasMore: true,
                error: '',
                success: true,
                requesting: false
            };
        case GET_SUBSCRIPTION_ERROR:
            return {
                ...state,
                error: action.error,
                hasMore: false,
                success: false,
                requesting: false,
                subscriptions: []
            };
        case SEARCH_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                search: action.search
            };
        case SEARCH_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                subscriptionsSearch: action.subscriptions
            };
        case SEARCH_SUBSCRIPTION_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                subscriptionsSearch: []
            };
        case CHANGE_STATE_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                subscriptionId: action.subscriptionId
            };
        case CHANGE_STATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                subscriptions: state.subscriptions.map(subscription => (
                    subscription.id === action.subscription.id ? action.subscription : subscription
                )),
                subscriptionsSearch: state.subscriptionsSearch.length > 0 && state.subscriptionsSearch.map(subscription => (
                    subscription.id === action.subscription.id ? action.subscription : subscription
                ))
            };
        case CHANGE_STATE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        case CREATE_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case CREATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptions: [action.subscription, ...state.subscriptions],
                requesting: false,
                success: true,
                error: ''
            };
        case CREATE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case UPDATE_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case UPDATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                subscriptionsSearch: state.subscriptionsSearch.map((subscription) => subscription.id === action.subscription.id ?
                    action.subscription : subscription),
                subscriptions: state.subscriptions.map((subscription) => subscription.id === action.subscription.id ? action.subscription : subscription)
            };
        case UPDATE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case GET_STATES_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case GET_STATES_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                statesSubscription: action.states
            };
        case GET_STATES_SUBSCRIPTION_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false
            };
        case SET_FOCUS_SUBSCRIPTION :
            return {
                ...state,
                subscriptionFocus: action.subscription,
                subscriptionId : action.subscription.id
            };
        case ERROR_SUBSCRIPTION:
            return {
                ...state,
                errorSubscription: !state.errorSubscription
            };
        case SHOW_MODAL_EDIT_SUBSCRIPTION :
            return {
              ...state,
                isModalEditSubscription: true,
            };
        case HIDDEN_MODAL_EDIT_SUBSCRIPTION :
            return {
                ...state,
                isModalEditSubscription: false
            };
        default :
            return state;
    }
};

export default reducer;

import React, {Component} from 'react';
import {ModalEditProduct} from "../modals/edit-product";
import {FormattedNumber} from 'react-intl';
import Carousel, {Modal, ModalGateway} from 'react-images';

export class TableProduct extends Component {

    state = {
        carouselOpen: false,
        photos: [],
        currentImage: 0,
        isInfoProduct: false,
    };

    handleViewPhotos = (object) => {
        let photos = [];
        if (object.fotos.length > 0) {
            object.fotos.map(photo => {
                photos.push({src: photo.url})
            });
        }
        this.setState({
            carouselOpen: true,
            photos: photos,
            photosCurrent: object.fotos,
        })
    };

    closeCarousel = () => {
        this.setState({
            currentImage: 0,
            carouselOpen: false,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };

    handleShowModal = (modal) => {
        this.setState({
            isInfoProduct: modal === 'detalle de producto',
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isInfoProduct: false,
        })
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            publications,
            handleFocus,
            handleShowModal,
            handleEnable,
            handleDeletePhoto,
        } = this.props;
        const {photos, carouselOpen, currentImage, photosCurrent} = this.state;
        return (
            <div className="Conteiner-table">
                <ModalGateway>
                    {carouselOpen && (
                        <Modal allowFullscreen={false}
                               closeOnBackdropClick={false}
                               onClose={this.closeCarousel}>
                            <Carousel
                                views={photos}
                                onClickPrev={this.gotoPrevious}
                                onClickNext={this.gotoNext}
                                onClose={this.closeCarousel}
                                imageCountSeparator={' / '}
                                frameProps={{autoSize: 'height'}}
                                currentImage={currentImage}
                                backdropClosesModal={true}
                                customControls={[
                                    <i className="icon-trash"
                                       style={{color: 'white', fontSize: 28, marginLeft: 25, cursor: 'pointer'}}
                                       onClick={() => handleDeletePhoto(photosCurrent[currentImage].id)}
                                    />
                                ]}
                            />
                        </Modal>
                    )}
                </ModalGateway>
                {this.state.isInfoProduct && (
                    <ModalEditProduct
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                <table className="table-providers">
                    <thead>
                    <tr>
                        <th>Identificación</th>
                        <th>Nombre</th>
                        <th>Descripcíon</th>
                        <th>Categoría</th>
                        <th>Subcategoría</th>
                        <th>Marca</th>
                        <th>Ciudad</th>
                        <th>Dimensiones</th>
                        <th>Peso</th>
                        <th>Precio anterior</th>
                        <th>Descuento</th>
                        <th>Precio con descuento</th>
                        <th>Valor de envío</th>
                        <th>Cantidad disponible</th>
                        <th>Tipo de productos</th>
                        <th>Fotos</th>
                        <th>Observación deshabilitación</th>
                        <th>Estado</th>
                    </tr>
                    </thead>
                    <tbody>
                    {publications.map((publication, index) => (
                        <tr key={index}>
                            <td>{publication.id}</td>
                            <td>{publication.nombre}</td>
                            <td>{publication.descripcion}</td>
                            <td>{publication.categoria.nombre}</td>
                            <td>{publication.subcategoria.nombre}</td>
                            <td>{publication.marca}</td>
                            <td>{publication.vendedor.ciudad.nombre}</td>
                            <td>{publication.dimensiones}</td>
                            <td>{publication.peso}</td>
                            <td><FormattedNumber value={publication.precio} style={'currency'} currency={'USD'}
                                                 minimumFractionDigits={0} maximumFractionDigits={0}/></td>
                            <td><FormattedNumber value={publication.precio - publication.precio_descuento} style={'currency'} currency={'USD'}
                                                 minimumFractionDigits={0} maximumFractionDigits={0}/></td>
                            <td><FormattedNumber value={publication.precio_descuento} style={'currency'} currency={'USD'}
                                                 minimumFractionDigits={0} maximumFractionDigits={0}/>
                            </td>                     
                            <td><FormattedNumber value={publication.valor_envio} style={'currency'} currency={'USD'}
                                                 minimumFractionDigits={0} maximumFractionDigits={0}/>
                            </td>
                            <td>{publication.cantidad}</td>
                            <td>{publication.tipo_modulo.tipo}</td>
                            <td>
                                {publication.fotos.length > 0 && (
                                    <button className="Btn-item-table"
                                            onClick={() => this.handleViewPhotos(publication)}>
                                        Ver fotos ({publication.fotos.length})
                                    </button>
                                )}
                                {publication.fotos.length === 0 && (
                                    'Sin fotos'
                                )}
                            </td>
                            <td>{publication.observacion}</td>
                            <td>
                                {publication.estado.nombre === 'Habilitado' && (
                                    <button className="Btn-item-table"
                                            onClick={() => {
                                                handleShowModal('disable');
                                                handleFocus(publication);
                                            }}>
                                        Inhabilitar
                                        <i className="icon-pencil styles-icons"/>
                                    </button>
                                )}
                                {publication.estado.nombre === 'Inhabilitado' && (
                                    <div>
                                        <button className="Btn-item-table" onClick={() => handleEnable(publication.id)}>
                                            Habilitar
                                            <i className="icon-pencil styles-icons"/>
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

import {GET_ROL_ERROR, GET_ROL_REQUESTING, GET_ROL_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    roles: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROL_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case GET_ROL_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                roles: action.roles
            };
        case GET_ROL_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                roles: []
            };
        default:
            return state;
    }
};

export default reducer;
import {ME_GET_ERROR, ME_GET_REQUESTING, ME_GET_SUCCESS} from "./constants";

export const meGetRequesting = (token) => ({
    type: ME_GET_REQUESTING,
    token
});

export const meGetSuccess = (user) => ({
    type: ME_GET_SUCCESS,
    user,
});

export const meGetError = (error) => ({
    type: ME_GET_ERROR,
    error,
});
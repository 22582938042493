//suscripciones usuario

export const SHOW_MODAL_SUBSCRIPTION_USER ='SHOW_MODAL_SUBSCRIPTION_USER';
export const HIDDEN_MODAL_SUBSCRIPTION_USER ='HIDDEN_MODAL_SUBSCRIPTION_USER ';

export const GET_SUBSCRIPTION_USER_REQUESTING = 'GET_SUBSCRIPTION_USER_REQUESTING';
export const GET_SUBSCRIPTION_USER_SUCCESS = 'GET_SUBSCRIPTION_USER_SUCCESS';
export const GET_SUBSCRIPTION_USER_ERROR = 'GET_SUBSCRIPTION_USER_ERROR';

export const CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING ='CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING';
export const CHANGE_STATE_SUBSCRIPTION_USER_SUCCESS ='CHANGE_STATE_SUBSCRIPTION_USER_SUCCESS';
export const CHANGE_STATE_SUBSCRIPTION_USER_ERROR ='CHANGE_STATE_SUBSCRIPTION_USER_ERROR';

export const CREATE_SUBSCRIPTION_USER_REQUESTING ='CREATE_SUBSCRIPTION_USER_REQUESTING';
export const CREATE_SUBSCRIPTION_USER_SUCCESS ='CREATE_SUBSCRIPTION_USER_SUCCESS';
export const CREATE_SUBSCRIPTION_USER_ERROR ='CREATE_SUBSCRIPTION_USER_ERROR';

export const SEARCH_SUBSCRIPTION_USER_REQUESTING='SEARCH_SUBSCRIPTION_USER_REQUESTING';
export const SEARCH_SUBSCRIPTION_USER_SUCCESS='SEARCH_SUBSCRIPTION_USER_SUCCESS';
export const SEARCH_SUBSCRIPTION_USER_ERROR='SEARCH_SUBSCRIPTION_USER_ERROR';

//Buscar usuario al crear suscripcion
export const SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING='SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING';
export const SEARCH_USER_CREATE_SUBSCRIPTION_SUCCESS='SEARCH_USER_CREATE_SUBSCRIPTION_SUCCESS';
export const SEARCH_USER_CREATE_SUBSCRIPTION_ERROR='SEARCH_USER_CREATE_SUBSCRIPTION_ERROR';

export const ERROR_SUBSCRIPTION_USER='ERROR_SUBSCRIPTION_USER';
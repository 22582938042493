import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    changeStateUserError,
    changeStateUserSuccess, createUserError, createUserSuccess,
    getStateUserError,
    getStateUserSuccess,
    getUserError,
    getUserSuccess,
    searchUserError,
    searchUserSuccess,
    updateUserError,
    updateUserSuccess,
    updateAdminError,
    updateAdminSuccess,
    hiddenModalUser,
    hiddenEditModalUser,
    errorControlUser,
    errorControlAdmin
} from "./actions";
import {controlModalAdmin} from "../client/actions";
import {toastr} from "react-redux-toastr";
import {
    CHANGE_STATE_USER_REQUESTING,
    CREATE_USER_REQUESTING,
    GET_STATE_USER_REQUESTING,
    GET_USER_REQUESTING,
    SEARCH_USER_REQUESTING,
    UPDATE_USER_REQUESTING,
    UPDATE_ADMIN_REQUESTING
} from "./constants";

const USERS_URL = `${process.env.REACT_APP_API_URL}/users`;
const ADMIN_URL = `${process.env.REACT_APP_API_URL}/admin`;
const STATE_USER_URL = `${process.env.REACT_APP_API_URL}/states/user`;

const getUserAPI = (cursor, filter, token) => {
    const URL = `${USERS_URL}/filter/${filter}?page=${cursor}`;
    return fetch(URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.code === 400)
                throw  json.data.data;
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getUsersFlow(action) {
    try{
        const {cursor, filter, token} = action;
        const users = yield call(getUserAPI, cursor, filter, token);
        yield put(getUserSuccess(users));
    }catch (error) {
        yield put(getUserError(error))
    }
}

const getStateUserAPI = (token) => {
    return fetch(STATE_USER_URL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getStateUserFlow(action){
    try {
        const {token} = action;
        const states = yield call(getStateUserAPI, token);
        yield put(getStateUserSuccess(states));
    }catch (error) {
        yield put(getStateUserError(error))
    }
}

const statesUserApi = (userId, token) => {
    const URL = `${USERS_URL}/state/${userId}`;
    return fetch(URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};

function* statesUserFlow(action) {
    try{
        const {userId, token} = action;
        const user = yield call(statesUserApi, userId, token);
        yield put(changeStateUserSuccess(user));
    }catch (error) {
        yield put(changeStateUserError(error));
    }
}

const searchUserApi = (search, token) => {
    const URL = `${USERS_URL}/search`;

    let body = {
        search: search
    };

    return fetch(URL,  {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* searchUserFlow(action) {
    try {
        const {search, token} = action;
        const user = yield call(searchUserApi, search, token);
        yield put(searchUserSuccess(user));
    }catch (error) {
        yield put(searchUserError(error));
    }
}

const createUserAPI = (user, token) => {

    let body = new FormData();
    let typePeople=  user.type_people !=='type_people' && user.type_people!==undefined ? JSON.parse(user.type_people): '';
    body.append('name', user.name);
    body.append('identification', user.identification);
    body.append('password', user.password);
    body.append('phone', user.phone);
    body.append('email', user.email);
    body.append('address', user.address);
    body.append('person_contact', user.person_contact);
    body.append('phone_contact', user.phone_contact);
    body.append('type_profile', user.type_profile);
    body.append('gender', user.type_gender);
    body.append('type_people_name', typePeople ? typePeople.nombre :'');
    body.append('type_people', typePeople ? typePeople.id :'');
    if(typePeople.nombre==='Profesional')
        body.append('type_professional',  user.type_professional);
    if (user.hasOwnProperty('photos') && user.photos.length > 0) {
        user.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', user.photos.length || '');
    }
    body.append('rol', user.rol);
    body.append('city', user.city);



    // console.log(body);
    return fetch(USERS_URL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createUserFlow(action) {
    try{
        const {user, token} = action;
        const userCreated = yield call(createUserAPI, user, token);
        yield put(createUserSuccess(userCreated));
        yield put(hiddenModalUser());
        toastr.success('Usuario creado', 'Usuario creado, ya puede ver los usuarios');
    }catch (error) {
        yield put(createUserError(error));
        yield put(errorControlUser());
    }
}

const userUpdateAPI = (user, token, focusUser) => {

    let body = new FormData();
    let typePeople=  user.tipo_persona ? user.tipo_persona.id ?  user.tipo_persona : JSON.parse(user.tipo_persona) : '';
    body.append('_method', 'PUT');
    body.append('name', user.nombre || '');
    body.append('identification', user.identificacion || '');
    body.append('phone', user.telefono || '');
    body.append('email', user.correo || '');
    body.append('address', user.direccion || '');
    body.append('person_contact', user.persona_contacto || '');
    body.append('phone_contact', user.telefono_contacto);
    body.append('type_profile', user.tipo_perfil.id || user.tipo_perfil);
    body.append('gender', user.genero.id || user.genero);
    body.append('type_people_name', typePeople ? typePeople.nombre :'');
    body.append('type_people', typePeople ? typePeople.id :'');
    if(typePeople.nombre==='Profesional')
        body.append('type_professional',  user.tipo_profesional.id || user.tipo_profesional);
    if (user.hasOwnProperty('photos') && user.photos.length > 0) {
        user.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', user.photos.length || '');
    }else {
        body.append('photos_length', 0);
    }
    body.append('city',   user.ciudad.id || user.ciudad );
    body.append('rol', user.rol.id || user.rol);


    return fetch(`${USERS_URL}/${focusUser.id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateUserFlow(action) {
    try {
        const {user, token, focusUser} = action;
        const userUpdate = yield call(userUpdateAPI, user, token, focusUser);
        yield put(updateUserSuccess(userUpdate));
        yield put(hiddenEditModalUser());
        toastr.success('Usuario editado', 'Usuario editado, ya puede ver los usuarios');
    } catch (error) {
        yield put(updateUserError(error));
        yield put(errorControlUser());
    }
}

const adminUpdateAPI = (token,admin) => {

    let body = new FormData();
    body.append('_method', 'PUT');
    body.append('password', admin.contrasena || '');
    body.append('email', admin.correo || '');

    return fetch(ADMIN_URL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateAdminFlow(action) {
    try {
        const {token,admin} = action;
        const adminUpdate = yield call(adminUpdateAPI,token,admin);
        yield put(updateAdminSuccess(adminUpdate));
        yield put(controlModalAdmin());
        toastr.success('Usuario editado', 'Usuario editado');
    } catch (error) {
        yield put(updateAdminError(error));
        yield put(errorControlAdmin());
    }
}

function* usersWatcher() {
    yield all([
        takeEvery(GET_USER_REQUESTING, getUsersFlow),
        takeEvery(GET_STATE_USER_REQUESTING, getStateUserFlow),
        takeEvery(CHANGE_STATE_USER_REQUESTING, statesUserFlow),
        takeEvery(SEARCH_USER_REQUESTING, searchUserFlow),
        takeEvery(CREATE_USER_REQUESTING, createUserFlow),
        takeEvery(UPDATE_USER_REQUESTING, updateUserFlow),
        takeEvery(UPDATE_ADMIN_REQUESTING,updateAdminFlow)
    ])
}

export default usersWatcher;

import {handleApiErrors} from "./../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {getReviewError, getReviewSuccess} from "./actions";
import {GET_REVIEW_REQUESTING} from "./constants";

const REVIEW_RECEIVED_URL = `${process.env.REACT_APP_API_URL}/reviewsReceived`;

const getReviewReceivedAPI = (token, id) => {

    return fetch(`${REVIEW_RECEIVED_URL}/${id}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getReviewReceivedFlow(action) {
    try{
        const {token, id} = action;
        const reviews = yield call(getReviewReceivedAPI, token, id);
        yield put(getReviewSuccess(reviews));
    }catch (error) {
        yield put(getReviewError(error));
    }
}

function* reviewWatcher() {
    yield all([
        takeEvery(GET_REVIEW_REQUESTING, getReviewReceivedFlow)
    ])
}

export default reviewWatcher;
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {loginPostError, loginPostSuccess,logoutError,logoutSuccess} from "./actions";
import {LOGIN_POST_REQUESTING,LOGOUT_REQUESTING} from "./constants";
import {clientSet,clientUnSet} from "../../client/actions";
import {meGetRequesting} from "../user/actions";

const loginUrl = `${process.env.REACT_APP_API_URL}/auth/administrator`;
const logoutUrl = `${process.env.REACT_APP_API_URL}/auth/logout`;

const loginPostApi = (values) => {
    let body = {
        email: values.email,
        password: values.password,
        device: 'desktop',
    };
    return fetch(loginUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422) {
                let message = '';
                for (let key in json.data) {
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if (json.hasOwnProperty('access_token')) {
                localStorage.setItem('@tuconstruccion:admin', json.access_token);
                return json.access_token;
            }
        })
        .catch((error) => {
            throw error;
        })
};

function* loginPostFlow(action) {
    try {
        const {values} = action;
        const token = yield call(loginPostApi, values);
        yield put(clientSet(token));
        yield put(meGetRequesting(token));
        yield put(loginPostSuccess());
    } catch (error) {
        yield put(loginPostError(error));
    }
}

const logoutPostApi = (token) => {
    return fetch(logoutUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200) {
                localStorage.removeItem('@tuconstruccion:admin');
                window.location.href = '/';
            } else {
                throw json.data;
            }
        }).catch((error) => {
            throw error
        })
};

function* logoutPostFlow(action) {
    try {
        const {token} = action;
        yield call(logoutPostApi, token);
        yield put(logoutSuccess());
        yield put(clientUnSet());
    } catch (error) {
        yield put(logoutError(error));
    }
}


function* loginWatcher() {
    yield all([
        takeEvery(LOGIN_POST_REQUESTING, loginPostFlow),
        takeEvery(LOGOUT_REQUESTING,logoutPostFlow),
    ])
}

export default loginWatcher;

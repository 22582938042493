import React from 'react';

export class ModalEditSubscription extends React.Component {

    render() {
        
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Editar orden</h1>
                    </div>
                    <div className="text-modal">
                        <h2>Información de compra</h2>
                    </div>
                    <div className="item-modal">
                        <label>Fecha de compra: </label>
                        <input type="text" className="input input-name"/>
                    </div>
                    <div className="item-modal">
                        <label>Valor: </label>
                        <input type="text" className="input input-city"/>
                    </div>

                    <div className="item-modal">
                        <label>Metodo de pago: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Direccíon: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Con cuanto pago: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Estado: </label>
                        <div className="item-modal">
                            {/*<label>Categoría 1: </label>*/}
                            <select name="rol" className="select-rol">
                                <option value="">Categoría 2:</option>
                                <option value="">Categoría 3:</option>
                                <option value="">Categoría 4:</option>
                            </select>
                        </div>
                    </div>
                    <div className="text-modal">
                        <h2>Información de cliente</h2>
                    </div>
                    <div className="item-modal">
                        <label>Nombre: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Celular: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Correo: </label>
                        <input type="text" className="input input-name"/>
                    </div>
                    <div className="item-modal">
                        <label>Dirección: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="text-modal">
                        <h2>Productos de la orden</h2>
                    </div>
                    <div className="Conteiner-table">
                        <table className="table-orders">
                            <thead>
                            <tr>
                                <th>Nombre de producto</th>
                                <th>Referencia</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Acción</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Nombre</td>
                                <td>Foto</td>
                                <td>Descripción</td>
                                <td>Precio real</td>
                                <td>
                                    <button className="Btn-item-table">Editar<i
                                        className="icon-pencil styles-icons"/></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal">Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Crear producto<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import {GET_CITY_ERROR, GET_CITY_REQUESTING, GET_CITY_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    cities: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CITY_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_CITY_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                cities: action.cities
            };
        case GET_CITY_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                cities: []
            };
        default:
            return state;
    }
};

export default reducer;
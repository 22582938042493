import React from 'react';
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import ImagesInput from './../../file-inputs/images';
import ModuleSelectComponent from './../../module/select';
import {categoryUpdatePutRequesting} from "../../../redux/category/actions";

class ModalEditCategory extends React.Component {

    handleSubmit = (values) => {
        const {client: {token}, categoryUpdatePutRequesting} = this.props;
        categoryUpdatePutRequesting(token, values);
    };

    render() {
        const {handleSubmit, initialValues} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Editar categoria</h1>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="item-modal">
                            <label>Nombre categoria: </label>
                            <Field type="text" className="input input-name" name={'categoria'} component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Foto: </label>
                            <Field name={'photos'} maxFiles={1} className={'filepond'} component={ImagesInput}/>
                        </div>
                        <div className="item-modal">
                            <label>Módulo: </label>
                            <ModuleSelectComponent type={'edit'} initialValues={initialValues.modulo}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal">
                                    Actualizar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'editCategory'
})(ModalEditCategory);

const mapStateToProps = state => ({
    category: state.category,
    initialValues: state.category.category,
    client: state.client
});

const connected = connect(mapStateToProps, {
    categoryUpdatePutRequesting,
})(formed);

export default connected

export const SUBCATEGORIES_GET_REQUESTING = 'SUBCATEGORIES_GET_REQUESTING';
export const SUBCATEGORIES_GET_SUCCESS = 'SUBCATEGORIES_GET_SUCCESS';
export const SUBCATEGORIES_GET_ERROR = 'SUBCATEGORIES_GET_ERROR';

export const SUBCATEGORY_CREATE_POST_REQUESTING = 'SUBCATEGORY_CREATE_POST_REQUESTING';
export const SUBCATEGORY_CREATE_POST_SUCCESS = 'SUBCATEGORY_CREATE_POST_SUCCESS';
export const SUBCATEGORY_CREATE_POST_ERROR = 'SUBCATEGORY_CREATE_POST_ERROR';

export const SUBCATEGORY_FOCUS = 'SUBCATEGORY_FOCUS';

export const SUBCATEGORY_UPDATE_PUT_REQUESTING = 'SUBCATEGORY_UPDATE_PUT_REQUESTING';
export const SUBCATEGORY_UPDATE_PUT_SUCCESS = 'SUBCATEGORY_UPDATE_PUT_SUCCESS';
export const SUBCATEGORY_UPDATE_PUT_ERROR = 'SUBCATEGORY_UPDATE_PUT_ERROR';

export const SUBCATEGORIES_SEARCH_POST_REQUESTING = 'SUBCATEGORIES_SEARCH_POST_REQUESTING';
export const SUBCATEGORIES_SEARCH_POST_SUCCESS = 'SUBCATEGORIES_SEARCH_POST_SUCCESS';
export const SUBCATEGORIES_SEARCH_POST_ERROR = 'SUBCATEGORIES_SEARCH_POST_ERROR';

export const SUBCATEGORY_STATE_DELETE_REQUESTING = 'SUBCATEGORY_STATE_DELETE_REQUESTING';
export const SUBCATEGORY_STATE_DELETE_SUCCESS = 'SUBCATEGORY_STATE_DELETE_SUCCESS';
export const SUBCATEGORY_STATE_DELETE_ERROR = 'SUBCATEGORY_STATE_DELETE_ERROR';

export const SUBCATEGORY_SHOW_MODAL = 'SUBCATEGORY_SHOW_MODAL';
export const SUBCATEGORY_HIDDEN_MODAL = 'SUBCATEGORY_HIDDEN_MODAL';

export const SUBCATEGORY_RESET_STATES = 'SUBCATEGORY_RESET_STATES';
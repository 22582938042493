import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    categoryResetStates,
    categoriesModuleGetError,
    categoriesModuleGetSuccess,
    categoriesGetSuccess,
    categoriesGetError,
    categoryHiddenModal,
    categoryCreatePostSuccess,
    categoryCreatePostError,
    categoryUpdatePutSuccess,
    categoryUpdatePutError,
    categoryStateDeleteSuccess,
    categoryStateDeleteError,
    categoriesSearchPostSuccess, categoriesSearchPostError
} from "./actions";
import {
    subcategoriesGetRequesting
} from "../subcategory/actions";
import {
    CATEGORIES_GET_REQUESTING,
    CATEGORIES_MODULE_GET_REQUESTING, CATEGORIES_SEARCH_POST_REQUESTING,
    CATEGORY_CREATE_POST_REQUESTING, CATEGORY_STATE_DELETE_REQUESTING, CATEGORY_UPDATE_PUT_REQUESTING
} from "./constants";

const categoryUrl = `${process.env.REACT_APP_API_URL}/categories`;

const categoriesModuleGetApi = (token, values) => {
    let url = `${categoryUrl}ModuleAdmin/${values.module}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0 && json.data.code !== 404)
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* categoriesModuleGetFlow(action) {
    try {
        const {token, values} = action;
        const categories = yield call(categoriesModuleGetApi, token, values);
        yield put(categoriesModuleGetSuccess(categories));
        yield put(categoryResetStates());
    } catch (error) {
        yield put(categoriesModuleGetError(error));
    }
}

const categoriesGetApi = (token, filter, paginate) => {
    let url = `${categoryUrl}/filter/${filter}?page=${paginate}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* categoriesGetFlow(action) {
    try {
        const {token, filter, paginate} = action;
        const categories = yield call(categoriesGetApi, token, filter, paginate);
        yield put(categoriesGetSuccess(categories));
        yield put(categoryResetStates());
    } catch (error) {
        yield put(categoriesGetError(error));
    }
}

const categoryCreatePostApi = (token, values) => {
    let body = new FormData();
    body.append('name', values.name || '');
    body.append('module', values.module || '');
    if (values.hasOwnProperty('photos') && values.photos.length > 0) {
        values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', values.photos.length || '');
    }
    return fetch(categoryUrl, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: body,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* categoryCreatePostFlow(action) {
    try {
        const {token, values} = action;
        const category = yield call(categoryCreatePostApi, token, values);
        yield put(categoryCreatePostSuccess(category));
        toastr.success('Categoría creada', `Se ha creado la categoria ${category.categoria} correctamente.`);
        yield put(categoryHiddenModal('categoria'));
        yield put(categoryResetStates());
    } catch (error) {
        yield put(categoryCreatePostError(error));
    }
}

const categoryUpdatePutApi = (token, values) => {
    let body = new FormData();
    body.append('_method', 'put');
    body.append('name', values.categoria || '');
    body.append('module', values.module || values.modulo.id);
    if (values.hasOwnProperty('photos') && values.photos.length > 0) {
        values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', values.photos.length || '');
    }
    return fetch(`${categoryUrl}/${values.id}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: body,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* categoryUpdatePutFlow(action) {
    try {
        const {token, values} = action;
        const category = yield call(categoryUpdatePutApi, token, values);
        yield put(categoryHiddenModal('edit'));
        yield put(categoryUpdatePutSuccess(category));
        toastr.success('Categoría actualizada', `Se ha actulizado la categoria ${category.categoria} correctamente.`);
        yield put(categoryResetStates());
    } catch (error) {
        yield put(categoryUpdatePutError(error));
    }
}

const categoryStateDeleteApi = (token, categoryId) => {
    return fetch(`${categoryUrl}/${categoryId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* categoryStateDeleteFlow(action) {
    try {
        const {token, categoryId} = action;
        const category = yield call(categoryStateDeleteApi, token, categoryId);
        yield put(categoryStateDeleteSuccess(category));
        yield put(categoryResetStates());
        toastr.success('Categoría actualizada', `Se ha ${category.estado.nombre} la categoria ${category.categoria} correctamente.`);
        yield put(subcategoriesGetRequesting(token, 'Todos', 1));
    } catch (error) {
        yield put(categoryStateDeleteError(error));
    }
}

const categoriesSearchPostApi = (token, search) => {
    let body = {
        search: search,
    };
    return fetch(`${categoryUrl}/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* categoriesSearchPostFlow(action) {
    try {
        const {token, search} = action;
        const categories = yield call(categoriesSearchPostApi, token, search);
        yield put(categoriesSearchPostSuccess(categories));
        yield put(categoryResetStates());
    } catch (error) {
        yield put(categoriesSearchPostError(error));
    }
}

function* categoryWatcher() {
    yield all([
        takeEvery(CATEGORIES_MODULE_GET_REQUESTING, categoriesModuleGetFlow),
        takeEvery(CATEGORIES_GET_REQUESTING, categoriesGetFlow),
        takeEvery(CATEGORY_CREATE_POST_REQUESTING, categoryCreatePostFlow),
        takeEvery(CATEGORY_UPDATE_PUT_REQUESTING, categoryUpdatePutFlow),
        takeEvery(CATEGORY_STATE_DELETE_REQUESTING, categoryStateDeleteFlow),
        takeEvery(CATEGORIES_SEARCH_POST_REQUESTING, categoriesSearchPostFlow),
    ])
}

export default categoryWatcher;

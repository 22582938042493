import {
    CHANGE_STATE_USER_ERROR,
    CHANGE_STATE_USER_REQUESTING,
    CHANGE_STATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    CREATE_USER_REQUESTING,
    CREATE_USER_SUCCESS,
    GET_STATE_USER_ERROR,
    GET_STATE_USER_REQUESTING,
    GET_STATE_USER_SUCCESS,
    GET_USER_ERROR,
    GET_USER_REQUESTING,
    GET_USER_SUCCESS,
    SEARCH_USER_ERROR,
    SEARCH_USER_REQUESTING,
    SEARCH_USER_SUCCESS,
    SET_FOCUS_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_REQUESTING,
    UPDATE_USER_SUCCESS,
    SHOW_MODAL_USER,
    HIDDEN_MODAL_USER,
    SHOW_MODAL_EDIT_USER,
    HIDDEN_MODAL_EDIT_USER,
    ERROR_USER, SHOW_MODAL_REVIEW_USER,
    HIDDEN_MODAL_REVIEW_USER,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_REQUESTING,
    UPDATE_ADMIN_ERROR,
    ERROR_ADMiN
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    hasLess: false,
    hasMore: true,
    cursor: 1,
    filter: 'Todos',
    users: [],
    statesUser: [],
    user: [],
    search: '',
    focusUser: 0,
    userDataFocus: {},
    userModal: false,
    userEditModal: false,
    errorUser: false,
    errorAdmin :false,
    userReviewModal: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_REQUESTING:
            return {
                ...state,
                cursor: action.cursor,
                filter: action.filter
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                users: action.users,
                hasLess: state.cursor + 1 > 1,
                hasMore: true
            };
        case GET_USER_ERROR:
            return {
                ...state,
                error: action.error,
                hasMore: false,
                users: []
            };
        case GET_STATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case GET_STATE_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                statesUser: action.states
            };
        case GET_STATE_USER_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false
            };
        case CHANGE_STATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                userId: action.userId
            };
        case CHANGE_STATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map(userItem => (
                    userItem.id === action.user.id ? action.user : userItem
                )),
                user: state.user.length > 0 && state.user.map(userItem => (
                    userItem.id === action.user.id ? action.user : userItem
                )),
            };
        case CHANGE_STATE_USER_ERROR:
            return {
                ...state,
                requesting: false
            };
        case SEARCH_USER_REQUESTING:
            return {
                ...state,
                search: action.search
            };
        case SEARCH_USER_SUCCESS:
            return {
                ...state,
                user: action.user
            };
        case SEARCH_USER_ERROR:
            return {
                ...state,
                user: []
            };
        case CREATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error : '',
                users: [action.userCreated, ...state.users]
            };
        case CREATE_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case SET_FOCUS_USER:
            return {
                ...state,
                focusUser: action.id,
                userDataFocus: action.user
            };
        case UPDATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                user: state.user.map((user) => user.id === state.focusUser ? action.userUpdate : user),
                users: state.users.map((user) => user.id === state.focusUser ? action.userUpdate : user)
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case SHOW_MODAL_USER :
            return {
                ...state,
                userModal: true,
            };
        case HIDDEN_MODAL_USER :
            return {
                ...state,
                userModal: false
            };
        case SHOW_MODAL_EDIT_USER :
            return {
                ...state,
                userEditModal: true,
            };
        case HIDDEN_MODAL_EDIT_USER:
            return {
                ...state,
                userEditModal: false
            };
        case ERROR_USER:
            return {
                ...state,
                errorUser: !state.errorUser
            };
        case ERROR_ADMiN:
            return {
                ...state,
                errorAdmin: !state.errorAdmin
            };
        case SHOW_MODAL_REVIEW_USER:
            return {
                ...state,
                userReviewModal: true,
            };
        case HIDDEN_MODAL_REVIEW_USER:
            return {
                ...state,
                userReviewModal: false,
            };
        case UPDATE_ADMIN_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                admin: action.adminUpdate
            };
        case UPDATE_ADMIN_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };

        default :
            return state;
    }
};

export default reducer;

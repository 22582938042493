import React from 'react';
import {reduxForm,Field} from 'redux-form';


class SearchUserSubscriptionComponent extends React.Component {

    handleSubmit = (values) => {
        this.props.handleSearchUser(values);
    };
    render(){
        const {handleSubmit} = this.props;
        return(
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                 <div className="item-modal">
                            <Field name={'searchUser'} component={'input'} type="text"
                                   placeholder='Buscar usuario por correo o identificacion'/>
                            <button  className="Btn-buscar">Buscar<i
                                className="icon-search styles-icons2"/></button>
                    </div>
            </form>
        )
    }
}
export default reduxForm({
    form: 'searchUserSubscription',
})(SearchUserSubscriptionComponent)
import {
    GET_SUBSCRIPTION_USER_ERROR,
    GET_SUBSCRIPTION_USER_REQUESTING,
    GET_SUBSCRIPTION_USER_SUCCESS,
    CHANGE_STATE_SUBSCRIPTION_USER_ERROR,
    CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING,
    CHANGE_STATE_SUBSCRIPTION_USER_SUCCESS,
    CREATE_SUBSCRIPTION_USER_ERROR,
    CREATE_SUBSCRIPTION_USER_REQUESTING,
    CREATE_SUBSCRIPTION_USER_SUCCESS,
    SEARCH_SUBSCRIPTION_USER_ERROR,
    SEARCH_SUBSCRIPTION_USER_REQUESTING,
    SEARCH_SUBSCRIPTION_USER_SUCCESS,
    SHOW_MODAL_SUBSCRIPTION_USER,
    HIDDEN_MODAL_SUBSCRIPTION_USER,
    SEARCH_USER_CREATE_SUBSCRIPTION_ERROR,
    SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING,
    SEARCH_USER_CREATE_SUBSCRIPTION_SUCCESS,
    ERROR_SUBSCRIPTION_USER
} from './constants';

//Crear suscripcion

export const createSubscriptionUserRequesting = (subscription, token) => ({
    type: CREATE_SUBSCRIPTION_USER_REQUESTING,
    subscription,
    token
});

export const createSubscriptionUserSuccess = (subscription) => ({
    type: CREATE_SUBSCRIPTION_USER_SUCCESS,
    subscription
});

export const createSubscriptionUserError = (error) => ({
    type: CREATE_SUBSCRIPTION_USER_ERROR,
    error
});

//Cambiar estado suscripcion

export const changeStateSubscriptionUserRequesting = (subscriptionId, token) => ({
    type: CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING,
    subscriptionId,
    token
});
export const changeStateSubscriptionUserSuccess = (subscription) => ({
    type: CHANGE_STATE_SUBSCRIPTION_USER_SUCCESS,
    subscription
});
export const changeStateSubscriptionUserError = (error) => ({
    type: CHANGE_STATE_SUBSCRIPTION_USER_ERROR,
    error
});

//Buscar suscripciones usuario
export const searchSubscriptionUserRequesting = (search, token) => ({
    type: SEARCH_SUBSCRIPTION_USER_REQUESTING,
    search,
    token
});
export const searchSubscriptionUserSuccess = (subscriptions) => ({
    type: SEARCH_SUBSCRIPTION_USER_SUCCESS,
    subscriptions
});
export const searchSubscriptionUserError = (error) => ({
    type: SEARCH_SUBSCRIPTION_USER_ERROR,
    error
});

//obtener suscripciones
export const getSubscriptionUserRequesting=(paginate,filter,token) =>({
    type: GET_SUBSCRIPTION_USER_REQUESTING,
    paginate,
    filter,
    token
});

export const getSubscriptionUserSuccess=(subscriptions) =>({
    type: GET_SUBSCRIPTION_USER_SUCCESS,
    subscriptions
});

export const getSubscriptionUserError=(error) =>({
    type: GET_SUBSCRIPTION_USER_ERROR,
    error
});

 export const errorControlSubscriptionUser =()=>({
     type: ERROR_SUBSCRIPTION_USER,
 });

export const showModalSubscriptionUser = ()=>({
    type: SHOW_MODAL_SUBSCRIPTION_USER
});

export const hiddenModalSubscriptionUser =()=>({
    type: HIDDEN_MODAL_SUBSCRIPTION_USER
});

//Buscar usuario al crear suscripcion

export const searchUserCreateSubscriptionRequesting = (search, token) => ({
    type: SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING,
    search,
    token
});
export const searchUserCreateSubscriptionSuccess = (user) => ({
    type: SEARCH_USER_CREATE_SUBSCRIPTION_SUCCESS,
    user
});
export const searchUserCreateSubscriptionError = (error) => ({
    type: SEARCH_USER_CREATE_SUBSCRIPTION_ERROR,
    error
});
import React from 'react';


export class FilterComponent extends React.Component {

    render() {
        const {states, handleFilter, type, handleGetCsv, handleGetData} = this.props;
        return (
            <div className="Btn-general-filter">
                <div className="container-add-direction3">
                    <div className="div-btn-filter">
                        <select name="" onChange={(e) => handleFilter(e.target.value, type)}>
                            <option value="Todos">Todos</option>
                            {states !== undefined && states.length > 0 && states.map((state, index) => (
                                <option key={index} value={state.nombre}>{state.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className="div-btn-add">
                        <button onClick={this.props.handleShowModal.bind(this, this.props.type)}
                                className="Btn-general-add">+
                            Agregar {this.props.type}<i
                                className="icon-ok styles-icons"/></button>
                    </div>
                    <div className="div-btn-add">
                        <button className="Btn-general-add"
                                type={'button'}
                                onClick={() => handleGetCsv()}>Descargar csv<i
                            className="icon-folder-open-empty styles-icons"/></button>
                    </div>
                    <div className="div-btn-direction">
                        <button className="Btn-general-direction" onClick={() => handleGetData(-1, type)}><i
                            className="icon-left-big styles-icons"/>Atrás
                        </button>
                        <button className="Btn-general-direction" onClick={() => handleGetData(1, type)}>Adelante<i
                            className="icon-right-big styles-icons"/></button>
                    </div>
                </div>
            </div>
        )
    }
}

import {
    LOGIN_POST_ERROR,
    LOGIN_POST_REQUESTING,
    LOGIN_POST_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
} from "./constants";

export const loginPostRequesting = (values) => ({
    type: LOGIN_POST_REQUESTING,
    values
});

export const loginPostSuccess = () => ({
    type: LOGIN_POST_SUCCESS,
});

export const loginPostError = (error) => ({
    type: LOGIN_POST_ERROR,
    error,
});

export const logoutRequesting = (token) => ({
    type: LOGOUT_REQUESTING,
    token,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutError = (error) => ({
    type: LOGOUT_ERROR,
    error,
});

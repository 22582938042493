import React from 'react';
import SearchComponent from "../../search/search";
import {TableCategories} from "./../table/table-categories";
import {FilterComponent} from "../../filter/filter";
import ModalAddCategory from "./../modals/add-category";
import ModalEditCategory from "./../modals/edit-category";
import {NotFound} from "../../../commons/errors/notFound";
import {connect} from "react-redux";
import {
    categoriesGetRequesting,
    categoryShowModal,
    categoryHiddenModal,
    categoryStateDeleteRequesting,
    categoryFocus,
    categoriesSearchPostRequesting,
} from "../../../redux/category/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {reduxForm} from "redux-form";
import {toastr} from "react-redux-toastr";
import {getStatesSubscriptionRequesting} from "../../../redux/subscription/actions";

export class ConfigurationCategoryComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            client: {token},
            category,
            categoriesGetRequesting,
            getStatesSubscriptionRequesting
        } = this.props;
        getStatesSubscriptionRequesting(token);
        categoriesGetRequesting(token, category.filter, category.paginate);
    }


    handleFocusCategory = (category) => {
        const {categoryFocus} = this.props;
        categoryFocus(category);
    };

    handleChangeStateCategory = (categoryId) => {
        const {client: {token}, categoryStateDeleteRequesting} = this.props;
        categoryStateDeleteRequesting(token, categoryId);
    };

    handleShowModalCategory = (modal) => {
        const {categoryShowModal} = this.props;
        categoryShowModal(modal);
    };

    handleHiddenModalCategory = (modal) => {
        const {categoryHiddenModal} = this.props;
        categoryHiddenModal(modal);
    };

    handleSearchCategory = (values) => {
        const {client: {token}, category: {stringSearch}, categoriesSearchPostRequesting} = this.props;
        if (stringSearch !== values.search)
            categoriesSearchPostRequesting(token, values.search);
    };

    handleErrorCategory = () => {
        const {category: {errorCategory}} = this.props;

        let toastOptions = {
            component: (
                <ErrorComponent message={errorCategory}/>
            )
        };
        toastr.error('Error', toastOptions);
    };

    handleFilterCategory = (values) => {
        const {client: {token}, category: {filter}, categoriesGetRequesting} = this.props;
        if (values !== filter)
            categoriesGetRequesting(token, values, 1);
    };

    handleGetCsvCategory = () => {
        const {me: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/categoriesExport?email=${user.correo}`, "_self")
    };

    handleGetDataCategory = (newPaginate) => {
        const {
            client: {token},
            categoriesGetRequesting,
            category: {filter, paginate, hasMore, hasLess}
        } = this.props;
        if (newPaginate === 1 && hasMore)
            categoriesGetRequesting(token, filter, paginate + newPaginate);
        if (newPaginate === -1 && hasLess && paginate > 1)
            categoriesGetRequesting(token, filter, paginate + newPaginate);
    };


    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            subscription: {
                statesSubscription,
            },
            category: {
                categories,
                categoriesSearch,
                stringSearch,
                errorCategory,
                modalEditCategory,
                modalCreateCategory,
            }
        } = this.props;
        return (
            <div className="styles-container">
                {errorCategory !== '' && this.handleErrorCategory()}
                <SearchComponent
                    type={'Configuración categorias'}
                    placeholder={'Consultar categorias'}
                    handleSearch={this.handleSearchCategory.bind(this)}
                >
                    {stringSearch !== '' && (
                        categoriesSearch.length > 0
                            ? <TableCategories categories={categoriesSearch}
                                               handleFocus={this.handleFocusCategory.bind(this)}
                                               handleChangeState={this.handleChangeStateCategory.bind(this)}
                                               handleShowModal={this.handleShowModalCategory.bind(this)}
                            />
                            : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'categoria'}
                        handleShowModal={this.handleShowModalCategory.bind(this)}
                        handleFilter={this.handleFilterCategory.bind(this)}
                        handleGetCsv={this.handleGetCsvCategory.bind(this)}
                        handleGetData={this.handleGetDataCategory.bind(this)}
                        states={statesSubscription}
                    />
                    {categories.length > 0
                        ? <TableCategories categories={categories}
                                           handleFocus={this.handleFocusCategory.bind(this)}
                                           handleChangeState={this.handleChangeStateCategory.bind(this)}
                                           handleShowModal={this.handleShowModalCategory.bind(this)}
                        />
                        : <NotFound/>
                    }
                    {modalCreateCategory && (
                        <ModalAddCategory
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModalCategory.bind(this)}/>
                    )}
                    {modalEditCategory && (
                        <ModalEditCategory
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModalCategory.bind(this)}/>
                    )}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    subscription: state.subscription,
    client: state.client,
    config: state.config,
    category: state.category,
    me: state.me,
});
const connected = connect(mapStateToProps, {
    categoriesGetRequesting,
    categoryShowModal,
    categoryHiddenModal,
    categoryStateDeleteRequesting,
    categoryFocus,
    categoriesSearchPostRequesting,
    getStatesSubscriptionRequesting
})(ConfigurationCategoryComponent);

const formed = reduxForm({
    form: 'ConfigurationComponent'
})(connected);

export default formed;
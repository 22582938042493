import React, {Component} from 'react';
import './App.css';
import {applyMiddleware, createStore, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {IndexSagas} from './redux/index-sagas';
import IndexReducer from './redux/index-reducer';
import ReduxToastr from 'react-redux-toastr';
import Routes from './commons/routes/routes';
import {Provider} from 'react-redux';
import PrivateRoute from './commons/privateRoutes/private';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LoginBack from "./components/login/login";
import UserComponent from "./components/2-User/index";
import ProductComponent from "./components/1-Products/index";
import SubscriptionComponent from "./components/3-Subscription";
import ConfigurationSubscriptionComponent from "./components/4-Configuration/suscripciones";
import ConfigurationCategoryComponent from "./components/4-Configuration/categorias";
import ConfigurationSubcategoryComponent from "./components/4-Configuration/subcategoria";

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    IndexReducer,
    composeSetup(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(IndexSagas);


class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <ReduxToastr
                            timeOut={10000}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-right"
                            transitionIn="bounceInDown"
                            transitionOut="bounceOutUp"
                            progressBar
                            closeOnToastrClick
                        />
                        <Routes/>
                        <Switch>
                            <Route exact path="/" component={LoginBack}/>
                            <PrivateRoute path={'/productos'} component={ProductComponent}/>
                            <PrivateRoute exact path="/configuracion/suscripcion" component={ConfigurationSubscriptionComponent}/>
                            <PrivateRoute exact path="/configuracion/categoria" component={ConfigurationCategoryComponent}/>
                            <PrivateRoute exact path="/configuracion/subcategoria" component={ConfigurationSubcategoryComponent}/>
                            <PrivateRoute path={'/suscripciones'} component={SubscriptionComponent}/>
                            <PrivateRoute path={'/usuarios'} component={UserComponent}/>
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;

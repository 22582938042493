import {GET_TYPE_PEOPLE_ERROR, GET_TYPE_PEOPLE_REQUESTING, GET_TYPE_PEOPLE_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    typePeoples: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TYPE_PEOPLE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_TYPE_PEOPLE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                typePeoples: action.typePeoples
            };
        case GET_TYPE_PEOPLE_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                typePeoples: []
            };
        default:
            return state;
    }
};

export default reducer;
import React from 'react';
import {File, FilePond} from "react-filepond";

export class ModalEditProduct extends React.Component {

    state={files1:[]}

    render() {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Agregar Nuevo producto</h1>
                    </div>
                    <div className="item-modal">
                        <label>Nombre: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Descripción: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Categoría: </label>
                        <select name="rol" className="select-rol">
                            <option value="">Categoría 2:</option>
                            <option value="">Categoría 3:</option>
                            <option value="">Categoría 4:</option>
                        </select>
                    </div>
                    <div className="item-modal">
                        <label>Marca: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Ciudad: </label>
                        <select name="rol" className="select-rol">
                            <option value="">Ciudad 1</option>
                            <option value="">Ciudad 2</option>
                            <option value="">Ciudad 3</option>
                        </select>
                    </div>
                    <div className="item-modal">
                        <label>Dimensiones: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Peso: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Precio: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Valor de Envío: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Cantidad Disponible: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Tipo de Producto: </label>
                        <select name="rol" className="select-rol">
                            <option value="">Ciudad 1</option>
                            <option value="">Ciudad 2</option>
                            <option value="">Ciudad 3</option>
                        </select>
                    </div>
                    <label>Foto: </label>
                    <FilePond ref={ref => this.pond = ref}
                                            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action upled">Busca aquí</span>'}
                                            allowMultiple={true}
                                            maxFiles={5}
                                            server="/api"
                                            onupdatefiles={(fileItems) => {
                                                // Set current file objects to this.state
                                                this.setState({
                                                    files1: fileItems.map(fileItem => fileItem.file)
                                                });
                                            }}>
                    {/* Update current files  */}
                    {this.state.files1.map(file => (
                        <File key={file} src={file} origin="local"/>
                    ))}
                </FilePond>
                    <div className="item-modal">
                        <label>Categoría: </label>
                        <textarea type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Marca:</label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal">Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Crear producto<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import {
    CHECK_AUTH,
    CHECK_AUTH_ERROR,
    CHECK_AUTH_SUCCESS,
    CLIENT_UNSET,
    CLIENT_SET,
    CONTROL_MODAL_ADMIN
} from "./constans";

export const clientSet = (token) => ({
    type: CLIENT_SET,
    token
});

export const clientUnSet = () => ({
    type: CLIENT_UNSET
});

export const checkAuth = () => ({
    type: CHECK_AUTH
});

export const checkAuthError = () => ({
    type: CHECK_AUTH_ERROR
});

export const checkAuthSuccess = (token) => ({
    type: CHECK_AUTH_SUCCESS,
    token
});

export const controlModalAdmin = () => ({
    type: CONTROL_MODAL_ADMIN,
});

import React from "react";
import {Form, Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {publicationResetStates} from "../../../redux/publication/actions";

const required = value => value ? undefined : '*';

class ModalDisablePublication extends React.Component {

    handleSubmit = (values) => {
        const {handleDisable} = this.props;
        handleDisable(values);
    };

    render() {
        const {
            handleSubmit,
            stateForm: {PublicationDisable},
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this, 'disable')}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this, 'disable')}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Deshabilitar publicación</h1>
                    </div>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="item-modal">
                            <label>Razón por la cual se deshabilita: </label>
                            <div className={'container-required'}>
                                <Field component={'input'} name={'observation'} type={'text'}/>
                            </div>
                            {/*<div className={'container-required'}>*/}
                                {/*<Field component={'input'} name={'observation'} type={'text'} validate={[required]}/>*/}
                                {/*{PublicationDisable !== undefined*/}
                                {/*&& PublicationDisable.hasOwnProperty('syncErrors')*/}
                                {/*&& PublicationDisable.syncErrors.hasOwnProperty('observation') && PublicationDisable.syncErrors.observation === '*' && (*/}
                                    {/*<span className={'required'}>{PublicationDisable.syncErrors.observation}</span>*/}
                                {/*)}*/}
                            {/*</div>*/}
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this, 'disable')}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" action="submit">
                                    Guardar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'PublicationDisable',
})(ModalDisablePublication);

const mapStateToProps = state => ({
    client: state.client,
    stateForm: state.form,
});

const connected = connect(mapStateToProps, {
    publicationResetStates
})(formed);

export default connected;
import React from 'react';
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import ImagesInput from './../../file-inputs/images';
import CategorySelectComponent from './../../category/select';
import {subcategoryCreatePostRequesting} from "../../../redux/subcategory/actions";

class ModalAddSubCategory extends React.Component {

    handleSubmit = (values) => {
        const {client: {token}, subcategoryCreatePostRequesting} = this.props;
        subcategoryCreatePostRequesting(token, values);
    };

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel"
                                onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Agregar subcategoria</h1>
                    <br/>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="item-modal">
                            <label>Nombre: </label>
                            <Field type="text" className="input input-name" name={'name'} component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Foto: </label>
                            <Field name={'photos'} maxFiles={1} className={'filepond'} component={ImagesInput}/>
                        </div>
                        <div className="item-modal">
                            <label>Categoría: </label>
                            <CategorySelectComponent type={'add'}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal">
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" formAction={'submit'}>
                                    Crear subcategoria
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'CreateSubcategory',
    enableReinitialize: true
})(ModalAddSubCategory);

const mapStateToProps = state => ({
    client: state.client,
});

const connected = connect(mapStateToProps, {
    subcategoryCreatePostRequesting,
})(formed);

export default connected;
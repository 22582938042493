import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';

class CategorySelectComponent extends React.Component {

    render() {
        const {
            category: {categoriesModule, categories},
            type,
            initialValues,
            publication: {moduleId}
        } = this.props;
        switch (type) {
            case 'filter':
                return (
                    <Field name={'category'} type={'select'} component={'select'} disable={categoriesModule.length === 0 && true}>
                        {categoriesModule.length > 0 && moduleId !== 'Todos' && (
                            <option value={'Todos'}>Todos</option>
                        )}
                        {moduleId !== 'Todos' && categoriesModule.length > 0 && categoriesModule.map((category, index) => (
                            <option key={index} value={category.id}>{category.categoria}</option>
                        ))}
                    </Field>
                );
            case 'add':
                return (
                    <Field name={'category'} type={'select'} component={'select'}>
                        <option>Seleccione una opción</option>
                        {categories.length > 0 && categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.categoria}</option>
                        ))}
                    </Field>
                );
            case 'edit':
                return (
                    <Field name={'category'} type={'select'} component={'select'}>
                        <option value={initialValues.id}>{initialValues.nombre}</option>
                        {categories.length > 0 && categories.map((category, index) => {
                            if (initialValues.id !== category.id) {
                                return <option key={index} value={category.id}>{category.categoria}</option>
                            }
                        })}
                    </Field>
                );
            default:
                return (
                    <Field name={'category'} type={'select'} component={'select'}>
                        <option>Seleccione una opción</option>
                        {categories.length > 0 && categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.categoria}</option>
                        ))}
                    </Field>
                );
        }
    }
}

const mapStateToProps = state => ({
    category: state.category,
    publication: state.publication,
});

const connected = connect(mapStateToProps, {})(CategorySelectComponent);

export default connected;
import {
    SUBCATEGORY_RESET_STATES,
    SUBCATEGORIES_GET_REQUESTING,
    SUBCATEGORIES_GET_SUCCESS,
    SUBCATEGORIES_GET_ERROR,
    SUBCATEGORY_CREATE_POST_REQUESTING,
    SUBCATEGORY_CREATE_POST_SUCCESS,
    SUBCATEGORY_CREATE_POST_ERROR,
    SUBCATEGORY_FOCUS,
    SUBCATEGORY_UPDATE_PUT_REQUESTING,
    SUBCATEGORY_UPDATE_PUT_SUCCESS,
    SUBCATEGORIES_SEARCH_POST_REQUESTING,
    SUBCATEGORIES_SEARCH_POST_SUCCESS,
    SUBCATEGORIES_SEARCH_POST_ERROR,
    SUBCATEGORY_STATE_DELETE_REQUESTING,
    SUBCATEGORY_STATE_DELETE_SUCCESS,
    SUBCATEGORY_STATE_DELETE_ERROR, SUBCATEGORY_SHOW_MODAL, SUBCATEGORY_HIDDEN_MODAL, SUBCATEGORY_UPDATE_PUT_ERROR
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    errorSubcategory: '',
    filter: 'Todos',
    paginate: 1,
    hasMore: true,
    hasLess: false,
    stringSearchSubcategory: '',
    subcategories: [],
    subcategoriesSearch: [],
    subcategory: {},
    modalCreateSubcategory: false,
    modalEditSubcategory: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBCATEGORIES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                filter: action.filter,
                paginate: action.paginate,
                errorSubcategory: '',
            };
        case SUBCATEGORIES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                hasLess: state.paginate !== 1,
                hasMore: action.subcategories.length > 0,
                subcategories: action.subcategories,
            };
        case SUBCATEGORIES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                errorSubcategory: action.error,
                hasMore: false,
                hasLess: state.paginate !== 1,
            };
        case SUBCATEGORY_CREATE_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorSubcategory: '',
            };
        case SUBCATEGORY_CREATE_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                subcategories: [action.subcategory, ...state.subcategories],
                subcategoriesSearch: state.subcategoriesSearch.length > 0 ? [action.subcategory, ...state.subcategoriesSearch] : state.subcategoriesSearch,
            };
        case SUBCATEGORY_CREATE_POST_ERROR:
            return {
                ...state,
                requesting: false,
                errorSubcategory: action.error,
            };
        case SUBCATEGORY_FOCUS:
            return {
                ...state,
                subcategory: action.subcategory,
            };
        case SUBCATEGORY_UPDATE_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorSubcategory: '',
            };
        case SUBCATEGORY_UPDATE_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                subcategories: state.subcategories.map((subcategory) => subcategory.id === action.subcategory.id ? action.subcategory : subcategory),
                subcategoriesSearch: state.subcategoriesSearch.length > 0 ? state.subcategoriesSearch.map((subcategory) => subcategory.id === action.subcategory.id ? action.subcategory : subcategory) : state.subcategoriesSearch,
            };
        case SUBCATEGORY_UPDATE_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                errorSubcategory: action.error,
            };
        case SUBCATEGORIES_SEARCH_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                stringSearchSubcategory: action.search,
                error: '',
            };
        case SUBCATEGORIES_SEARCH_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                subcategoriesSearch: action.subcategories,
            };
        case SUBCATEGORIES_SEARCH_POST_ERROR:
            return {
                ...state,
                requesting: false,
                errorSubcategory: action.error,
            };
        case SUBCATEGORY_STATE_DELETE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorSubcategory: '',
            };
        case SUBCATEGORY_STATE_DELETE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                subcategories: state.subcategories.map((subcategory) => subcategory.id === action.subcategory.id ? action.subcategory : subcategory),
                subcategoriesSearch: state.subcategoriesSearch.length > 0 ? state.subcategoriesSearch.map((subcategory) => subcategory.id === action.subcategory.id ? action.subcategory : subcategory) : state.subcategoriesSearch,
            };
        case SUBCATEGORY_STATE_DELETE_ERROR:
            return {
                ...state,
                requesting: false,
                errorSubcategory: action.error,
            };
        case SUBCATEGORY_SHOW_MODAL:
            return {
                ...state,
                modalCreateSubcategory: action.modal === 'subcategoria',
                modalEditSubcategory: action.modal === 'edit',
            };
        case SUBCATEGORY_HIDDEN_MODAL:
            return {
                ...state,
                modalCreateSubcategory: action.modal === 'subcategoria' && false,
                modalEditSubcategory: action.modal === 'edit' && false,
            };
        case SUBCATEGORY_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                errorSubcategory: '',
                subcategory: {},
            };
        default:
            return state;
    }
};

export default reducer;
import {
    CATEGORY_RESET_STATES,
    CATEGORIES_MODULE_GET_ERROR,
    CATEGORIES_MODULE_GET_REQUESTING,
    CATEGORIES_MODULE_GET_SUCCESS,
    CATEGORIES_GET_REQUESTING,
    CATEGORIES_GET_SUCCESS,
    CATEGORIES_GET_ERROR,
    CATEGORY_CREATE_POST_REQUESTING,
    CATEGORY_CREATE_POST_SUCCESS,
    CATEGORY_CREATE_POST_ERROR,
    CATEGORY_FOCUS,
    CATEGORY_UPDATE_PUT_REQUESTING,
    CATEGORY_UPDATE_PUT_SUCCESS,
    CATEGORIES_SEARCH_POST_REQUESTING,
    CATEGORIES_SEARCH_POST_SUCCESS,
    CATEGORIES_SEARCH_POST_ERROR,
    CATEGORY_STATE_DELETE_REQUESTING,
    CATEGORY_STATE_DELETE_SUCCESS,
    CATEGORY_STATE_DELETE_ERROR, CATEGORY_SHOW_MODAL, CATEGORY_HIDDEN_MODAL, CATEGORY_UPDATE_PUT_ERROR
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    errorCategory: '',
    filter: 'Todos',
    paginate: 1,
    hasMore: true,
    hasLess: false,
    stringSearch: '',
    categories: [],
    categoriesSearch: [],
    category: {},
    categoriesModule: [],
    modalCreateCategory: false,
    modalEditCategory: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORIES_MODULE_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorCategory: '',
            };
        case CATEGORIES_MODULE_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categoriesModule: action.categories,
            };
        case CATEGORIES_MODULE_GET_ERROR:
            return {
                ...state,
                requesting: false,
                errorCategory: action.error,
            };
        case CATEGORIES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                filter: action.filter,
                paginate: action.paginate,
                errorCategory: '',
            };
        case CATEGORIES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                hasLess: state.paginate !== 1,
                hasMore: action.categories.length > 0,
                categories: action.categories,
            };
        case CATEGORIES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                errorCategory: action.error,
                hasMore: false,
                hasLess: state.paginate !== 1,
            };
        case CATEGORY_CREATE_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorCategory: '',
            };
        case CATEGORY_CREATE_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categories: [action.category, ...state.categories],
                categoriesSearch: state.categoriesSearch.length > 0 ? [action.category, ...state.categoriesSearch] : state.categoriesSearch,
            };
        case CATEGORY_CREATE_POST_ERROR:
            return {
                ...state,
                requesting: false,
                errorCategory: action.error,
            };
        case CATEGORY_FOCUS:
            return {
                ...state,
                category: action.category,
            };
        case CATEGORY_UPDATE_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorCategory: '',
            };
        case CATEGORY_UPDATE_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categories: state.categories.map((category) => category.id === action.category.id ? action.category : category),
                categoriesSearch: state.categoriesSearch.length > 0 ? state.categoriesSearch.map((category) => category.id === action.category.id ? action.category : category) : state.categoriesSearch,
            };
        case CATEGORY_UPDATE_PUT_ERROR:
            return {
                ...state,
                ...state,
                requesting: false,
                errorCategory: action.error,
            };
        case CATEGORIES_SEARCH_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                stringSearch: action.search,
                error: '',
            };
        case CATEGORIES_SEARCH_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categoriesSearch: action.categories,
            };
        case CATEGORIES_SEARCH_POST_ERROR:
            return {
                ...state,
                requesting: false,
                errorCategory: action.error,
            };
        case CATEGORY_STATE_DELETE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                errorCategory: '',
            };
        case CATEGORY_STATE_DELETE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categories: state.categories.map((category) => category.id === action.category.id ? action.category : category),
                categoriesSearch: state.categoriesSearch.length > 0 ? state.categoriesSearch.map((category) => category.id === action.category.id ? action.category : category) : state.categoriesSearch,
            };
        case CATEGORY_STATE_DELETE_ERROR:
            return {
                ...state,
                requesting: false,
                errorCategory: action.error,
            };
        case CATEGORY_SHOW_MODAL:
            return {
                ...state,
                modalCreateCategory: action.modal === 'categoria',
                modalEditCategory: action.modal === 'edit',
            };
        case CATEGORY_HIDDEN_MODAL:
            return {
                ...state,
                modalCreateCategory: action.modal === 'categoria' && false,
                modalEditCategory: action.modal === 'edit' && false,
            };
        case CATEGORY_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                errorCategory: '',
                category: {},
            };
        default:
            return state;
    }
};

export default reducer;
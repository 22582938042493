import React from 'react';
import ModalEditUser from "../modals/edit-user";
import ModalAddUser from "../modals/add-user";
import ModalReviewUser from "../modals/review-user";


export class TableUser
    extends React.Component {

    stopPropagation = (e) => {
        e.stopPropagation();
    };
    render() {
        const {users, handleChangeState,showEditModal,hiddenEditModal,visibleEditModal, visibleReviewModal,
            showReviewModal, hiddenReviewModal} = this.props;
        return (
            <div className="Conteiner-table">
                {visibleEditModal && (
                    <ModalEditUser
                        stop={this.stopPropagation.bind(this)}
                        hidden={hiddenEditModal}/>
                )}
                {visibleReviewModal && (
                    <ModalReviewUser
                        stop={this.stopPropagation.bind(this)}
                        hidden={hiddenReviewModal}/>
                )}
                {/*{this.state.isAddUser && (*/}
                {/*    <ModalAddUser*/}
                {/*        stop={this.stopPropagation.bind(this)}*/}
                {/*        hidden={this.handleHiddenModal.bind(this)}/>*/}
                {/*)}*/}
                <table className="table-info-product1">
                    <thead>
                    <tr>
                        <th>Nombre - Razón Social</th>
                        <th>Cedula - NIT</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th>Persona de Contacto</th>
                        <th>Teléfono de Contacto</th>
                        <th>Ciudad</th>
                        <th>Dirección</th>
                        <th>Tipo de Persona</th>
                        <th>Género</th>
                        <th>Foto /Logo</th>
                        <th>Tipo de Perfil</th>
                        <th>Tipo de profesional</th>
                        <th>Reseñas</th>
                        <th>Acción</th>
                        <th>Estado</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.length > 0 && users.map((user, index) => (
                        <tr key={index}>
                            <td>{user.nombre}</td>
                            <td>{user.identificacion}</td>
                            <td>{user.correo}</td>
                            <td>{user.telefono}</td>
                            <td>{user.persona_contacto}</td>
                            <td>{user.telefono_contacto}</td>
                            <td>{user.ciudad.nombre}</td>
                            <td>{user.direccion}</td>
                            <td>{user.tipo_persona.nombre}</td>
                            <td>{user.genero.nombre}</td>
                            <td>{<img src={user.foto} alt=""
                                  style={{width: 90, height: 90}}/>}</td>
                            <td>{user.tipo_perfil.nombre}</td>
                            <td>{user.tipo_profesional.nombre}</td>
                            <td>
                                <button  className="Btn-item-table"
                                         onClick={()=>showReviewModal(user)}
                                >Reseña<i
                                    className="icon-pencil styles-icons"/></button>
                            </td>
                            <td>
                                <button  className="Btn-item-table" onClick={()=>showEditModal(user)}>Editar<i
                                    className="icon-pencil styles-icons"/></button>
                            </td>
                            <td><input className="style-checkbox" type="checkbox"
                                       onClick={ () => handleChangeState(user.id)}
                                    checked={user.estado.nombre === 'Habilitado'}/>
                                <p className={user.estado.nombre !== 'Habilitado' ? "style-checkbox-on" : "style-checkbox-off"}>
                                    {user.estado.nombre}</p></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
import {call, put, all, takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {CHECK_AUTH} from "./constans";
import {checkAuthError, checkAuthSuccess} from "./actions";
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {meGetRequesting} from "../auth/user/actions";

const checkJWT = `${process.env.REACT_APP_API_URL}/auth/verifyAdministrator`;

const checkTokenJWT = () => {
    let token = localStorage.getItem('@tuconstruccion:admin');
    return fetch(checkJWT, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 400)
                throw json.data.data;
            if (json.code === 200)
                return token;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};


function* checkAuthFlow() {
    try {
        const token = yield call(checkTokenJWT);
        yield put(checkAuthSuccess(token));
        yield put(meGetRequesting(token));
    } catch (error) {
        yield put(checkAuthError(error))
    }
}

function* checkAuthWatcher() {
    yield all([
        takeEvery(CHECK_AUTH, checkAuthFlow)
    ])
}

export default checkAuthWatcher;
import React from 'react';
import ImgLogin3 from "../../../assets/img/candado-registro.png";
import {ModalToken} from "./validate-token";


export class ModalNewPassword extends React.Component {

    state = {
        isToken  : false,
    };
    handleShowModal = (modal) => {
        this.setState({
            isToken : modal === 'validar token',

        })
    };
    handleHiddenModal = () => {
        this.setState({
            isToken  :false,
        })
    };
    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };
    render() {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="password-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="item-modal-img">
                        <img src={ImgLogin3} alt=""/>
                    </div>
                    <div className="text-modal">
                        <h1>Ingresa una nueva contraseña</h1>
                    </div>
                    <div id="item-modal-p">
                        <p>Ingresa tu nueva contraseña</p>
                        <input type="text" className="input input-name" placeholder="Digita tu correo de registro"/>
                        <p>Confirmar tu contraseña</p>
                        <input type="text" className="input input-name" placeholder="Digita tu correo de registro"/>
                    </div>
                    {this.state.isToken && (
                        <ModalToken
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    <div className="button-container">
                        <div >
                            <button className="Btn-modal">Ingresar<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export const PUBLICATIONS_GET_REQUESTING = 'PUBLICATIONS_GET_REQUESTING';
export const PUBLICATIONS_GET_SUCCESS = 'PUBLICATIONS_GET_SUCCESS';
export const PUBLICATIONS_GET_ERROR = 'PUBLICATIONS_GET_ERROR';

export const PUBLICATION_FOCUS = 'PUBLICATION_FOCUS';
export const PUBLICATION_FOCUS_CLEAN = 'PUBLICATION_FOCUS_CLEAN';

export const PUBLICATION_DISABLE_PUT_REQUESTING = 'PUBLICATION_DISABLE_PUT_REQUESTING';
export const PUBLICATION_DISABLE_PUT_SUCCESS = 'PUBLICATION_DISABLE_PUT_SUCCESS';
export const PUBLICATION_DISABLE_PUT_ERROR = 'PUBLICATION_DISABLE_PUT_ERROR';

export const PUBLICATION_ENABLE_PUT_REQUESTING = 'PUBLICATION_ENABLE_PUT_REQUESTING';
export const PUBLICATION_ENABLE_PUT_SUCCESS = 'PUBLICATION_ENABLE_PUT_SUCCESS';
export const PUBLICATION_ENABLE_PUT_ERROR = 'PUBLICATION_ENABLE_PUT_ERROR';

export const PUBLICATIONS_SEARCH_POST_REQUESTING = 'PUBLICATIONS_SEARCH_POST_REQUESTING';
export const PUBLICATIONS_SEARCH_POST_SUCCESS = 'PUBLICATIONS_SEARCH_POST_SUCCESS';
export const PUBLICATIONS_SEARCH_POST_ERROR = 'PUBLICATIONS_SEARCH_POST_ERROR';

export const PUBLICATION_SHOW_MODAL = 'PUBLICATION_SHOW_MODAL';
export const PUBLICATION_HIDDEN_MODAL = 'PUBLICATION_HIDDEN_MODAL';

export const PUBLICATION_RESET_STATES = 'PUBLICATION_RESET_STATES';
import React from 'react';
import {ModalEditSubscription} from "../modals/edit-subscription";


export class TableSubscription
    extends React.Component {

    state = {
        xxxxxx: false,
    };
    handleShowModal = (modal) => {
        this.setState({
            isOrders: modal === 'xxxxxxxxxx',

        })
    };
    handleHiddenModal = () => {
        this.setState({
            xxxxxxx:false,
// *******************************
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const{subscriptions}=this.props;
        return (
            <div className="Conteiner-table">
                {this.state.xxxxxxx && (
                    <ModalEditSubscription
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                <table className="table-info-product1">
                    <thead>
                    <tr>
                        <th>Nombre Cliente</th>
                        <th>Cedula - NIT</th>
                        <th>Subscripcion</th>
                        <th>Descripción</th>
                        <th>Valor</th>
                        <th>Cantidad Publicaciones</th>
                        <th>Tipo de Pago</th>
                        <th>Estado de Pago</th>
                        <th>Fecha inicio</th>
                        <th>Fecha vencimiento</th>
                        <th>Estado</th>
                    </tr>
                    </thead>
                    <tbody>
                    {subscriptions.length>0 && subscriptions.map((subscription,index) =>(
                    <tr key={index}>
                        <td>{subscription.usuario.nombre}</td>
                        <td>{subscription.usuario.identificacion}</td>
                        <td>{subscription.suscripcion.nombre}</td>
                        <td>{subscription.suscripcion.descripcion}</td>
                        <td>{subscription.suscripcion.precio}</td>
                        <td>{subscription.suscripcion.numero_publicaciones}</td>
                        <td>{subscription.tipo_de_pago}</td>
                        <td>{subscription.estado_pago}</td>
                        <td>{subscription.fecha_inicio}</td>
                        <td>{subscription.fecha_vencimiento}</td>
                        <td><input className="style-checkbox" type="checkbox"
                                   checked={subscription.estado === 'Habilitado'}
                        />
                            <p className={subscription.estado !== 'Habilitado'? "style-checkbox-on" : "style-checkbox-off"}>{subscription.estado}</p>
                        </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
import {handleApiErrors} from "./../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {getRolError, getRolSuccess} from "./actions";
import {GET_ROL_REQUESTING} from "./constants";

const ROL_URL = `${process.env.REACT_APP_API_URL}/roles`;


const getRolAPI = () => {

    return fetch(ROL_URL, {
        method: 'GET'
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getRolesFlow(action) {
    try{
        const {} = action;
        const roles = yield call(getRolAPI);
        yield put(getRolSuccess(roles));
    }catch (error) {
        yield put(getRolError(error));
    }
}

function* rolWatcher() {
    yield all([
        takeEvery(GET_ROL_REQUESTING, getRolesFlow)
    ])
}

export default rolWatcher;
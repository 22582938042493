import React from 'react';
import ModalEditPlan from "../modals/edit-plan";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import {showModalEditSubscription, hiddenModalEditSubscription} from "../../../redux/subscription/actions";

class TablePlans extends React.Component {

    handleShowModal = (modal, subscription) => {
        const {focusSubscription, showModalEditSubscription} = this.props;
        focusSubscription(subscription);
        showModalEditSubscription();
    };
    handleHiddenModal = () => {
        const {hiddenModalEditSubscription} = this.props;
        hiddenModalEditSubscription();
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {subscriptions, changeState, modalSubscription: {isModalEditSubscription}} = this.props;
        return (
            <div className="Conteiner-table">
                {isModalEditSubscription && (
                    <ModalEditPlan
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}
                    />
                )}

                <table className="table-info-product1">
                    <thead>
                    <tr>
                        <th>Plan</th>
                        <th>Descripción</th>
                        <th>Cantidad Publicaciones</th>
                        <th>Duración(Meses)</th>
                        <th>Valor</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {subscriptions.length > 0 && subscriptions.map((subscription, index) => (
                        <tr key={index}>
                            <td>{subscription.nombre}</td>
                            <td>{subscription.descripcion}</td>
                            <td>{subscription.numero_publicaciones}</td>
                            <td>{subscription.duracion}</td>
                            <td>{subscription.precio}</td>
                            <td><input className="style-checkbox" type="checkbox"
                                       onClick={() => changeState(subscription.id)}
                                       checked={subscription.estado === 'Habilitado'}
                            />
                                <p className={subscription.estado !== 'Habilitado' ? "style-checkbox-on" : "style-checkbox-off"}>{subscription.estado}</p>
                            </td>
                            <td>
                                <button className="Btn-item-table"
                                        onClick={this.handleShowModal.bind(this, 'Editar plan', subscription)}>Editar<i
                                    className="icon-pencil styles-icons"/></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    modalSubscription: state.subscription
});
const connected = connect(mapStateToProps, {
    showModalEditSubscription,
    hiddenModalEditSubscription
})(TablePlans);

const formed = reduxForm({
    form: 'TablePlans'
})(connected);

export default formed;
import React from 'react';
import ImgLogin2 from './../../assets/img/Logo-backOffice.png';
import ImgLogin3 from './../../assets/img/Logo-backOffice.png';
import {ModalPassword} from "./modals/forgot-password";
import {reduxForm, Field, Form} from "redux-form";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {loginPostRequesting} from "../../redux/auth/login/actions";

class LoginBack extends React.Component {

    state = {
        isPassword: false,

    };
    handleShowModal = (modal) => {
        this.setState({
            isPassword: modal === 'contraseña',

        })
    };
    handleHiddenModal = () => {
        this.setState({
            isPassword: false,
        })
    };
    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    handleSubmit = (values) => {
        const {loginPostRequesting} = this.props;
        loginPostRequesting(values);
    };

    render() {
        const {
            handleSubmit,
            login: {success},
            client: {isLogged}
        } = this.props;
        if (success || isLogged)
            return <Redirect from={'/'} to={'productos'}/>;
        return (
            <div>
                <div className="container-register container-register-img">
                    <div className="container-info">
                        <div className="info">
                            <button href="#" className="title">Tu construcción</button>
                            <div className="title-register">Administrador proveedor</div>
                            <br/>
                            <div className="step">
                                <span className="circle">1</span>
                                <h2>Gestiona entregas</h2>
                            </div>
                            <div className="step">
                                <span className="circle">2</span>
                                <h2>Controla tu negocio</h2>
                            </div>
                            <div className="step">
                                <span className="circle">3</span>
                                <h2>Administración de información</h2>
                            </div>
                        </div>
                    </div>
                    <div className="form">
                        <div id="form-register">
                            <img className="hiden" src={ImgLogin2} alt=""/>
                            <img className="hiden2" src={ImgLogin3} alt=""/>
                            <h1 className="title-form-h1">Iniciar sesión</h1>
                            <p>Administrador proveedor</p>
                            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                                <div className="input-group">
                                    <Field name={'email'} type="email" placeholder="Email" className="email" required
                                           component={'input'}/>
                                </div>
                                <div className="input-group">
                                    <Field name={'password'} type="password" placeholder="Contraseña" className="password" required
                                           component={'input'}/>
                                </div>
                                <div className="modal-error hidden ">
                                    <label className="incorrect">Datos incorrectos</label>
                                </div>
                                <button className="register" action="submit">
                                    Ingresar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </Form>
                            <div className="separate">
                                <span
                                    onClick={this.handleShowModal.bind(this, 'contraseña')}>¿ Olvido su contraseña ?</span>
                            </div>
                            {this.state.isPassword && (
                                <ModalPassword
                                    stop={this.stopPropagation.bind(this)}
                                    hidden={this.handleHiddenModal.bind(this)}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    login: state.login,
});

const connected = connect(mapStateToProps, {
    loginPostRequesting
})(LoginBack);

const formed = reduxForm({
    form: 'login'
})(connected);

export default formed;
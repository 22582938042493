export const CATEGORIES_MODULE_GET_REQUESTING = 'CATEGORIES_MODULE_GET_REQUESTING';
export const CATEGORIES_MODULE_GET_SUCCESS = 'CATEGORIES_MODULE_GET_SUCCESS';
export const CATEGORIES_MODULE_GET_ERROR = 'CATEGORIES_MODULE_GET_ERROR';

export const CATEGORIES_GET_REQUESTING = 'CATEGORIES_GET_REQUESTING';
export const CATEGORIES_GET_SUCCESS = 'CATEGORIES_GET_SUCCESS';
export const CATEGORIES_GET_ERROR = 'CATEGORIES_GET_ERROR';

export const CATEGORY_CREATE_POST_REQUESTING = 'CATEGORY_CREATE_POST_REQUESTING';
export const CATEGORY_CREATE_POST_SUCCESS = 'CATEGORY_CREATE_POST_SUCCESS';
export const CATEGORY_CREATE_POST_ERROR = 'CATEGORY_CREATE_POST_ERROR';

export const CATEGORY_FOCUS = 'CATEGORY_FOCUS';

export const CATEGORY_UPDATE_PUT_REQUESTING = 'CATEGORY_UPDATE_PUT_REQUESTING';
export const CATEGORY_UPDATE_PUT_SUCCESS = 'CATEGORY_UPDATE_PUT_SUCCESS';
export const CATEGORY_UPDATE_PUT_ERROR = 'CATEGORY_UPDATE_PUT_ERROR';

export const CATEGORIES_SEARCH_POST_REQUESTING = 'CATEGORIES_SEARCH_POST_REQUESTING';
export const CATEGORIES_SEARCH_POST_SUCCESS = 'CATEGORIES_SEARCH_POST_SUCCESS';
export const CATEGORIES_SEARCH_POST_ERROR = 'CATEGORIES_SEARCH_POST_ERROR';

export const CATEGORY_STATE_DELETE_REQUESTING = 'CATEGORY_STATE_DELETE_REQUESTING';
export const CATEGORY_STATE_DELETE_SUCCESS = 'CATEGORY_STATE_DELETE_SUCCESS';
export const CATEGORY_STATE_DELETE_ERROR = 'CATEGORY_STATE_DELETE_ERROR';

export const CATEGORY_SHOW_MODAL = 'CATEGORY_SHOW_MODAL';
export const CATEGORY_HIDDEN_MODAL = 'CATEGORY_HIDDEN_MODAL';

export const CATEGORY_RESET_STATES = 'CATEGORY_RESET_STATES';
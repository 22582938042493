import {
    CATEGORIES_GET_ERROR,
    CATEGORIES_GET_REQUESTING,
    CATEGORIES_GET_SUCCESS,
    CATEGORIES_MODULE_GET_ERROR,
    CATEGORIES_MODULE_GET_REQUESTING,
    CATEGORIES_MODULE_GET_SUCCESS,
    CATEGORIES_SEARCH_POST_ERROR,
    CATEGORIES_SEARCH_POST_REQUESTING,
    CATEGORIES_SEARCH_POST_SUCCESS,
    CATEGORY_CREATE_POST_ERROR,
    CATEGORY_CREATE_POST_REQUESTING,
    CATEGORY_CREATE_POST_SUCCESS,
    CATEGORY_FOCUS, CATEGORY_HIDDEN_MODAL,
    CATEGORY_RESET_STATES,
    CATEGORY_SHOW_MODAL,
    CATEGORY_STATE_DELETE_ERROR,
    CATEGORY_STATE_DELETE_REQUESTING,
    CATEGORY_STATE_DELETE_SUCCESS,
    CATEGORY_UPDATE_PUT_ERROR,
    CATEGORY_UPDATE_PUT_REQUESTING,
    CATEGORY_UPDATE_PUT_SUCCESS
} from './constants';

export const categoriesModuleGetRequesting = (token, values) => ({
    type: CATEGORIES_MODULE_GET_REQUESTING,
    token, values,
});

export const categoriesModuleGetSuccess = (categories) => ({
    type: CATEGORIES_MODULE_GET_SUCCESS,
    categories,
});

export const categoriesModuleGetError = (error) => ({
    type: CATEGORIES_MODULE_GET_ERROR,
    error,
});

export const categoriesGetRequesting = (token, filter, paginate) => ({
    type: CATEGORIES_GET_REQUESTING,
    token, filter, paginate,
});

export const categoriesGetSuccess = (categories) => ({
    type: CATEGORIES_GET_SUCCESS,
    categories,
});

export const categoriesGetError = (error) => ({
    type: CATEGORIES_GET_ERROR,
    error,
});

export const categoryCreatePostRequesting = (token, values) => ({
    type: CATEGORY_CREATE_POST_REQUESTING,
    token, values,
});

export const categoryCreatePostSuccess = (category) => ({
    type: CATEGORY_CREATE_POST_SUCCESS,
    category,
});

export const categoryCreatePostError = (error) => ({
    type: CATEGORY_CREATE_POST_ERROR,
    error,
});

export const categoryFocus = (category) => ({
    type: CATEGORY_FOCUS,
    category,
});

export const categoryUpdatePutRequesting = (token, values) => ({
    type: CATEGORY_UPDATE_PUT_REQUESTING,
    token, values,
});

export const categoryUpdatePutSuccess = (category) => ({
    type: CATEGORY_UPDATE_PUT_SUCCESS,
    category,
});

export const categoryUpdatePutError = (error) => ({
    type: CATEGORY_UPDATE_PUT_ERROR,
    error,
});

export const categoriesSearchPostRequesting = (token, search) => ({
    type: CATEGORIES_SEARCH_POST_REQUESTING,
    token, search,
});

export const categoriesSearchPostSuccess = (categories) => ({
    type: CATEGORIES_SEARCH_POST_SUCCESS,
    categories,
});

export const categoriesSearchPostError = (error) => ({
    type: CATEGORIES_SEARCH_POST_ERROR,
    error,
});

export const categoryStateDeleteRequesting = (token, categoryId) => ({
    type: CATEGORY_STATE_DELETE_REQUESTING,
    token, categoryId,
});

export const categoryStateDeleteSuccess = (category) => ({
    type: CATEGORY_STATE_DELETE_SUCCESS,
    category,
});

export const categoryStateDeleteError = (error) => ({
    type: CATEGORY_STATE_DELETE_ERROR,
    error,
});

export const categoryShowModal = (modal) => ({
    type: CATEGORY_SHOW_MODAL,
    modal,
});

export const categoryHiddenModal = (modal) => ({
    type: CATEGORY_HIDDEN_MODAL,
    modal,
});

export const categoryResetStates = () => ({
    type: CATEGORY_RESET_STATES,
});
import React from 'react';
import connect from "react-redux/es/connect/connect";
import {reduxForm, Field} from "redux-form";
import {updateAdminRequesting} from "../../../redux/user/actions";


class ModalEditAdmin extends React.Component {

    handleAction = (values) => {
        const {updateAdminRequesting,client:{token}} =this.props;
            updateAdminRequesting(token,values);
    };

    render() {
        const {
            handleSubmit,
        } = this.props;
        return (
            <div style={divStyle} className={'body-blur'} onClick={() => this.props.hidden()}>
                <div style={divStyle} className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={() => this.props.hidden()}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Editar datos</h1>
                    </div>
                    {/*<div className="text-modal">*/}
                    {/*    <h2>Información de compra</h2>*/}
                    {/*</div>*/}
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Correo</label>
                            <Field name={'correo'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Contraseña: </label>
                            <Field name={'contrasena'} type="password" className="input input-city"
                                   component={'input'}/>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={() => this.props.hidden()}>Cancelar<i
                                    className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal" type={'submit'}>Editar<i
                                    className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const divStyle = {
    height: '310px',
};

const formed = reduxForm({
    form: 'ModalEditAdmin'
})(ModalEditAdmin);

const mapStateToProps = state => ({
    initialValues: state.me.user,
    client: state.client
});

const connected = connect(mapStateToProps, {
    updateAdminRequesting
})(formed);

export default connected;

import {MODULE_RESET_STATES, MODULES_GET_ERROR, MODULES_GET_REQUESTING, MODULES_GET_SUCCESS} from "./constants";

export const modulesGetRequesting = (token) => ({
    type: MODULES_GET_REQUESTING,
    token,
});

export const modulesGetSuccess = (modules) => ({
    type: MODULES_GET_SUCCESS,
    modules
});

export const modulesGetError = (error) => ({
    type: MODULES_GET_ERROR,
    error,
});

export const moduleResetStates = () => ({
    type: MODULE_RESET_STATES,
});
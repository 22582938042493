import React from 'react';

export class TableCategories extends React.Component {

    render() {
        const {categories, handleChangeState, handleFocus, handleShowModal} = this.props;
        return (
            <div className="Conteiner-table">
                <table className="table-info-product1">
                    <thead>
                    <tr>
                        <th>Categoría</th>
                        <th>Foto</th>
                        <th>Módulo</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map((category, index) => (
                        <tr key={index}>
                            <td>{category.categoria}</td>
                            {category.foto !== false && (
                                <td><img style={{width: '40%'}} src={category.foto} alt={category.categoria}/></td>
                            )}
                            {category.foto === false && (
                                <td>Sin foto</td>
                            )}
                            <td>{category.modulo.label}</td>
                            <td>
                                <input className="style-checkbox" type="checkbox"
                                       onClick={() => handleChangeState(category.id)}
                                       checked={category.estado.nombre === 'Habilitado'}
                                />
                                <p className={category.estado.nombre !== 'Habilitado' ? "style-checkbox-on" : "style-checkbox-off"}>
                                    {category.estado.nombre}
                                </p>
                            </td>
                            <td>
                                <button className="Btn-item-table"
                                        onClick={() => {
                                            handleShowModal('edit');
                                            handleFocus(category)
                                        }}>
                                    Editar
                                    <i className="icon-pencil styles-icons"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}




import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {modulesGetRequesting} from "../../../redux/module/actions";

class ModuleSelectComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            client: {token},
            modulesGetRequesting,
        } = this.props;
        modulesGetRequesting(token);
    }

    render() {
        const {
            module: {modules},
            type,
            initialValues,
        } = this.props;
        switch (type) {
            case 'filter':
                return (
                    <Field name={'module'} type={'select'} component={'select'}>
                        <option value={'Todos'}>Todos</option>
                        {modules.length > 0 && modules.map((moduleData, index) => (
                            <option key={index} value={moduleData.id}>{moduleData.label/*- {moduleData.modulo}*/}</option>
                        ))}
                    </Field>
                );
            case 'add':
                return (
                    <Field name={'module'} type={'select'} component={'select'}>
                        <option>Seleccione una opción</option>
                        {modules.length > 0 && modules.map((moduleData, index) => (
                            <option key={index} value={moduleData.id}>{moduleData.label} {/*- moduleData.modulo*/}</option>
                        ))}
                    </Field>
                );
            case 'edit':
                return (
                    <Field name={'module'} type={'select'} component={'select'}>
                        <option value={initialValues.id}>{initialValues.label} {/*-initialValues.modulo */}</option>
                        {modules.length > 0 && modules.map((moduleData, index) => {
                            if (moduleData.id !== initialValues.id) {
                                return (
                                    <option key={index}
                                            value={moduleData.id}>{moduleData.label} {/*- {moduleData.modulo*/}</option>
                                )
                            }
                        })}
                    </Field>
                );
            default :
                return (
                    <Field name={'module'} type={'select'} component={'select'}>
                        <option>Seleccione una opción</option>
                        {modules.length > 0 && modules.map((moduleData, index) => (
                            <option key={index} value={moduleData.id}>{moduleData.label} {/*- moduleData.modulo*/}</option>
                        ))}
                    </Field>
                );
        }
    }
}

const mapStateToProps = state => ({
    client: state.client,
    module: state.module,
});

const connected = connect(mapStateToProps, {
    modulesGetRequesting
})(ModuleSelectComponent);

export default connected;
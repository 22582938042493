import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    GET_SUBSCRIPTION_REQUESTING,
    GET_STATES_SUBSCRIPTION_REQUESTING,
    SEARCH_SUBSCRIPTION_REQUESTING,
    CHANGE_STATE_SUBSCRIPTION_REQUESTING,
    CREATE_SUBSCRIPTION_REQUESTING,
    UPDATE_SUBSCRIPTION_REQUESTING,
    GET_ALL_SUBSCRIPTION_REQUESTING
} from './constants';

import {
    getSubscriptionSuccess,
    getSubscriptionError,
    getStatesSubscriptionSuccess,
    getStatesSubscriptionError,
    searchSubscriptionSuccess,
    searchSubscriptionError,
    changeStateSubscriptionSuccess,
    changeStateSubscriptionError,
    createSubscriptionError,
    createSubscriptionSuccess,
    updateSubscriptionError,
    updateSubscriptionSuccess,
    errorControlSubscription,
    hiddenModalEditSubscription,
    getAllSubscriptionError,
    getAllSubscriptionSuccess
} from './actions';

import {hiddenModalConfig} from "../config/actions";

const subscriptionsURL = `${process.env.REACT_APP_API_URL}/subscriptions`;
const stateSubscriptionsURL = `${process.env.REACT_APP_API_URL}/states/index/subscription`;
const searchSubscriptionsURL = `${process.env.REACT_APP_API_URL}/searches/subscriptions`;
const changeStateSubscriptionsURL = `${process.env.REACT_APP_API_URL}/states/subscription/`;

//Crear suscripcion
const createSubscriptionApi = (subscription, token) => {

    let body = new FormData();
    if (subscription.name !== undefined)
        body.append('name', subscription.name);
    if (subscription.description !== undefined)
        body.append('description', subscription.description);
    if (subscription.price !== undefined)
        body.append('price', subscription.price);
    if (subscription.duration !== undefined)
        body.append('duration', subscription.duration);
    if (subscription.number_publication !== undefined)
        body.append('number_publication', subscription.number_publication);
    return fetch(subscriptionsURL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createSubscriptionFlow(action) {
    try {
        const {subscription, token} = action;
        const subscriptionCreated = yield call(createSubscriptionApi, subscription, token);
        yield put(createSubscriptionSuccess(subscriptionCreated));
        yield put(hiddenModalConfig());
        toastr.success('Subscripcion creada', 'Subscripcion creada, ya puede ver las subscripciones');
    } catch (error) {
        yield put(createSubscriptionError(error));
        yield put(errorControlSubscription());
    }
}

//Actualizar suscripcion
const updateSubscriptionApi = (subscription, token, id) => {

    let body = new FormData();
    body.append('_method', 'PUT');
    if (subscription.nombre !== undefined)
        body.append('name', subscription.nombre);
    if (subscription.descripcion !== undefined)
        body.append('description', subscription.descripcion);
    if (subscription.precio !== undefined)
        body.append('price', subscription.precio);
    if (subscription.duracion !== undefined)
        body.append('duration', subscription.duracion);
    if (subscription.numero_publicaciones !== undefined)
        body.append('number_publication', subscription.numero_publicaciones);

    return fetch(`${subscriptionsURL}/${id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw {error: json.data.data};
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateSubscriptionFlow(action) {
    try {
        const {subscription, token, subscriptionId} = action;
        const subscriptionUpdate = yield call(updateSubscriptionApi, subscription, token, subscriptionId);
        yield put(updateSubscriptionSuccess(subscriptionUpdate));
        yield put(hiddenModalEditSubscription());
        toastr.success('Subscripcion actualizada', 'Subscripcion actualizada, ya puede ver las subscripciones');
    } catch (error) {
        yield put(updateSubscriptionError(error));
        yield put(errorControlSubscription());
    }
}

//Cambiar estado suscripciones
const changeStateSubscriptionApi = (subscriptionId, token) => {

    return fetch(changeStateSubscriptionsURL + subscriptionId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* changeStateSubscriptionFlow(action) {
    try {
        const {subscriptionId, token} = action;
        const stateSubscription = yield call(changeStateSubscriptionApi, subscriptionId, token);
        yield put(changeStateSubscriptionSuccess(stateSubscription));
    } catch (error) {
        yield put(changeStateSubscriptionError(error));
    }
}

//Buscar suscripciones
const searchSubscriptionAPI = (search, token) => {
    let body = {
        search: search
    };

    return fetch(searchSubscriptionsURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* searchSubscriptionFlow(action) {
    try {
        const {search, token} = action;
        const subscription = yield call(searchSubscriptionAPI, search, token);
        yield put(searchSubscriptionSuccess(subscription));
    } catch (error) {
        yield put(searchSubscriptionError(error));
    }
}

//Obtener estados suscripciones

const getStatesSubscriptionApi = (token) => {
    return fetch(stateSubscriptionsURL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getStatesSubscriptionFlow(action) {
    try {
        const {token} = action;
        const states = yield call(getStatesSubscriptionApi, token);
        yield put(getStatesSubscriptionSuccess(states));
    } catch (error) {
        yield put(getStatesSubscriptionError(error));
    }
}

//Obtener suscripciones
const getSubscriptionsApi = (paginate, filter, token) => {

    const url = `${subscriptionsURL}/filter/${filter}?page=${paginate}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getSubscriptionsFLow(action) {
    try {
        const {paginate, filter, token} = action;
        const getSubscriptions = yield call(getSubscriptionsApi, paginate, filter, token);
        yield put(getSubscriptionSuccess(getSubscriptions));
    } catch (error) {
        yield put(getSubscriptionError(error));
    }
}

//Obtener todas lassuscripciones
const getAllSubscriptionsApi = (token, user) => {

    const body = {
        user_id: user
    };

    return fetch(`${subscriptionsURL}/backoffice`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getAllSubscriptionsFLow(action) {
    try {
        const {token, user} = action;
        const getSubscriptions = yield call(getAllSubscriptionsApi, token, user);
        yield put(getAllSubscriptionSuccess(getSubscriptions));
    } catch (error) {
        yield put(getAllSubscriptionError(error));
    }
}

function* usersWatcher() {
    yield all([
        takeEvery(GET_SUBSCRIPTION_REQUESTING, getSubscriptionsFLow),
        takeEvery(GET_STATES_SUBSCRIPTION_REQUESTING, getStatesSubscriptionFlow),
        takeEvery(SEARCH_SUBSCRIPTION_REQUESTING, searchSubscriptionFlow),
        takeEvery(CHANGE_STATE_SUBSCRIPTION_REQUESTING, changeStateSubscriptionFlow),
        takeEvery(CREATE_SUBSCRIPTION_REQUESTING, createSubscriptionFlow),
        takeEvery(UPDATE_SUBSCRIPTION_REQUESTING, updateSubscriptionFlow),
        takeEvery(GET_ALL_SUBSCRIPTION_REQUESTING, getAllSubscriptionsFLow)
    ])
}

export default usersWatcher

import {
    GET_ALL_PAYMENT_METHOD_ERROR,
    GET_ALL_PAYMENT_METHOD_REQUESTING,
    GET_ALL_PAYMENT_METHOD_SUCCESS
} from "./constants";

const initialState = {

    requesting: false,
    success: false,
    error: '',
    paymentMethods:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PAYMENT_METHOD_REQUESTING:
            return {
                ...state,
                error: '',
                success: false,
                requesting: false
            };
        case GET_ALL_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: action.paymentMethods,
                error: '',
                success: true,
                requesting: false
            };
        case GET_ALL_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                paymentMethods: []
            };
        default :
            return state;
    }
};
export default reducer;
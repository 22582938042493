import React from 'react';
import ImgLogin4 from "../../../assets/img/token.png";
import {ModalNewPassword} from "./new-password";

export class ModalToken extends React.Component {

    state = {
        isNewPassword  : false,
    };
    handleShowModal = (modal) => {
        this.setState({
            isNewPassword : modal === 'nueva contraseña',

        })
    };
    handleHiddenModal = () => {
        this.setState({
            isNewPassword  :false,
        })
    };
    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="password-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>

                    <div className="item-modal-img">
                        <img src={ImgLogin4} alt=""/>
                    </div>
                    <div className="text-modal">
                        <h1>Validar token</h1>
                    </div>
                    <div id="item-modal-p">
                        <p>Ingresa tu codigo de verificación</p>
                        <input type="text" className="input input-name" placeholder="Codigo de verificación"/>
                    </div>
                    {this.state.isNewPassword && (
                        <ModalNewPassword
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal">Enviar otro codigo<i className="icon-ok styles-icons"/></button>
                            <button className="Btn-modal" onClick={this.handleShowModal.bind(this,'nueva contraseña')}>Validar<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

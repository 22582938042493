import {GET_CITY_ERROR, GET_CITY_REQUESTING, GET_CITY_SUCCESS} from "./constants";

export const getCityRequesting = () => ({
    type: GET_CITY_REQUESTING
});

export const getCitySuccess = (cities) => ({
    type: GET_CITY_SUCCESS,
    cities
});

export const getCityError = (error) => ({
    type: GET_CITY_ERROR,
    error
});
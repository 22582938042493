import {all, fork} from 'redux-saga/effects';

import LoginSagas from './auth/login/sagas';
import ClientSagas from './client/sagas';
import MeUserSagas from './auth/user/sagas';
import ModuleSagas from './module/sagas';
import CategorySagas from './category/sagas';
import SubcategorySagas from './subcategory/sagas';
import subscriptionSagas from './subscription/sagas';
import PublicationSagas from '../redux/publication/sagas';
import UserSagas from './user/sagas';
import subscriptionsUserSagas from '../redux/subscription/user/sagas';
import paymentsMethodsSagas from '../redux/payment-method/sagas'
import citySagas from '../redux/city/sagas';
import typeProfileSagas from '../redux/typeProfile/sagas';
import typePeopleSagas from '../redux/typePeople/sagas';
import genderSagas from '../redux/gender/sagas';
import rolSagas from '../redux/rol/sagas';
import typeProfessionalSagas from '../redux/typeProfessional/sagas';
import reviewSagas from '../redux/review/sagas';

export function* IndexSagas() {
    yield all([
        fork(LoginSagas),
        fork(ClientSagas),
        fork(MeUserSagas),
        fork(CategorySagas),
        fork(SubcategorySagas),
        fork(ModuleSagas),
        fork(subscriptionSagas),
        fork(PublicationSagas),
        fork(UserSagas),
        fork(subscriptionsUserSagas),
        fork(paymentsMethodsSagas),
        fork(citySagas),
        fork(typeProfileSagas),
        fork(typeProfessionalSagas),
        fork(typePeopleSagas),
        fork(genderSagas),
        fork(rolSagas),
        fork(reviewSagas)
    ])
}
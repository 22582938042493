import React from 'react';
import connect from "react-redux/es/connect/connect";
import {checkAuth} from "../../redux/client/actions";
import MenuHome from "../../components/menu-home/menuHome";

class RoutesComponent extends React.Component {

    componentWillMount() {
        const {checkAuth} = this.props;
        checkAuth();
    }

    render() {
        return (
            <div>
                <MenuHome/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    client: state.client,
});

const connected = connect(mapStateToProps, {
    checkAuth,
})(RoutesComponent);

export default connected;
import React from 'react';
import ImgLogin2 from './../../assets/img/Logo-backOffice.png';
import ImgLogin3 from './../../assets/img/Logo-backOffice.png';
import Icon1 from './../../assets/icons/iconos-nav-1.png';
import Icon2 from './../../assets/icons/iconos-nav-2.png';
import Icon3 from './../../assets/icons/iconos-nav-3.png';
import Icon4 from './../../assets/icons/iconos-nav-4.png';
import Icon5 from './../../assets/icons/iconos-nav-5.png';
import Icon6 from './../../assets/icons/iconos-nav-6.png';
import Icon7 from './../../assets/icons/iconos-nav-7.png';
import IconSelectWhite from './../../assets/icons/iconos-bajar-blanco.svg';
// import IconSelectBlack from './../../assets/icons/iconos-bajar-negro.svg';
import $ from 'jquery';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {logoutRequesting} from "../../redux/auth/login/actions";
import {controlModalAdmin} from "../../redux/client/actions";
import ModalEditAdmin from  '../menu-home/modals/edit-admin';
import ModalEditUser from "../2-User/table/table-user";
import {ErrorComponent} from "../../commons/errors/errors";
import {errorControlAdmin} from "../../redux/user/actions";
import {toastr} from "react-redux-toastr";

class MenuHome extends React.Component {


    componentDidMount() {

        // Aca va las funciones del jquery

//funciones para menu header//

        $(document).ready(function () {

            $("#mostrar1").on("click", function () {
                $("#target1").slideToggle(200);
            });

            $("#target1").slideUp(100);
            $("#mostrar1").click(function (event) {
                event.stopPropagation();
                // Do something
            });

            $("#mostrar2,#mostrar33").on("click", function () {
                $("#target1").slideToggle(200);
            });
        });

        $("html").on("click", function () {

            $("#target1").slideUp(100);
            $("#mostrar2,#mostrar33").click(function (event) {
                event.stopPropagation();
                // Do something
            });
        });
//funciones para menu header//

// funciones para menu bar
        $(function () {
            //If the overlay is clicked, then we close the menu bars.
            $('.overlay').on('click', function () {
                $(this).fadeOut(300);
                $('.menu-bar').removeClass('is-active');
                $('.menu-button').removeClass('is-clicked');
            });

            $('.top-menu').on('click', function () {
                var side = $('.menu-button').data('menu');
                //If this has is-clicked, then we close the menu.
                if ($('.menu-button').hasClass('is-clicked')) {
                    //Since we created the code for overlay already.
                    $('.overlay').click();
                }
                //Otherwise, we open it.
                else {
                    $('.overlay').fadeIn(300);
                    $('.menu-button').addClass('is-clicked');
                    $('.menu-bar.' + side).addClass('is-active');
                }
            });
        });
// funciones para menu bar

//funciones para menu isquierdo//

        $(document).ready(function () {

            $("#mostrar3").on("click", function () {
                $("#target2").slideToggle(250);
            });

            $("#mostrar4").on("click", function () {
                $("#target2").slideToggle(250);
            });

            $("#mostrar5").on("click", function () {
                $("#target3").slideToggle(250);
            });

            $("#mostrar6").on("click", function () {
                $("#target3").slideToggle(250);
            });

            $("#mostrar7").on("click", function () {
                $("#target4").slideToggle(250);
            });

            $("#mostrar8").on("click", function () {
                $("#target4").slideToggle(250);
            });

            $("#mostrar9").on("click", function () {
                $("#target5").slideToggle(250);
            });

            $("#mostrar10").on("click", function () {
                $("#target5").slideToggle(250);
            });

            $("#mostrar11").on("click", function () {
                $("#target6").slideToggle(250);
            });

            $("#mostrar12").on("click", function () {
                $("#target6").slideToggle(250);
            });

            $("#mostrar13").on("click", function () {
                $("#target7").slideToggle(250);
            });

            $("#mostrar14").on("click", function () {
                $("#target7").slideToggle(250);
            });

            $("#mostrar15").on("click", function () {
                $("#target8").slideToggle(250);
            });

            $("#mostrar16").on("click", function () {
                $("#target8").slideToggle(250);
            });

//funcion//

        });

        // Aca va las funciones del jquery
    }

    handleLogout = () => {
        const {logoutRequesting, client: {token}} = this.props;
        logoutRequesting(token);
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    handleErrorModal = () =>{
        const{user:{error},errorControlAdmin}=this.props;
        console.log(error);
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlAdmin();
    };

    render() {
        const {client,controlModalAdmin,user:{errorAdmin}} = this.props;
        return (
            <div style={client.isLogged ? {} : {display: 'none'}}>
                <header>
                    {errorAdmin && this.handleErrorModal()}
                    <div className="body-data-site-header">
                        <div>
                            <div className="top-menu">
                                <div className="menu-button" data-menu="left"/>
                            </div>
                            <div className="menu-bar left menu-bar-on">
                                <div className="menu-body">
                                    <div id="body-menu-left">
                                        <div className="title-center-home">
                                            <h2>TU CONSTRUCCIÓN</h2>
                                        </div>
                                        <div className="container-data-site">
                                            <img src={ImgLogin3} alt="Logo Gou domicilio"/>
                                            <div className="date-site">
                                                <h3>Climatización</h3>
                                                <h3>Dirección xxxxxxxxxx</h3>
                                                <h4>Oficina xxxxxxx</h4>
                                            </div>
                                        </div>
                                        <div className="container-data-general">
                                            <div className="title-center-home">
                                                <h2>Módulo de servicios</h2>
                                            </div>



                                                <div className="Content-General">
                                                    <div className="icono1">
                                                        <img src={Icon1} alt=""/>
                                                    </div>
                                                    <Link to={'/productos'}>
                                                    <input type="button" className="styles3" id="mostrar3" name="boton1"
                                                           value="Productos"/>
                                                    </Link>
                                                    {/*<img src={IconSelectWhite} className="styles icono2" id="mostrar4"*/}
                                                    {/*     alt="select"/>*/}
                                                </div>

                                            <div id="target2">
                                            </div>


                                            <div className="Content-General">
                                                <div className="icono1">
                                                    <img src={Icon2} alt=""/>
                                                </div>
                                                <Link to={'/usuarios'}>
                                                <input type="button" className="styles3" id="mostrar5" name="boton1"
                                                       value="Usuarios"/>
                                                </Link>
                                                {/*<img className="styles icono2" id="mostrar6" src={IconSelectWhite}*/}
                                                {/*     alt=" select"/>*/}

                                            </div>

                                            <div id="target3">
                                            </div>





                                            <div className="Content-General">
                                                <div className="icono1">
                                                    <img src={Icon3} alt=""/>
                                                </div>
                                                <Link  to={"/suscripciones"}>
                                                <input type="button" className="styles3" id="mostrar7" name="boton1"
                                                       value="Suscripción"/>
                                                {/*<img className="styles icono2" id="mostrar8" src={IconSelectWhite}*/}
                                                {/*     alt=" select"/>*/}
                                                </Link>
                                            </div>
                                            <div id="target4">
                                            </div>


                                            <div className="Content-General">
                                                <div className="icono1">
                                                    <img src={Icon4} alt=""/>
                                                </div>
                                                <input type="button" className="styles3" id="mostrar9" name="boton1"
                                                       value="Configuración"/>
                                                <img className="styles icono2" id="mostrar10" src={IconSelectWhite}
                                                     alt=" select"/>
                                            </div>
                                            <div id="target5">
                                                <ul>
                                                    <li>
                                                        <Link className="btn-menu-home" to={"/configuracion/suscripcion"}>Configuración Suscripciones</Link>
                                                        {/*<button href="" className="btn-menu-home">Configuración Subscripciones</button>*/}
                                                    </li>

                                                    <li>
                                                        <Link to={"/configuracion/categoria"} className="btn-menu-home">Configuración Categorías</Link>
                                                    </li>

                                                    <li>
                                                        <Link to={"/configuracion/subcategoria"} className="btn-menu-home">Configuración Subcategorías</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay overlay-off"></div>
                        </div>
                        <div className="item-header">
                            <div className="body-menu-header">
                                <div className="icono1-header" id="mostrar33">
                                    <img src={ImgLogin2} alt="Logo Go domicilio"/>
                                </div>
                                <input type="button" className="styles-nav-header" id="mostrar1" name="boton1"
                                       value="Configuración"/>
                                <img src={IconSelectWhite} className="styles-nav-header icono2-header" id="mostrar2"
                                     alt=" select"/>
                            </div>
                            <div id="target1">
                                <ul>
                                    <li>
                                        <button onClick={()=>controlModalAdmin()} className="close-session">Editar datos</button>
                                    </li>
                                    <li>
                                        <button onClick={()=>this.handleLogout()} className="close-session">Cerrar sesion</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
                {
                    client.adminModal && (
                        <ModalEditAdmin
                            stop={this.stopPropagation.bind(this)}
                            hidden={controlModalAdmin}/>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user : state.user
});

const connected = connect(mapStateToProps, {
    logoutRequesting,
    controlModalAdmin,
    errorControlAdmin
})(MenuHome);

export default connected;

import React from 'react';
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import ModalSearchUser from './search-user-subscription';
import {createSubscriptionUserRequesting,} from '../../../redux/subscription/user/actions'

export class ModalAddSubscription extends React.Component {

    handleAction = (values) => {
        const {createSubscriptionUserRequesting, client: {token}} = this.props;
        createSubscriptionUserRequesting(values, token)
    };

    render() {
        const {
            handleSearchUser,
            subscription: {allSubscriptions},
            paymentMethods: {paymentMethods},
            handleSubmit,
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Agregar Subscripción</h1>
                    <ModalSearchUser
                        handleSearchUser={handleSearchUser.bind(this)}
                    />
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <br/>
                        <div className="item-modal">
                            <label>Nombre Cliente: </label>
                            <Field name={'nombre'} disabled type="text" className="input input-name"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Cedula - NIT: </label>
                            <Field name={'identification'} type="text" disabled className="input input-name"
                                   component={'input'}/>
                        </div>
                        {allSubscriptions && allSubscriptions.length > 0 && <div className="item-modal">
                            <label>subscripción: </label>
                            <Field name="subscription" type="text" className="input input-name" component={'select'}>
                                <option>Seleccione subscripción</option>
                                {allSubscriptions !== undefined && allSubscriptions.length > 0 && allSubscriptions.map((subscription, index) => (
                                    subscription.nombre !== 'Free' ? <option key={index}
                                                                             value={subscription.id}>{subscription.nombre}</option> : null
                                ))}
                            </Field>
                        </div>}

                        <div className="item-modal">
                            <label>Tipo de pago: </label>
                            <Field name="payment" type="text" className="input input-name" component={'select'}>
                                <option>Seleccione tipo de pago</option>
                                {paymentMethods !== undefined && paymentMethods.length > 0 && paymentMethods.map((payment, index) => (
                                    payment.nombre === 'Contra entrega' ?
                                        <option key={index} value={payment.id}>{payment.nombre}</option> : null
                                ))}
                            </Field>
                        </div>
                        {/*  <div className="item-modal">
                        <label>Valor: </label>
                        <Field name={'price'}   type="text" className="input input-name" component={'input'}/>
                    </div> */}
                        {/*<div className="item-modal">*/}
                        {/*    <label>Cantidad Meses: </label>*/}
                        {/*    <input type="text" className="input input-city"/>*/}
                        {/*</div>*/}
                        {/*<div className="item-modal">*/}
                        {/*    <label>Fecha Inicio: </label>*/}
                        {/*    <input type="text" className="input input-city"/>*/}
                        {/*</div>*/}
                        {/*<div className="item-modal">*/}
                        {/*    <label>Fecha Vencimiento: </label>*/}
                        {/*    <input type="text" className="input input-name"/>*/}
                        {/*</div>*/}
                        {/*<div className="item-modal">*/}
                        {/*    <label>Verificación: </label>*/}
                        {/*    <input type="text" className="input input-city"/>*/}
                        {/*</div>*/}
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i
                                    className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal">Crear subscripción<i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'ModalAddSubscription',
    enableReinitialize: true
})(ModalAddSubscription);

const mapStateToProps = state => ({
    client: state.client,
    subscription: state.subscription,
    paymentMethods: state.paymentMethods,
    subscriptionUser: state.subscriptionUser,
    initialValues: state.subscriptionUser.userSubscription
});

const connected = connect(mapStateToProps, {
    createSubscriptionUserRequesting
})(formed);

export default connected;

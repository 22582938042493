import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {meGetError, meGetSuccess} from "./actions";
import {ME_GET_REQUESTING} from "./constants";

const meUrl = `${process.env.REACT_APP_API_URL}/auth/me`;

const meGetApi = (token) => {
    return fetch(meUrl, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* meGetFlow(action) {
    try {
        const {token} = action;
        const user = yield call(meGetApi, token);
        yield put(meGetSuccess(user));
    } catch (error) {
        yield put(meGetError(error));
    }
}

function* meUserWatcher() {
    yield all([
        takeEvery(ME_GET_REQUESTING, meGetFlow),
    ])
}

export default meUserWatcher;
import React from 'react';
import {reduxForm, Field} from 'redux-form';

class SearchComponent extends React.Component {

    handleSubmit = (values) => {
        const {
            type,
            handleSearch
        } = this.props;
        handleSearch(values, type);
    };

    render() {
        const {handleSubmit} = this.props;
        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="conteiner-page-sector1">
                    <div className="Contenedor-consulta">
                        <div className="styles-title-page">
                            <h1 className="tex-align-h1">Gestionar {this.props.type}</h1>
                        </div>
                        <div className="formulario2">
                            <div className="item-formulario2">
                                <Field name={'search'} component={'input'} type="text"
                                       placeholder={this.props.placeholder}/>
                                <button type="submit" className="Btn-buscar">Buscar en {this.props.type}<i
                                    className="icon-search styles-icons2"/></button>
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                    <div className="container-notification">
                        <label className="incorrect hidden">El {this.props.type} con el id especificado no
                            existe</label>
                    </div>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'search',
})(SearchComponent)
import React from 'react';
import {File, FilePond} from "react-filepond";
import connect from "react-redux/es/connect/connect";
import {reduxForm, Field} from "redux-form";
import ImagesInput from "../../file-inputs/images";
import {filterGender} from "../../../redux/gender/actions";
import {visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
import {updateUserRequesting} from "../../../redux/user/actions";

class ModalEditUser extends React.Component {

    state = {files1: []};

    handleAction = (values) => {
        const {client: {token}, updateUserRequesting, user: {userDataFocus}} = this.props;
        console.log(values);
        if(values){
            updateUserRequesting(values, token,userDataFocus);
        }
    };

    render() {
        const {
            handleSubmit,
            city: {cities},
            initialValues,
            typeProfile: {typeProfiles},
            typePeople: {typePeoples},
            typeProfessional: {typeProfessional, visible},
            gender: {gendersFilter},
            rol: {roles},
            filterGender,
            visibleTypeProfessional
        } = this.props;
        return (
            <div style={divStyle} className={'body-blur'} onClick={() => this.props.hidden()}>
                <div style={divStyle} className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={() => this.props.hidden()}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <img src={initialValues.foto} alt={initialValues.nombre} style={{width: 100, height: 100}}/>
                    <div className="text-modal">
                        <h1>Editar usuario</h1>
                    </div>
                    {/*<div className="text-modal">*/}
                    {/*    <h2>Información de compra</h2>*/}
                    {/*</div>*/}
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Nombre - Razón Social: </label>
                            <Field name={'nombre'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Cedula - NIT: </label>
                            <Field name={'identificacion'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Correo: </label>
                            <Field name={'correo'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Teléfono: </label>
                            <Field name={'telefono'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Persona de Contacto: </label>
                            <Field name={'persona_contacto'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Teléfono de Contacto: </label>
                            <Field name={'telefono_contacto'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Dirección: </label>
                            <Field name={'direccion'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Ciudad: </label>
                            <Field component={'select'} name="ciudad" className="input input-city">
                                <option value={initialValues.ciudad.id}>{initialValues.ciudad.nombre}</option>
                                {cities.map((city, index) => {
                                        if (city.nombre !== initialValues.ciudad.nombre) {
                                            return <option  value={city.id} key={index}>{city.nombre}</option>
                                        }
                                    }
                                )}
                            </Field>
                        </div>

                        <div className="item-modal">
                            <label>Tipo de perfil: </label>
                            <Field component={'select'} name='tipo_perfil' className="input input-city">
                                <option value={initialValues.tipo_perfil.id}>{initialValues.tipo_perfil.nombre}</option>
                                {typeProfiles.map((typeProfile, index) => {
                                        if (typeProfile.nombre !== initialValues.tipo_perfil.nombre)
                                            return <option value={typeProfile.id} key={index}>{typeProfile.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>

                        <div className="item-modal">
                            <label>Tipo de Persona: </label>
                            <Field component={'select'} onChange={(e) => {
                                filterGender(typePeoples, e.target.value !== 'type_people' ? JSON.parse(e.target.value) : '');
                                visibleTypeProfessional(e.target.value !== 'type_people' ? JSON.parse(e.target.value) : '');
                            }} name="tipo_persona" className="input input-city">
                                <option
                                    value={initialValues.tipo_persona.id}>{initialValues.tipo_persona.nombre}</option>
                                {typePeoples.map((typePeople, index) => {
                                        if (typePeople.nombre !== initialValues.tipo_persona.nombre)
                                            return <option value={JSON.stringify(typePeople)}
                                                           key={index}>{typePeople.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>

                        {visible &&
                        <div className="item-modal">
                            <label>Tipo de Profesional: </label>
                            <Field component={'select'} name='tipo_profesional' className="input input-city">
                                <option
                                    value={initialValues.tipo_profesional.id}>{initialValues.tipo_profesional.nombre === 'No requerido' ? 'Seleccione profesional' : initialValues.tipo_profesional.nombre}</option>
                                {typeProfessional.map((typeProfessional, index) => {
                                        if (typeProfessional.nombre !== initialValues.tipo_profesional.nombre)
                                            return <option value={typeProfessional.id}
                                                           key={index}>{typeProfessional.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>
                        }

                        <div className="item-modal">
                            <label>Tipo de Género: </label>
                            <Field component={'select'} name='genero' className="input input-city">
                                <option value={initialValues.genero.id}>{initialValues.genero.nombre}</option>
                                {gendersFilter.map((gender, index) => {
                                        if (gender.nombre !== initialValues.genero.nombre)
                                            return <option value={gender.id} key={index}>{gender.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Tipo de Rol: </label>
                            <Field component={'select'} name='rol' className="input input-city">
                                <option value={initialValues.rol.id}>{initialValues.rol.nombre}</option>
                                {roles.map((rol, index) => {
                                        if (rol.nombre !== initialValues.rol.nombre)
                                            return <option value={rol.id} key={index}>{rol.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>

                        <label>Fotos: </label>
                        <Field name={'photos'} maxFiles={1} component={ImagesInput}/>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={() => this.props.hidden()}>Cancelar<i
                                    className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal" onClick={() => this.handleAction()}>Editar usuario<i
                                    className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const divStyle = {
    // height: '500px',
};

const formed = reduxForm({
    form: 'editModalUser'
})(ModalEditUser);

const mapStateToProps = state => ({
    user: state.user,
    client: state.client,
    city: state.city,
    initialValues: state.user.userDataFocus,
    typeProfile: state.typeProfile,
    typePeople: state.typePeople,
    gender: state.gender,
    rol: state.rol,
    typeProfessional: state.typeProfessional
});

const connected = connect(mapStateToProps, {
    updateUserRequesting,
    filterGender,
    visibleTypeProfessional
})(formed);

export default connected;
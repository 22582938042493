import React from 'react';
import connect from "react-redux/es/connect/connect";
import {Field, reduxForm} from "redux-form";
import {createUserRequesting} from "../../../redux/user/actions";
import {filterGender} from "../../../redux/gender/actions";
import {visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
//***********import upload files****************
import {FilePond, File, registerPlugin} from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import ImagesInput from '../../file-inputs/images';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

//***********import upload files****************

class ModalAddUser extends React.Component {

    state = {files1: []}


    handleAction = (values) => {
        const {client: {token}, createUserRequesting} = this.props;
        createUserRequesting(values, token)
    };


    render() {
        const {
            handleSubmit,
            city: {cities},
            typeProfile: {typeProfiles},
            typePeople: {typePeoples},
            typeProfessional :{typeProfessional,visible},
            gender: {gendersFilter},
            rol: {roles},
            filterGender,
            visibleTypeProfessional
        } = this.props;
        return (
            <div style={divStyle} className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div style={divStyle}  className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Agregar un nuevo usuario</h1>
                    </div>
                    {/*<div className="text-modal">*/}
                    {/*    <h2>Información de compra</h2>*/}
                    {/*</div>*/}
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Nombre - Razón Social: </label>
                            <Field name={'name'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Cedula - NIT: </label>
                            <Field name={'identification'} type="text" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Contraseña: </label>
                            <Field name={'password'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Teléfono: </label>
                            <Field name={'phone'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Correo: </label>
                            <Field name={'email'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Dirección: </label>
                            <Field name={'address'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Persona de Contacto: </label>
                            <Field name={'person_contact'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Teléfono de Contacto: </label>
                            <Field name={'phone_contact'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Ciudad: </label>
                            <Field component={'select'} name={'city'} className="input input-city">
                                <option value={"city"}>Seleccionar ciudad</option>
                                {cities.map((city, index) =>
                                    <option value={city.id}  key={index}>{city.nombre}</option>
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Tipo de perfil: </label>
                            <Field component={'select'} name={'type_profile'} className="input input-city">
                                <option value={'type_profile'}>Seleccionar el tipo de perfil</option>
                                {typeProfiles.map((typeProfile, index) =>
                                    <option value={typeProfile.id} key={index}>{typeProfile.nombre}</option>
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Tipo de Persona: </label>
                            <Field component={'select'} onChange={(e)=>{
                                filterGender(typePeoples,e.target.value !=='type_people' ? JSON.parse(e.target.value): '');
                                visibleTypeProfessional(e.target.value !=='type_people' ? JSON.parse(e.target.value): '');
                            }} name={'type_people'} className="input input-city">
                                <option value={'type_people'}>Seleccionar el tipo de persona</option>
                                {typePeoples.map((typePeople, index) =>
                                    <option value={JSON.stringify(typePeople)} key={index}>{typePeople.nombre}</option>
                                )}
                            </Field>
                        </div>
                        {visible &&
                        <div className="item-modal">
                            <label>Tipo de Profesional: </label>
                            <Field component={'select'} name={'type_professional'} className="input input-city">
                                <option value={'type_professional'}>Seleccionar el tipo de profesional</option>
                                {typeProfessional.map((typeProfessional, index) =>
                                    <option value={typeProfessional.id} key={index}>{typeProfessional.nombre}</option>
                                )}
                            </Field>
                        </div>
                        }
                        <div className="item-modal">
                            <label>Tipo de Género: </label>
                            <Field component={'select'} name={'type_gender'} className="input input-city">
                                <option value={'type_gender'}>Seleccionar el tipo de género</option>
                                {gendersFilter.map((gender, index) =>
                                    <option value={gender.id} key={index}>{gender.nombre}</option>
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Tipo de Rol: </label>
                            <Field component={'select'} name={'rol'} className="input input-city">
                                <option value={'rol'}>Seleccionar el tipo de rol</option>
                                {roles.map((rol, index) =>
                                    <option value={rol.id} key={index}>{rol.nombre}</option>
                                )}
                            </Field>
                        </div>
                        {/*<div className="item-modal">*/}
                        {/*    <label>Latitud: </label>*/}
                        {/*    <input type="text" className="input input-city"/>*/}
                        {/*</div>*/}
                        {/*<div className="item-modal">*/}
                        {/*    <label>Longitud: </label>*/}
                        {/*    <input type="text" className="input input-city"/>*/}
                        {/*</div>*/}

                        {/*<label>Documentos: </label>*/}
                        {/*<FilePond ref={ref => this.pond = ref}*/}
                        {/*          labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action upled">Busca aquí</span>'}*/}
                        {/*          allowMultiple={true}*/}
                        {/*          maxFiles={5}*/}
                        {/*          server="/api"*/}
                        {/*          onupdatefiles={(fileItems) => {*/}
                        {/*              // Set current file objects to this.state*/}
                        {/*              this.setState({*/}
                        {/*                  files1: fileItems.map(fileItem => fileItem.file)*/}
                        {/*              });*/}
                        {/*          }}>*/}
                        {/*    /!* Update current files  *!/*/}
                        {/*    {this.state.files1.map(file => (*/}
                        {/*        <File key={file} src={file} origin="local"/>*/}
                        {/*    ))}*/}
                        {/*</FilePond>*/}

                        <label>Fotos: </label>
                        <Field name={'photos'} maxFiles={1} component={ImagesInput}/>

                        {/*<div className="item-modal">*/}
                        {/*    <label>Especialidad: </label>*/}
                        {/*    <div className="item-modal">*/}
                        {/*        /!*<label>Categoría 1: </label>*!/*/}
                        {/*        <select name="rol" className="select-rol">*/}
                        {/*            <option value="">Especialidad 1:</option>*/}
                        {/*            <option value="">Especialidad 2:</option>*/}
                        {/*            <option value="">Especialidad 3:</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal">Crear usuario<i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const divStyle = {
    // height: '500px',
};

const mapStateToProps = state => ({
    user: state.user,
    client: state.client,
    city: state.city,
    typeProfile: state.typeProfile,
    typePeople: state.typePeople,
    gender: state.gender,
    rol: state.rol,
    typeProfessional: state.typeProfessional
});

const connected = connect(mapStateToProps, {
    createUserRequesting,
    filterGender,
    visibleTypeProfessional
})(ModalAddUser);

const formed = reduxForm({
    form: 'userModal'
})(connected);

export default formed;

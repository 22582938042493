import React from 'react';
import SearchComponent from "../search/search";
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {TableSubscription} from "./table/table-subscription";
import ModalAddSubscription from "./modals/add-subscription";
import {FilterComponent} from "../filter/filter";
import {
    showModalSubscriptionUser,
    hiddenModalSubscriptionUser,
    getSubscriptionUserRequesting,
    searchSubscriptionUserRequesting,
    searchUserCreateSubscriptionRequesting
} from "../../redux/subscription/user/actions";
import {getStatesSubscriptionRequesting, getAllSubscriptionRequesting} from "../../redux/subscription/actions";
import {getAllPaymentMethodRequesting} from "../../redux/payment-method/actions";
import {NotFound} from "../../commons/errors/notFound";
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {errorControlSubscriptionUser} from "../../redux/subscription/user/actions";

export class SubscriptionComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            getSubscriptionUserRequesting,
            getStatesSubscriptionRequesting,
            getAllPaymentMethodRequesting,
            subscriptionUser: {filter, paginate},
            client: {token}
        } = this.props;
        getAllPaymentMethodRequesting(token);
        getSubscriptionUserRequesting(paginate, filter, token);
        getStatesSubscriptionRequesting(token);
    }

    handleErrorModal = () => {
        const {subscriptionUser: {error}, errorControlSubscriptionUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlSubscriptionUser();
    };

    handleShowModal = () => {
        const {showModalSubscriptionUser} = this.props;
        showModalSubscriptionUser();
    };
    handleHiddenModal = () => {
        const {hiddenModalSubscriptionUser} = this.props;
        hiddenModalSubscriptionUser();
    };

    handleSearch = (value) => {
        const {searchSubscriptionUserRequesting, client: {token}, subscriptionUser: {search}} = this.props;
        if ((search !== value.search) && value.search !== '')
            searchSubscriptionUserRequesting(value.search, token);
    };
    handleSearchUser = (value) => {
        const {searchUserCreateSubscriptionRequesting, client: {token}, subscriptionUser: {search}} = this.props;
        if ((search !== value.searchUser) && value.searchUser !== '')
            searchUserCreateSubscriptionRequesting(value.searchUser, token);
    };
    handleFilter = (value) => {
        const {getSubscriptionUserRequesting, subscriptionUser: {filter, paginate}, client: {token}} = this.props;
        if (filter !== value)
            getSubscriptionUserRequesting(paginate, value, token)
    };
    handleGetData = (paginate) => {
        const {getSubscriptionUserRequesting, paginateSubscription, subscriptionUser: {filter, hasMore, hasLess}, client: {token}} = this.props;
        if (paginate === 1 && hasMore)
            getSubscriptionUserRequesting(paginate + paginateSubscription, filter, token);
        if (paginate === -1 && hasLess && paginateSubscription > 1)
            getSubscriptionUserRequesting(paginate + paginateSubscription, filter, token);
    };
    handleGetCsv = () => {
        const {me: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/exports/userSubscriptions?email=${user.correo}`, "_self")
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            subscription: {
                statesSubscription,
            },
            subscriptionUser: {
                subscriptions,
                isAddSubscription,
                search,
                subscriptionsSearch,
                errorSubscription
            }
        } = this.props;
        return (
            <div className="styles-container">
                {errorSubscription && this.handleErrorModal()}
                <SearchComponent
                    type={'Suscripción'}
                    placeholder={'Consultar Suscripción'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {search !== undefined && search !== '' && (
                        subscriptionsSearch.length > 0 ?
                            <TableSubscription
                                subscriptions={subscriptionsSearch}
                            /> : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'Suscripción'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        handleGetData={this.handleGetData.bind(this)}
                        states={statesSubscription}
                    />
                    {subscriptions.length > 0 ?
                        (
                            <TableSubscription
                                subscriptions={subscriptions}
                            />
                        )
                        :
                        <NotFound/>
                    }
                    {isAddSubscription && (
                        <ModalAddSubscription
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            handleSearchUser={this.handleSearchUser.bind(this)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    subscription: state.subscription,
    subscriptionUser: state.subscriptionUser,
    paginateSubscription: state.subscriptionUser.paginate,
    me: state.me,
});

const connected = connect(mapStateToProps, {
    showModalSubscriptionUser,
    hiddenModalSubscriptionUser,
    getSubscriptionUserRequesting,
    getStatesSubscriptionRequesting,
    searchSubscriptionUserRequesting,
    getAllSubscriptionRequesting,
    getAllPaymentMethodRequesting,
    searchUserCreateSubscriptionRequesting,
    errorControlSubscriptionUser
})(SubscriptionComponent);

const formed = reduxForm({
    form: 'SubscriptionComponent'
})(connected);

export default formed;

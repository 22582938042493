import React from 'react';
import SearchComponent from "../search/search";
import {TableProduct} from "./table/table-product";
import Filter3Component from "../filter/filter3";
import ModalDisablePublication from "./modals/disable-publication";
import {connect} from 'react-redux';
import {
    publicationsGetRequesting,
    publicationFocus,
    publicationDisablePutRequesting,
    publicationEnablePutRequesting,
    publicationsSearchPostRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationResetStates,
} from "../../redux/publication/actions";
import {categoriesModuleGetRequesting} from "../../redux/category/actions";
import {NotFound} from "../../commons/errors/notFound";
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";

class ProductComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            client: {token},
            publication: {moduleId, categoryId, paginate},
            publicationsGetRequesting,
        } = this.props;
        publicationsGetRequesting(token, moduleId, categoryId, paginate);
    }

    handleFocus = (object) => {
        const {publicationFocus} = this.props;
        publicationFocus(object);
    };

    handleShowModal = (modal) => {
        const {publicationShowModal} = this.props;
        publicationShowModal(modal);
    };

    handleHiddenModal = (modal) => {
        const {publicationHiddenModal} = this.props;
        publicationHiddenModal(modal);
    };

    handleDisable = (values) => {
        const {
            client: {token},
            publication: {publication},
            publicationDisablePutRequesting
        } = this.props;
        publicationDisablePutRequesting(token, publication.id, values);
    };

    handleEnable = (publicationId) => {
        const {
            client: {token},
            publicationEnablePutRequesting
        } = this.props;
        publicationEnablePutRequesting(token, publicationId);
    };

    handleErrorModal = () => {
        const {publication: {error}} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
    };

    handleDeletePhoto = (photoId) => {
        // console.log(photoId);
    };

    handleSearch = (values) => {
        const {client: {token}, publication: {stringSearch}, publicationsSearchPostRequesting} = this.props;
        if (values.search !== stringSearch) {
            publicationsSearchPostRequesting(token, values);
        }
    };

    handleFilter = (values) => {
        const {
            client: {token},
            categoriesModuleGetRequesting,
            publicationsGetRequesting,
            publication: {moduleId, categoryId, paginate}
        } = this.props;
        if (values.module !== moduleId) {
            categoriesModuleGetRequesting(token, values);
            publicationsGetRequesting(token, values.module, 'Todos', paginate);
        }
        if (values.category !== categoryId) {
            publicationsGetRequesting(token, moduleId, values.category, paginate);
        }
    };

    handleGetData = (newPaginate) => {
        const {
            client: {token},
            publicationsGetRequesting,
            publication: {moduleId, categoryId, paginate, hasMore, hasLess}
        } = this.props;
        if (newPaginate === 1 && hasMore)
            publicationsGetRequesting(token, moduleId, categoryId, paginate + newPaginate);
        if (newPaginate === -1 && hasLess && paginate > 1)
            publicationsGetRequesting(token, moduleId, categoryId, paginate + newPaginate);
    };

    handleGetCsv = () => {
        const {me: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/publicationsExport?email=${user.correo}`, "_self")
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            publication: {
                publications, publicationsSearch, stringSearch, modalDisable, error
            },
        } = this.props;
        return (
            <div className="styles-container">
                {error !== '' && this.handleErrorModal()}
                <SearchComponent
                    type={'Productos'}
                    placeholder={'Consultar en producto'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {stringSearch !== '' && (
                        publicationsSearch.length > 0
                            ? <TableProduct publications={publicationsSearch}
                                            handleFocus={this.handleFocus.bind(this)}
                                            handleShowModal={this.handleShowModal.bind(this)}
                                            handleEnable={this.handleEnable.bind(this)}
                                            handleDeletePhoto={this.handleDeletePhoto.bind(this)}
                            />
                            : <NotFound/>

                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <Filter3Component type={'producto'}
                                      handleFilter={this.handleFilter.bind(this)}
                                      handleGetData={this.handleGetData.bind(this)}
                                      handleGetCsv={this.handleGetCsv.bind(this)}
                    />
                    {publications.length > 0
                        ? (<TableProduct publications={publications}
                                         handleFocus={this.handleFocus.bind(this)}
                                         handleShowModal={this.handleShowModal.bind(this)}
                                         handleEnable={this.handleEnable.bind(this)}
                                         handleDeletePhoto={this.handleDeletePhoto.bind(this)}
                        />)
                        : <NotFound/>
                    }
                </div>
                {modalDisable && (
                    <ModalDisablePublication stop={this.stopPropagation.bind(this)}
                                             hidden={this.handleHiddenModal.bind(this)}
                                             handleDisable={this.handleDisable.bind(this)}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    publication: state.publication,
    client: state.client,
    me: state.me,
});

const connected = connect(mapStateToProps, {
    publicationsGetRequesting,
    publicationFocus,
    publicationDisablePutRequesting,
    publicationEnablePutRequesting,
    publicationsSearchPostRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationResetStates,
    categoriesModuleGetRequesting
})(ProductComponent);

export default connected;
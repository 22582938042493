import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {connect} from "react-redux";
import {createSubscriptionRequesting} from "../../../redux/subscription/actions";

class ModalAddPlan extends React.Component {

    handleAction = (values) => {
        const {client: {token}, createSubscriptionRequesting} = this.props;
        createSubscriptionRequesting(values, token);
    };

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>

                    <h1 className="arreglojulian">Agregar plan</h1>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Nombre del plan: </label>
                            <Field name={'name'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Descripción: </label>
                            <Field name={'description'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Cantidad Publicaciones: </label>
                            <Field name={'number_publication'} type="number" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Duración(Meses): </label>
                            <Field name={'duration'} type="number" className="input input-city"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Valor: </label>
                            <Field name={'price'} type="number" className="input input-city" component={'input'}/>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button onClick={this.props.hidden.bind(this)} className="Btn-modal">Cancelar<i
                                    className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal">Crear suscripcion<i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'planModal',
    enableReinitialize: true
})(ModalAddPlan);

const mapStateToProps = state => ({
    client: state.client,
});

const connected = connect(mapStateToProps, {
    createSubscriptionRequesting
})(formed);

export default connected

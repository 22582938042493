import {
    SUBCATEGORIES_GET_ERROR,
    SUBCATEGORIES_GET_REQUESTING,
    SUBCATEGORIES_GET_SUCCESS,
    SUBCATEGORIES_SEARCH_POST_ERROR,
    SUBCATEGORIES_SEARCH_POST_REQUESTING,
    SUBCATEGORIES_SEARCH_POST_SUCCESS,
    SUBCATEGORY_CREATE_POST_ERROR,
    SUBCATEGORY_CREATE_POST_REQUESTING,
    SUBCATEGORY_CREATE_POST_SUCCESS,
    SUBCATEGORY_FOCUS, SUBCATEGORY_HIDDEN_MODAL,
    SUBCATEGORY_RESET_STATES,
    SUBCATEGORY_SHOW_MODAL,
    SUBCATEGORY_STATE_DELETE_ERROR,
    SUBCATEGORY_STATE_DELETE_REQUESTING,
    SUBCATEGORY_STATE_DELETE_SUCCESS,
    SUBCATEGORY_UPDATE_PUT_ERROR,
    SUBCATEGORY_UPDATE_PUT_REQUESTING,
    SUBCATEGORY_UPDATE_PUT_SUCCESS
} from './constants';

export const subcategoriesGetRequesting = (token, filter, paginate) => ({
    type: SUBCATEGORIES_GET_REQUESTING,
    token, filter, paginate,
});

export const subcategoriesGetSuccess = (subcategories) => ({
    type: SUBCATEGORIES_GET_SUCCESS,
    subcategories,
});

export const subcategoriesGetError = (error) => ({
    type: SUBCATEGORIES_GET_ERROR,
    error,
});

export const subcategoryCreatePostRequesting = (token, values) => ({
    type: SUBCATEGORY_CREATE_POST_REQUESTING,
    token, values,
});

export const subcategoryCreatePostSuccess = (subcategory) => ({
    type: SUBCATEGORY_CREATE_POST_SUCCESS,
    subcategory,
});

export const subcategoryCreatePostError = (error) => ({
    type: SUBCATEGORY_CREATE_POST_ERROR,
    error,
});

export const subcategoryFocus = (subcategory) => ({
    type: SUBCATEGORY_FOCUS,
    subcategory,
});

export const subcategoryUpdatePutRequesting = (token, values) => ({
    type: SUBCATEGORY_UPDATE_PUT_REQUESTING,
    token, values,
});

export const subcategoryUpdatePutSuccess = (subcategory) => ({
    type: SUBCATEGORY_UPDATE_PUT_SUCCESS,
    subcategory,
});

export const subcategoryUpdatePutError = (error) => ({
    type: SUBCATEGORY_UPDATE_PUT_ERROR,
    error,
});

export const subcategoriesSearchPostRequesting = (token, search) => ({
    type: SUBCATEGORIES_SEARCH_POST_REQUESTING,
    token, search,
});

export const subcategoriesSearchPostSuccess = (subcategories) => ({
    type: SUBCATEGORIES_SEARCH_POST_SUCCESS,
    subcategories,
});

export const subcategoriesSearchPostError = (error) => ({
    type: SUBCATEGORIES_SEARCH_POST_ERROR,
    error,
});

export const subcategoryStateDeleteRequesting = (token, subcategoryId) => ({
    type: SUBCATEGORY_STATE_DELETE_REQUESTING,
    token, subcategoryId,
});

export const subcategoryStateDeleteSuccess = (subcategory) => ({
    type: SUBCATEGORY_STATE_DELETE_SUCCESS,
    subcategory,
});

export const subcategoryStateDeleteError = (error) => ({
    type: SUBCATEGORY_STATE_DELETE_ERROR,
    error,
});

export const subcategoryShowModal = (modal) => ({
    type: SUBCATEGORY_SHOW_MODAL,
    modal,
});

export const subcategoryHiddenModal = (modal) => ({
    type: SUBCATEGORY_HIDDEN_MODAL,
    modal,
});

export const subcategoryResetStates = () => ({
    type: SUBCATEGORY_RESET_STATES,
});
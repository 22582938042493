import {
    GET_GENDER_ERROR,
    GET_GENDER_REQUESTING,
    GET_GENDER_SUCCESS,
    FILTER_GENDER
} from "./constants";

export const getGenderRequesting = () => ({
    type: GET_GENDER_REQUESTING
});

export const getGenderSuccess = (genders) => ({
    type: GET_GENDER_SUCCESS,
    genders
});

export const getGenderError = (error) => ({
    type: GET_GENDER_ERROR,
    error
});

export const filterGender =(typePeoples,typePeopleFocus)=> ({
   type:FILTER_GENDER,
    typePeoples,
    typePeopleFocus
});
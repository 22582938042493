import {
    PUBLICATION_ENABLE_PUT_ERROR,
    PUBLICATION_ENABLE_PUT_REQUESTING,
    PUBLICATION_ENABLE_PUT_SUCCESS,
    PUBLICATION_DISABLE_PUT_ERROR,
    PUBLICATION_DISABLE_PUT_REQUESTING,
    PUBLICATION_DISABLE_PUT_SUCCESS,
    PUBLICATION_FOCUS,
    PUBLICATION_FOCUS_CLEAN,
    PUBLICATIONS_GET_ERROR,
    PUBLICATIONS_GET_REQUESTING,
    PUBLICATIONS_GET_SUCCESS,
    PUBLICATION_RESET_STATES,
    PUBLICATIONS_SEARCH_POST_REQUESTING,
    PUBLICATIONS_SEARCH_POST_SUCCESS, PUBLICATIONS_SEARCH_POST_ERROR, PUBLICATION_SHOW_MODAL, PUBLICATION_HIDDEN_MODAL
} from "./constants";

export const publicationsGetRequesting = (token, moduleId, categoryId, paginate) => ({
    type: PUBLICATIONS_GET_REQUESTING,
    token, moduleId, categoryId, paginate,
});

export const publicationsGetSuccess = (publications) => ({
    type: PUBLICATIONS_GET_SUCCESS,
    publications,
});

export const publicationsGetError = (error) => ({
    type: PUBLICATIONS_GET_ERROR,
    error,
});

export const publicationFocus = (publication) => ({
    type: PUBLICATION_FOCUS,
    publication,
});

export const publicationFocusClean = () => ({
    type: PUBLICATION_FOCUS_CLEAN,
});

export const publicationDisablePutRequesting = (token, publicationId, values) => ({
    type: PUBLICATION_DISABLE_PUT_REQUESTING,
    token, publicationId, values,
});

export const publicationDisablePutSuccess = (publication) => ({
    type: PUBLICATION_DISABLE_PUT_SUCCESS,
    publication,
});

export const publicationDisablePutError = (error) => ({
    type: PUBLICATION_DISABLE_PUT_ERROR,
    error,
});

export const publicationEnablePutRequesting = (token, publicationId) => ({
    type: PUBLICATION_ENABLE_PUT_REQUESTING,
    token, publicationId,
});

export const publicationEnablePutSuccess = (publication) => ({
    type: PUBLICATION_ENABLE_PUT_SUCCESS,
    publication,
});

export const publicationEnablePutError = (error) => ({
    type: PUBLICATION_ENABLE_PUT_ERROR,
    error,
});

export const publicationsSearchPostRequesting = (token, values) => ({
    type: PUBLICATIONS_SEARCH_POST_REQUESTING,
    token, values,
});

export const publicationsSearchPostSuccess = (publications) => ({
    type: PUBLICATIONS_SEARCH_POST_SUCCESS,
    publications,
});

export const publicationsSearchPostError = (error) => ({
    type: PUBLICATIONS_SEARCH_POST_ERROR,
    error,
});

export const publicationShowModal = (modal) => ({
    type: PUBLICATION_SHOW_MODAL,
    modal,
});

export const publicationHiddenModal = (modal) => ({
    type: PUBLICATION_HIDDEN_MODAL,
    modal,
});

export const publicationResetStates = () => ({
    type: PUBLICATION_RESET_STATES,
});
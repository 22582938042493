import {GET_REVIEW_ERROR, GET_REVIEW_REQUESTING, GET_REVIEW_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    reviews: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REVIEW_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                reviews: action.reviews
            };
        case GET_REVIEW_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                reviews: []
            };
        default:
            return state;
    }
};

export default reducer;
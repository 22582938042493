import React from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
);

const typeFiles = ['image/png', 'image/jpeg', 'image/jpg'];

const ImagesInput = ({input, name, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            allowMultiple={true}
            maxFiles={rest.maxFiles}
            allowFileTypeValidation={true}
            acceptedFileTypes={typeFiles}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action">Busca aquí</span>'}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        />
    )
};

export default ImagesInput;
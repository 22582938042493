import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/styles-body.css';
import './styles/Styles-login.css';
import './styles/Styles-home.css';
import './styles/Styles-header.css';
import './styles/Styles-table.css';
import './styles/Styles-container.css';
import './styles/Styles-button.css';
import './styles/Styles-modal.css';


import * as serviceWorker from './serviceWorker';
import './vendor/css/fontello.css';
import App from './App';
import {IntlProvider} from "react-intl";

ReactDOM.render(<IntlProvider locale={'es-CO'}><App/></IntlProvider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

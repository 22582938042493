import React from 'react';
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import ImagesInput from './../../file-inputs/images';
import ModuleSelectComponent from './../../module/select';
import {categoryCreatePostRequesting} from "../../../redux/category/actions";

class ModalAddCategory extends React.Component {

    handleSubmit = (values) => {
        const {client: {token}, categoryCreatePostRequesting} = this.props;
        categoryCreatePostRequesting(token, values);
    };

    render() {
        const {
            handleSubmit,
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Agregar categoria</h1>
                    <br/>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="item-modal">
                            <label>Nombre categoria: </label>
                            <Field type="text" className="input input-name" name={'name'} component={'input'}/>
                        </div>
                        <label>Foto: </label>
                        <Field name={'photos'} maxFiles={1} className={'filepond'} component={ImagesInput}/>
                        <div className="item-modal">
                            <label>Condición: </label>
                            <ModuleSelectComponent type={'add'}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" formAction={'submit'}>
                                    Crear categoria
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'CreateCategory',
    enableReinitialize: true
})(ModalAddCategory);

const mapStateToProps = state => ({
    client: state.client,
});

const connected = connect(mapStateToProps, {
    categoryCreatePostRequesting,
})(formed);

export default connected;
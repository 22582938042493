import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    publicationEnablePutError,
    publicationEnablePutSuccess,
    publicationResetStates,
    publicationsGetError,
    publicationsGetSuccess, publicationsSearchPostError,
    publicationsSearchPostSuccess,
    publicationHiddenModal, publicationDisablePutSuccess, publicationDisablePutError
} from "./actions";
import {
    PUBLICATION_DISABLE_PUT_REQUESTING,
    PUBLICATION_ENABLE_PUT_REQUESTING,
    PUBLICATIONS_GET_REQUESTING,
    PUBLICATIONS_SEARCH_POST_REQUESTING
} from "./constants";

const publicationUrl = `${process.env.REACT_APP_API_URL}/publications`;

const publicationsGetApi = (token, moduleId, categoryId, paginate) => {
    let url = `${publicationUrl}/filter?page=${paginate}`;
    let body = {
        module: moduleId,
        category: categoryId,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* publicationsGetFlow(action) {
    try {
        const {token, moduleId, categoryId, paginate} = action;
        const publications = yield call(publicationsGetApi, token, moduleId, categoryId, paginate);
        yield put(publicationsGetSuccess(publications));
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationsGetError(error));
    }
}

const publicationsSearchPostApi = (token, values) => {
    let url = `${publicationUrl}/searchAdministrator`;
    let body = {
        search: values.search,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* publicationsSearchPostFlow(action) {
    try {
        const {token, values} = action;
        const publications = yield call(publicationsSearchPostApi, token, values);
        yield put(publicationsSearchPostSuccess(publications));
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationsSearchPostError(error));
    }
}

const publicationEnablePutApi = (token, publicationId) => {
    let url = `${publicationUrl}/enable/${publicationId}`;
    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* publicationEnablePutFlow(action) {
    try {
        const {token, publicationId} = action;
        const publication = yield call(publicationEnablePutApi, token, publicationId);
        yield put(publicationResetStates());
        yield put(publicationEnablePutSuccess(publication));
        toastr.success('Publicación habilitada', `La publicación con el id ${publication.id} ha sido habilitada correctamente.`);
    } catch (error) {
        yield put(publicationEnablePutError(error));
    }
}

const publicationDisablePutApi = (token, publicationId, values) => {
    let url = `${publicationUrl}/disable/${publicationId}`;
    let body = {
        observation: values.observation,
    };
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* publicationDisablePutFlow(action) {
    try {
        const {token, publicationId, values} = action;
        const publication = yield call(publicationDisablePutApi, token, publicationId, values);
        yield put(publicationHiddenModal('disable'));
        yield put(publicationResetStates());
        yield put(publicationDisablePutSuccess(publication));
        toastr.success('Publicación deshabilitada', `La publicación con el id ${publication.id} ha sido deshabilitada correctamente.`);
    } catch (error) {
        yield put(publicationDisablePutError(error));
    }
}

function* publicationWatcher() {
    yield all([
        takeEvery(PUBLICATIONS_GET_REQUESTING, publicationsGetFlow),
        takeEvery(PUBLICATIONS_SEARCH_POST_REQUESTING, publicationsSearchPostFlow),
        takeEvery(PUBLICATION_ENABLE_PUT_REQUESTING, publicationEnablePutFlow),
        takeEvery(PUBLICATION_DISABLE_PUT_REQUESTING, publicationDisablePutFlow),
    ])
}

export default publicationWatcher;
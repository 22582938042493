import {GET_ROL_ERROR, GET_ROL_REQUESTING, GET_ROL_SUCCESS} from "./constants";

export const getRolRequesting = () => ({
    type: GET_ROL_REQUESTING
});

export const getRolSuccess = (roles) => ({
    type: GET_ROL_SUCCESS,
    roles
});

export const getRolError = (error) => ({
    type: GET_ROL_ERROR,
    error
});
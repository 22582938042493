import {
    PUBLICATION_ENABLE_PUT_ERROR,
    PUBLICATION_ENABLE_PUT_REQUESTING,
    PUBLICATION_ENABLE_PUT_SUCCESS,
    PUBLICATION_DISABLE_PUT_ERROR,
    PUBLICATION_DISABLE_PUT_REQUESTING,
    PUBLICATION_DISABLE_PUT_SUCCESS,
    PUBLICATION_FOCUS,
    PUBLICATION_FOCUS_CLEAN, PUBLICATION_RESET_STATES,
    PUBLICATIONS_GET_ERROR,
    PUBLICATIONS_GET_REQUESTING,
    PUBLICATIONS_GET_SUCCESS,
    PUBLICATIONS_SEARCH_POST_ERROR,
    PUBLICATIONS_SEARCH_POST_REQUESTING,
    PUBLICATIONS_SEARCH_POST_SUCCESS, PUBLICATION_SHOW_MODAL, PUBLICATION_HIDDEN_MODAL
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    moduleId: 'Todos',
    categoryId: 'Todos',
    paginate: 1,
    hasMore: true,
    hasLess: false,
    publication: {},
    stringSearch: '',
    publications: [],
    publicationsSearch: [],
    modalDisable: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PUBLICATIONS_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                moduleId: action.moduleId,
                categoryId: action.categoryId,
                paginate: action.paginate,
            };
        case PUBLICATIONS_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                hasLess: state.paginate !== 1,
                hasMore: action.publications.length > 0,
                publications: action.publications,
            };
        case PUBLICATIONS_GET_ERROR:
            console.log(action.error);
            return {
                ...state,
                requesting: false,
                error: action.error,
                hasMore: false,
                hasLess: state.paginate !== 1,
            };
        case PUBLICATION_FOCUS:
            return {
                ...state,
                publication: action.publication,
            };
        case PUBLICATION_FOCUS_CLEAN:
            return {
                ...state,
                publication: {},
            };
        case PUBLICATION_DISABLE_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case PUBLICATION_DISABLE_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publications: state.publications.length > 0 ? state.publications.map((publication) => publication.id === action.publication.id ? action.publication : publication) : state.publications,
                publicationsSearch: state.publicationsSearch.length > 0 ? state.publicationsSearch.map((publication) => publication.id === action.publication.id ? action.publication : publication) : state.publicationsSearch,
            };
        case PUBLICATION_DISABLE_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_ENABLE_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case PUBLICATION_ENABLE_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publications: state.publications.length > 0 ? state.publications.map((publication) => publication.id === action.publication.id ? action.publication : publication) : state.publications,
                publicationsSearch: state.publicationsSearch.length > 0 ? state.publicationsSearch.map((publication) => publication.id === action.publication.id ? action.publication : publication) : state.publicationsSearch,
            };
        case PUBLICATION_ENABLE_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATIONS_SEARCH_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                stringSearch: action.values.search,
                error: '',
            };
        case PUBLICATIONS_SEARCH_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publicationsSearch: action.publications,
            };
        case PUBLICATIONS_SEARCH_POST_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_SHOW_MODAL:
            return {
                ...state,
                modalDisable: action.modal === 'disable',
            };
        case PUBLICATION_HIDDEN_MODAL:
            return {
                ...state,
                error: '',
                modalDisable: action.modal === 'disable' && false,
            };
        case PUBLICATION_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
            };
        default:
            return state;
    }
};

export default reducer;

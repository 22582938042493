import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    subcategoryResetStates,
    subcategoriesGetSuccess,
    subcategoriesGetError,
    subcategoryHiddenModal,
    subcategoryCreatePostSuccess,
    subcategoryCreatePostError,
    subcategoryUpdatePutSuccess,
    subcategoryUpdatePutError,
    subcategoryStateDeleteSuccess,
    subcategoryStateDeleteError,
    subcategoriesSearchPostSuccess, subcategoriesSearchPostError
} from "./actions";
import {
    SUBCATEGORIES_GET_REQUESTING,
    SUBCATEGORIES_SEARCH_POST_REQUESTING,
    SUBCATEGORY_CREATE_POST_REQUESTING, SUBCATEGORY_STATE_DELETE_REQUESTING, SUBCATEGORY_UPDATE_PUT_REQUESTING
} from "./constants";

const subcategoryUrl = `${process.env.REACT_APP_API_URL}/subcategories`;

const subcategoriesGetApi = (token, filter, paginate) => {
    let url = `${subcategoryUrl}/filter/${filter}?page=${paginate}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* subcategoriesGetFlow(action) {
    try {
        const {token, filter, paginate} = action;
        const subcategories = yield call(subcategoriesGetApi, token, filter, paginate);
        yield put(subcategoriesGetSuccess(subcategories));
        yield put(subcategoryResetStates());
    } catch (error) {
        yield put(subcategoriesGetError(error));
    }
}

const subcategoryCreatePostApi = (token, values) => {
    let body = new FormData();
    body.append('name', values.name || '');
    body.append('category', values.category || '');
    if (values.hasOwnProperty('photos') && values.photos.length > 0) {
        values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', values.photos.length || '');
    }
    return fetch(subcategoryUrl, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: body,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* subcategoryCreatePostFlow(action) {
    try {
        const {token, values} = action;
        const subcategory = yield call(subcategoryCreatePostApi, token, values);
        yield put(subcategoryCreatePostSuccess(subcategory));
        toastr.success('Subcategoria creada', `Se ha creado la subcategoria ${subcategory.nombre} correctamente.`);
        yield put(subcategoryHiddenModal('subcategoria'));
        yield put(subcategoryResetStates());
    } catch (error) {
        yield put(subcategoryCreatePostError(error));
    }
}

const subcategoryUpdatePutApi = (token, values) => {
    let body = new FormData();
    body.append('_method', 'put');
    body.append('name', values.nombre || '');
    body.append('category', values.category || values.categoria.id);
    if (values.hasOwnProperty('photos') && values.photos.length > 0) {
        values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', values.photos.length || '');
    }
    return fetch(`${subcategoryUrl}/${values.id}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: body,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* subcategoryUpdatePutFlow(action) {
    try {
        const {token, values} = action;
        const subcategory = yield call(subcategoryUpdatePutApi, token, values);
        yield put(subcategoryHiddenModal('edit'));
        yield put(subcategoryUpdatePutSuccess(subcategory));
        toastr.success('Subcategoria actualizada', `Se ha actulizado la categoria ${subcategory.nombre} correctamente.`);
        yield put(subcategoryResetStates());
    } catch (error) {
        yield put(subcategoryUpdatePutError(error));
    }
}

const subcategoryStateDeleteApi = (token, subcategoryId) => {
    return fetch(`${subcategoryUrl}/${subcategoryId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* subcategoryStateDeleteFlow(action) {
    try {
        const {token, subcategoryId} = action;
        const subcategory = yield call(subcategoryStateDeleteApi, token, subcategoryId);
        yield put(subcategoryStateDeleteSuccess(subcategory));
        yield put(subcategoryResetStates());
        toastr.success('Subcategoria actualizada', `Se ha ${subcategory.estado.nombre} la categoria ${subcategory.nombre} correctamente.`);
    } catch (error) {
        yield put(subcategoryStateDeleteError(error));
    }
}

const subcategoriesSearchPostApi = (token, search) => {
    let body = {
        search: search,
    };
    return fetch(`${subcategoryUrl}/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* subcategoriesSearchPostFlow(action) {
    try {
        const {token, search} = action;
        const subcategories = yield call(subcategoriesSearchPostApi, token, search);
        yield put(subcategoriesSearchPostSuccess(subcategories));
        yield put(subcategoryResetStates());
    } catch (error) {
        yield put(subcategoriesSearchPostError(error));
    }
}

function* subcategoryWatcher() {
    yield all([
        takeEvery(SUBCATEGORIES_GET_REQUESTING, subcategoriesGetFlow),
        takeEvery(SUBCATEGORY_CREATE_POST_REQUESTING, subcategoryCreatePostFlow),
        takeEvery(SUBCATEGORY_UPDATE_PUT_REQUESTING, subcategoryUpdatePutFlow),
        takeEvery(SUBCATEGORY_STATE_DELETE_REQUESTING, subcategoryStateDeleteFlow),
        takeEvery(SUBCATEGORIES_SEARCH_POST_REQUESTING, subcategoriesSearchPostFlow),
    ])
}

export default subcategoryWatcher;
import {
    GET_SUBSCRIPTION_USER_ERROR,
    GET_SUBSCRIPTION_USER_REQUESTING,
    GET_SUBSCRIPTION_USER_SUCCESS,
    CHANGE_STATE_SUBSCRIPTION_USER_ERROR,
    CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING,
    CHANGE_STATE_SUBSCRIPTION_USER_SUCCESS,
    CREATE_SUBSCRIPTION_USER_ERROR,
    CREATE_SUBSCRIPTION_USER_REQUESTING,
    CREATE_SUBSCRIPTION_USER_SUCCESS,
    SEARCH_SUBSCRIPTION_USER_ERROR,
    SEARCH_SUBSCRIPTION_USER_REQUESTING,
    SEARCH_SUBSCRIPTION_USER_SUCCESS,
    SHOW_MODAL_SUBSCRIPTION_USER,
    HIDDEN_MODAL_SUBSCRIPTION_USER,
    SEARCH_USER_CREATE_SUBSCRIPTION_ERROR,
    SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING,
    SEARCH_USER_CREATE_SUBSCRIPTION_SUCCESS,
    ERROR_SUBSCRIPTION_USER
} from './constants';

const initialState = {
    requesting: false,
    success: false,
    error: '',
    hasMore: true,
    hasLess: false,
    search: '',
    searchUser: '',
    paginate: 1,
    subscriptionId:0,
    filter: 'Todos',
    userSubscription:{},
    subscriptions: [],
    subscriptionsSearch: [],
    isAddSubscription: false,
    errorSubscription :false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_USER_REQUESTING:
            return {
                ...state,
                paginate: action.paginate,
                filter: action.filter,
                error: '',
                success: false,
                requesting: false
            };
        case GET_SUBSCRIPTION_USER_SUCCESS:
            return {
                ...state,
                subscriptions: action.subscriptions,
                hasLess: state.paginate + 1 > 1,
                hasMore: true,
                error: '',
                success: true,
                requesting: false
            };
        case GET_SUBSCRIPTION_USER_ERROR:
            return {
                ...state,
                error: action.error,
                hasMore: false,
                success: false,
                requesting: false,
                subscriptions: []
            };
        case SEARCH_SUBSCRIPTION_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                search: action.search
            };
        case SEARCH_SUBSCRIPTION_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                subscriptionsSearch: action.subscriptions
            };
        case SEARCH_SUBSCRIPTION_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                subscriptionsSearch: []
            };
        case CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                subscriptionId: action.subscriptionId
            };
        case CHANGE_STATE_SUBSCRIPTION_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                subscriptions: state.subscriptions.map(subscription => (
                    subscription.id === action.subscription.id ? action.subscription : subscription
                )),
                subscriptionsSearch: state.subscriptionsSearch.length > 0 && state.subscriptionsSearch.map(subscription => (
                    subscription.id === action.subscription.id ? action.subscription : subscription
                ))
            };
        case CHANGE_STATE_SUBSCRIPTION_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        case CREATE_SUBSCRIPTION_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case CREATE_SUBSCRIPTION_USER_SUCCESS:
            return {
                ...state,
                subscriptions: [action.subscription, ...state.subscriptions],
                requesting: false,
                success: true,
                error: ''
            };
        case CREATE_SUBSCRIPTION_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };

         case ERROR_SUBSCRIPTION_USER:
             return {
                 ...state,
                errorSubscription: !state.errorSubscription
             };
        case SHOW_MODAL_SUBSCRIPTION_USER :
            return {
                ...state,
                isAddSubscription: true,
            };
        case HIDDEN_MODAL_SUBSCRIPTION_USER :
            return {
                ...state,
                isAddSubscription: false
            };
            case SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING:
                return {
                    ...state,
                    requesting: true,
                    success: false,
                    error: '',
                    searchUser: action.search
                };
            case SEARCH_USER_CREATE_SUBSCRIPTION_SUCCESS:
                return {
                    ...state,
                    requesting: false,
                    success: true,
                    error: '',
                    userSubscription: action.user
                };
            case SEARCH_USER_CREATE_SUBSCRIPTION_ERROR:
                return {
                    ...state,
                    requesting: false,
                    success: false,
                    error: action.error,
                    userSubscription: []
                };    
        default :
            return state;
    }
};

export default reducer;

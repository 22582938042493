//Suscripciones

export const GET_ALL_SUBSCRIPTION_REQUESTING = 'GET_ALL_SUBSCRIPTION_REQUESTING';
export const GET_ALL_SUBSCRIPTION_SUCCESS = 'GET_ALL_SUBSCRIPTION_SUCCESS';
export const GET_ALL_SUBSCRIPTION_ERROR = 'GET_ALL_SUBSCRIPTION_ERROR';

export const GET_SUBSCRIPTION_REQUESTING = 'GET_SUBSCRIPTION_REQUESTING';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';

export const CHANGE_STATE_SUBSCRIPTION_REQUESTING ='CHANGE_STATE_SUBSCRIPTION_REQUESTING';
export const CHANGE_STATE_SUBSCRIPTION_SUCCESS ='CHANGE_STATE_SUBSCRIPTION_SUCCESS';
export const CHANGE_STATE_SUBSCRIPTION_ERROR ='CHANGE_STATE_SUBSCRIPTION_ERROR';

export const GET_STATES_SUBSCRIPTION_REQUESTING ='GET_STATE_SUBSCRIPTION_REQUESTING';
export const GET_STATES_SUBSCRIPTION_SUCCESS ='GET_STATE_SUBSCRIPTION_SUCCESS';
export const GET_STATES_SUBSCRIPTION_ERROR ='GET_STATE_SUBSCRIPTION_ERROR';

export const CREATE_SUBSCRIPTION_REQUESTING ='CREATE_SUBSCRIPTION_REQUESTING';
export const CREATE_SUBSCRIPTION_SUCCESS ='CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR ='CREATE_SUBSCRIPTION_ERROR';

export const UPDATE_SUBSCRIPTION_REQUESTING ='UPDATE_SUBSCRIPTION_REQUESTING';
export const UPDATE_SUBSCRIPTION_SUCCESS ='UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR ='UPDATE_SUBSCRIPTION_ERROR';

export const SEARCH_SUBSCRIPTION_REQUESTING='SEARCH_SUBSCRIPTION_REQUESTING';
export const SEARCH_SUBSCRIPTION_SUCCESS='SEARCH_SUBSCRIPTION_SUCCESS';
export const SEARCH_SUBSCRIPTION_ERROR='SEARCH_SUBSCRIPTION_ERROR';

export const SET_FOCUS_SUBSCRIPTION = 'SET_FOCUS_SUBSCRIPTION';

export const ERROR_SUBSCRIPTION='ERROR_SUBSCRIPTION';

export const SHOW_MODAL_EDIT_SUBSCRIPTION ='SHOW_MODAL_EDIT_SUBSCRIPTION';
export const HIDDEN_MODAL_EDIT_SUBSCRIPTION ='HIDDEN_MODAL_EDIT_SUBSCRIPTION';


import {
    SHOW_MODAL_CONFIG,
    HIDDEN_MODAL_CONFIG
} from './constants';

export const showModalConfig = (modal)=>({
    type: SHOW_MODAL_CONFIG,
    modal
});
export const hiddenModalConfig = ()=>({
    type: HIDDEN_MODAL_CONFIG
});
import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {moduleResetStates, modulesGetError, modulesGetSuccess} from "./actions";
import {MODULES_GET_REQUESTING} from "./constants";

const moduleUrl = `${process.env.REACT_APP_API_URL}/modules`;

const modulesGetApi = (token) => {
    return fetch(moduleUrl, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* modulesGetFlow(action) {
    try {
        const {token} = action;
        const modules = yield call(modulesGetApi, token);
        yield put(modulesGetSuccess(modules));
        yield put(moduleResetStates());
    } catch (error) {
        yield put(modulesGetError(error));
    }
}

function* moduleWatcher() {
    yield all([
        takeEvery(MODULES_GET_REQUESTING, modulesGetFlow),
    ])
}

export default moduleWatcher;
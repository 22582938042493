import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    GET_SUBSCRIPTION_USER_REQUESTING,
    SEARCH_SUBSCRIPTION_USER_REQUESTING,
    CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING,
    CREATE_SUBSCRIPTION_USER_REQUESTING,
    SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING
} from './constants';

import {
    getSubscriptionUserSuccess,
    getSubscriptionUserError,
    searchSubscriptionUserSuccess,
    searchSubscriptionUserError,
    changeStateSubscriptionUserError,
    changeStateSubscriptionUserSuccess,
    createSubscriptionUserError,
    createSubscriptionUserSuccess,
    searchUserCreateSubscriptionError,
    searchUserCreateSubscriptionSuccess,
    hiddenModalSubscriptionUser,
    errorControlSubscriptionUser
} from './actions';

import {getAllSubscriptionRequesting} from "../actions";

const subscriptionsUserURL = `${process.env.REACT_APP_API_URL}/userSubscriptions`;
const searchSubscriptionsUserURL = `${process.env.REACT_APP_API_URL}/searches/userSubscriptions`;
const searchUserSubscriptionURL = `${process.env.REACT_APP_API_URL}/users/searchSubscription`;
const changeStateSubscriptionsUserURL = `${process.env.REACT_APP_API_URL}/states/userSubscription/`;

 //Crear suscripcion
 const createSubscriptionUserApi = (values, token) => {

    let body = new FormData();
     if (values.id !== undefined)
        body.append('user_id', values.id);
     if (values.payment !== undefined)
         body.append('payment_id',values.payment);
     if (values.subscription !== undefined)
         body.append('subscription_id', values.subscription);
      return fetch(subscriptionsUserURL, {
         method: 'POST',
         headers: {
             Authorization: 'Bearer ' + token
         },
         body: body
     })
         .then(handleApiErrors)
         .then(response => response.json())
         .then(json => {
             if (json.code === 422)
                 throw json.data;
             if (json.code === 400)
                 throw json.data;
             if (json.code === 200)
                 return json.data.data;
         }).catch((error) => {
             throw error;
         })
 };

 function* createSubscriptionUserFlow(action) {
     try {
         const {subscription, token} = action;
         const subscriptionCreated = yield call(createSubscriptionUserApi, subscription, token);
         yield put(createSubscriptionUserSuccess(subscriptionCreated));
         yield put(hiddenModalSubscriptionUser());
         toastr.success('Subscripcion creada', 'Subscripcion creada, ya puede ver las subscripciones');
     } catch (error) {
         yield put(createSubscriptionUserError(error));
         yield put(errorControlSubscriptionUser());
     }
 }


//Cambiar estado suscripciones
const changeStateSubscriptionUserApi = (subscriptionId, token) => {

    return fetch(changeStateSubscriptionsUserURL + subscriptionId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* changeStateSubscriptionUserFlow(action) {
    try {
        const {subscriptionId, token} = action;
        const stateSubscription = yield call(changeStateSubscriptionUserApi, subscriptionId, token);
        yield put(changeStateSubscriptionUserSuccess(stateSubscription));
    } catch (error) {
        yield put(changeStateSubscriptionUserError(error));
    }
}

//Buscar suscripciones
const searchSubscriptionUserAPI = (search, token) => {
    let body = {
        search: search
    };

    return fetch(searchSubscriptionsUserURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* searchSubscriptionUserFlow(action) {
    try {
        const {search, token} = action;
        const subscription = yield call(searchSubscriptionUserAPI, search, token);
        yield put(searchSubscriptionUserSuccess(subscription));
    } catch (error) {
        yield put(searchSubscriptionUserError(error));
    }
}

//Obtener suscripciones
const getSubscriptionsUserApi = (paginate, filter, token) => {

    const url = `${subscriptionsUserURL}/filter/${filter}?page=${paginate}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getSubscriptionsUserFLow(action) {
    try {
        const {paginate, filter, token} = action;
        const getSubscriptions = yield call(getSubscriptionsUserApi, paginate, filter, token);
        yield put(getSubscriptionUserSuccess(getSubscriptions));
    } catch (error) {
        yield put(getSubscriptionUserError(error));
    }
}
//Buscar usuario al crear suscripcion
const searchUserCreateSubscriptionAPI = (search, token) => {
    let body = {
        search: search
    };

    return fetch(searchUserSubscriptionURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* searchUserCreateSubscriptionFlow(action) {
    try {
        const {search, token} = action;
        const user = yield call(searchUserCreateSubscriptionAPI, search, token);
        yield put(searchUserCreateSubscriptionSuccess(user));
        yield put(getAllSubscriptionRequesting(token,user.id));
    } catch (error) {
        yield put(searchUserCreateSubscriptionError(error));
    }
}

function* usersWatcher() {
    yield all([
        takeEvery(GET_SUBSCRIPTION_USER_REQUESTING, getSubscriptionsUserFLow),
        takeEvery(SEARCH_SUBSCRIPTION_USER_REQUESTING, searchSubscriptionUserFlow),
        takeEvery(CHANGE_STATE_SUBSCRIPTION_USER_REQUESTING, changeStateSubscriptionUserFlow),
        takeEvery(SEARCH_USER_CREATE_SUBSCRIPTION_REQUESTING,searchUserCreateSubscriptionFlow),
        takeEvery(CREATE_SUBSCRIPTION_USER_REQUESTING,createSubscriptionUserFlow),
    ])
}
export default usersWatcher
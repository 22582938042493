import React from 'react';
import {connect} from "react-redux";
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, client, ...rest}) => {

    return <Route {...rest}
                  render={props => client.isLogged ? (<Component {...props}/>) : (
                      <Redirect to={{pathname: '/', state: {from: props.location}}}
                      />)}
    />
};

const mapStateToProps = state => ({
    client: state.client
});

const connected = connect(mapStateToProps, {})(PrivateRoute);

export default connected;


import {
    GET_TYPE_PROFESSIONAL_ERROR,
    GET_TYPE_PROFESSIONAL_REQUESTING,
    GET_TYPE_PROFESSIONAL_SUCCESS,
    VISIBLE_TYPE_PROFESSIONAL
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    visible: false,
    typeProfessional: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TYPE_PROFESSIONAL_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_TYPE_PROFESSIONAL_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                typeProfessional: action.typeProfessional
            };
        case GET_TYPE_PROFESSIONAL_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                typeProfessional:[]
            };
        case VISIBLE_TYPE_PROFESSIONAL:
            return {
              ...state,
              visible:action.typePeopleFocus.nombre === 'Profesional'
            };
        default:
            return state;
    }
};

export default reducer;
import React from 'react';
import SearchComponent from "../search/search";
import {TableUser} from "./table/table-user";
import {FilterComponent} from "../filter/filter";
import ModalAddUser from "../2-User/modals/add-user";
import {reduxForm} from "redux-form/lib/immutable";
import {connect} from "react-redux";
import {
    getUserRequesting,
    getStateUserRequesting,
    changeStateUserRequesting,
    searchUserRequesting,
    hiddenModalUser,
    showModalUser,
    showEditModalUser,
    hiddenEditModalUser,
    errorControlUser,
    setFocusUser,
    showReviewModalUser,
    hiddenReviewModalUser
} from "../../redux/user/actions";
import {NotFound} from "../../commons/errors/notFound";
import {getCityRequesting} from "../../redux/city/actions";
import {getTypeProfileRequesting} from "../../redux/typeProfile/actions";
import {getTypePeopleRequesting} from "../../redux/typePeople/actions";
import {getGenderRequesting} from "../../redux/gender/actions";
import {getRolRequesting} from "../../redux/rol/actions";
import {getTypeProfessionalRequesting} from "../../redux/typeProfessional/actions";
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";

class UserComponent
    extends React.Component {

    constructor(props) {
        super(props);
        const {
            getUserRequesting,
            getStateUserRequesting,
            getCityRequesting,
            getTypeProfileRequesting,
            getTypePeopleRequesting,
            getGenderRequesting,
            getRolRequesting,
            getTypeProfessionalRequesting,
            user: {cursor, filter},
            client: {token}
        } = this.props;
        getUserRequesting(cursor, filter, token);
        getStateUserRequesting(token);
        getCityRequesting();
        getTypeProfileRequesting();
        getTypePeopleRequesting();
        getGenderRequesting();
        getRolRequesting();
        getTypeProfessionalRequesting();
    }

    state = {
        isAddUser: false,
    };
    handleShowModal = () => {
        const {showModalUser} = this.props;
        showModalUser();
    };
    handleHiddenModal = () => {
        const {hiddenModalUser} = this.props;
        hiddenModalUser();
    };
    handleShowEditModal = (user) => {
        const {showEditModalUser, setFocusUser} = this.props;
        showEditModalUser();
        setFocusUser(user.id, user);

    };
    handleHiddenEditModal = () => {
        const {hiddenEditModalUser} = this.props;
        hiddenEditModalUser();
    };
    handleShowReviewModal = (user) => {
        const {showReviewModalUser, setFocusUser} = this.props;
        showReviewModalUser();
        setFocusUser(user.id, user);
    };
    handleHiddenReviewModal = () => {
        const {hiddenReviewModalUser} = this.props;
        hiddenReviewModalUser();
    };

    handleGetData = (cursor) => {
        const {
            getUserRequesting,
            client: {token},
            user
        } = this.props;
        if (cursor === 1 && user.hasMore)
            getUserRequesting(user.cursor + cursor, user.filter, token);
        if (cursor === -1 && user.hasLess && user.cursor > 1)
            getUserRequesting(user.cursor + cursor, user.filter, token);
    };
    handleGetCsv = () => {
        const {me: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/users/export?email=${user.correo}`, "_self")
    };

    handleFilter = (value) => {
        const {
            getUserRequesting,
            client: {token},
            user: {cursor, filter}
        } = this.props;
        if (filter !== value)
            getUserRequesting(cursor, value, token);
    };
    handleChangeState = (id) => {
        const {
            changeStateUserRequesting,
            client: {token}
        } = this.props;
        changeStateUserRequesting(id, token);
    };
    handleSearch = (value) => {
        const {
            searchUserRequesting,
            client: {token},
            user
        } = this.props;
        if (user && (user.search !== value.search) && value.search !== '')
            searchUserRequesting(value.search, token);
    };
    handleErrorModal = () => {
        const {user: {error}, errorControlUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlUser();
    };
    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {user: {users, statesUser, search, user, userModal, userEditModal, errorUser, userReviewModal}} = this.props;
        return (
            <div className="styles-container">
                {errorUser && this.handleErrorModal()}
                {userEditModal}
                <SearchComponent
                    type={'Usuarios'}
                    placeholder={'Consultar usuarios'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {search !== undefined && search !== '' && (
                        user.length > 0 ? <TableUser
                            visibleEditModal={userEditModal}
                            showEditModal={this.handleShowEditModal.bind(this)}
                            handleChangeState={this.handleChangeState.bind(this)}
                            users={user}
                        /> : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={' usuario'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        handleGetData={this.handleGetData.bind(this)}
                        states={statesUser}
                        handleGetCsv={this.handleGetCsv}
                    />
                    {users.length > 0 ? (
                        <TableUser
                            users={users}
                            visibleEditModal={userEditModal}
                            visibleReviewModal={userReviewModal}
                            showEditModal={this.handleShowEditModal.bind(this)}
                            hiddenEditModal={this.handleHiddenEditModal.bind(this)}
                            showReviewModal={this.handleShowReviewModal.bind(this)}
                            hiddenReviewModal={this.handleHiddenReviewModal.bind(this)}
                            handleChangeState={this.handleChangeState.bind(this)}
                        />
                    ) : <NotFound/>}
                    {userModal && (
                        <ModalAddUser
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    me: state.me,
});

const connected = connect(mapStateToProps, {
    getUserRequesting,
    getStateUserRequesting,
    changeStateUserRequesting,
    searchUserRequesting,
    getCityRequesting,
    getTypeProfileRequesting,
    getTypePeopleRequesting,
    getGenderRequesting,
    getRolRequesting,
    getTypeProfessionalRequesting,
    hiddenModalUser,
    showModalUser,
    errorControlUser,
    showEditModalUser,
    hiddenEditModalUser,
    setFocusUser,
    showReviewModalUser,
    hiddenReviewModalUser,
})(UserComponent);

const formed = reduxForm({
    form: 'ClientComponent'
})(connected);

export default formed;

export const GET_USER_REQUESTING = 'GET_USER_REQUESTING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_STATE_USER_REQUESTING = 'GET_STATE_USER_REQUESTING';
export const GET_STATE_USER_SUCCESS = 'GET_STATE_USER_SUCCESS';
export const GET_STATE_USER_ERROR = 'GET_STATE_USER_ERROR';

export const CHANGE_STATE_USER_REQUESTING = 'CHANGE_STATE_USER_REQUESTING';
export const CHANGE_STATE_USER_SUCCESS = 'CHANGE_STATE_USER_SUCCESS';
export const CHANGE_STATE_USER_ERROR = 'CHANGE_STATE_USER_ERROR';

export const SEARCH_USER_REQUESTING = 'SEARCH_USER_REQUESTING';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR';

export const CREATE_USER_REQUESTING = 'CREATE_USER_REQUESTING';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const SET_FOCUS_USER = 'SET_FOCUS_USER';

export const UPDATE_USER_REQUESTING = 'UPDATE_USER_REQUESTING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_ADMIN_REQUESTING = 'UPDATE_ADMIN_REQUESTING';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';

export const SHOW_MODAL_USER ='SHOW_MODAL_USER';
export const HIDDEN_MODAL_USER ='HIDDEN_MODAL_USER ';

export const SHOW_MODAL_EDIT_USER ='SHOW_MODAL_EDIT_USER';
export const HIDDEN_MODAL_EDIT_USER ='HIDDEN_MODAL_EDIT_USER ';

export const SHOW_MODAL_REVIEW_USER = 'SHOW_MODAL_REVIEW_USER';
export const HIDDEN_MODAL_REVIEW_USER = 'HIDDEN_MODAL_REVIEW_USER';

export const ERROR_USER='ERROR_USER';
export const ERROR_ADMiN='ERROR_ADMIN';




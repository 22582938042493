import React from 'react';
import SearchComponent from "../../search/search";
import {TableSubCategories} from "./../table/table-Subcategories";
import {FilterComponent} from "../../filter/filter";
import ModalAddSubCategory from "./../modals/add-subcategory";
import ModalEditSubCategory from "./../modals/edit-subcategory";
import {NotFound} from "../../../commons/errors/notFound";
import {connect} from "react-redux";
import {
    getStatesSubscriptionRequesting,
} from "../../../redux/subscription/actions";
import {
    subcategoriesGetRequesting,
    subcategoryShowModal,
    subcategoryHiddenModal,
    subcategoryStateDeleteRequesting,
    subcategoryFocus,
    subcategoriesSearchPostRequesting,
} from "../../../redux/subcategory/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {reduxForm} from "redux-form";
import {toastr} from "react-redux-toastr";

export class ConfigurationSubcategoryComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            getStatesSubscriptionRequesting,
            client: {token},
            subcategory,
            subcategoriesGetRequesting
        } = this.props;
        getStatesSubscriptionRequesting(token);
        subcategoriesGetRequesting(token, subcategory.filter, subcategory.paginate);
    }

    handleFocusSubcategory = (subcategory) => {
        const {subcategoryFocus} = this.props;
        subcategoryFocus(subcategory);
    };

    handleChangeStateSubcategory = (subcategoryId) => {
        const {client: {token}, subcategoryStateDeleteRequesting} = this.props;
        subcategoryStateDeleteRequesting(token, subcategoryId);
    };

    handleShowModalSubcategory = (modal) => {
        const {subcategoryShowModal} = this.props;
        subcategoryShowModal(modal);
    };

    handleHiddenModalSubcategory = (modal) => {
        const {subcategoryHiddenModal} = this.props;
        subcategoryHiddenModal(modal);
    };

    handleSearchSubcategory = (values) => {
        const {client: {token}, subcategory: {stringSearchSubcategory}, subcategoriesSearchPostRequesting} = this.props;
        if (stringSearchSubcategory !== values.search)
            subcategoriesSearchPostRequesting(token, values.search);
    };

    handleErrorSubcategory = () => {
        const {subcategory: {errorSubcategory}} = this.props;

        let toastOptions = {
            component: (
                <ErrorComponent message={errorSubcategory}/>
            )
        };
        toastr.error('Error', toastOptions);
    };

    handleFilterSubcategory = (values) => {
        const {client: {token}, subcategory: {filter}, subcategoriesGetRequesting} = this.props;
        if (values !== filter)
            subcategoriesGetRequesting(token, values, 1);
    };

    handleGetCsvSubcategory = () => {
        const {me: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/subcategoriesExport?email=${user.correo}`, "_self")
    };

    handleGetDataSubcategory = (newPaginate) => {
        const {
            client: {token},
            subcategoriesGetRequesting,
            subcategory: {filter, paginate, hasMore, hasLess}
        } = this.props;
        if (newPaginate === 1 && hasMore)
            subcategoriesGetRequesting(token, filter, paginate + newPaginate);
        if (newPaginate === -1 && hasLess && paginate > 1)
            subcategoriesGetRequesting(token, filter, paginate + newPaginate);
    };

    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            subscription: {
                statesSubscription,
            },
            subcategory: {
                subcategories,
                subcategoriesSearch,
                stringSearchSubcategory,
                errorSubcategory,
                modalEditSubcategory,
                modalCreateSubcategory,
            }
        } = this.props;
        return (
            <div className="styles-container">
                {errorSubcategory !== '' && this.handleErrorSubcategory()}

                <SearchComponent
                    type={'Configuración Subcategorias'}
                    placeholder={'Consultar categoria'}
                    handleSearch={this.handleSearchSubcategory.bind(this)}
                >
                    {stringSearchSubcategory !== '' && (
                        subcategoriesSearch.length > 0
                            ? <TableSubCategories subcategories={subcategoriesSearch}
                                                  handleFocus={this.handleFocusSubcategory.bind(this)}
                                                  handleChangeState={this.handleChangeStateSubcategory.bind(this)}
                                                  handleShowModal={this.handleShowModalSubcategory.bind(this)}
                            />
                            : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'subcategoria'}
                        handleShowModal={this.handleShowModalSubcategory.bind(this)}
                        handleFilter={this.handleFilterSubcategory.bind(this)}
                        handleGetCsv={this.handleGetCsvSubcategory.bind(this)}
                        handleGetData={this.handleGetDataSubcategory.bind(this)}
                        states={statesSubscription}
                    />
                    {subcategories.length > 0
                        ? <TableSubCategories subcategories={subcategories}
                                              handleFocus={this.handleFocusSubcategory.bind(this)}
                                              handleChangeState={this.handleChangeStateSubcategory.bind(this)}
                                              handleShowModal={this.handleShowModalSubcategory.bind(this)}
                        />
                        : <NotFound/>
                    }
                    {modalCreateSubcategory && (
                        <ModalAddSubCategory
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModalSubcategory.bind(this)}/>
                    )}
                    {modalEditSubcategory && (
                        <ModalEditSubCategory
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModalSubcategory.bind(this)}/>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    subscription: state.subscription,
    client: state.client,
    paginateSubscription: state.subscription.paginate,
    config: state.config,
    category: state.category,
    subcategory: state.subcategory,
    me: state.me,
});
const connected = connect(mapStateToProps, {
    getStatesSubscriptionRequesting,
    subcategoriesGetRequesting,
    subcategoryShowModal,
    subcategoryHiddenModal,
    subcategoryStateDeleteRequesting,
    subcategoryFocus,
    subcategoriesSearchPostRequesting,
})(ConfigurationSubcategoryComponent);

const formed = reduxForm({
    form: 'ConfigurationComponent'
})(connected);

export default formed;
import {
    GET_SUBSCRIPTION_REQUESTING,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    CHANGE_STATE_SUBSCRIPTION_ERROR,
    CHANGE_STATE_SUBSCRIPTION_REQUESTING,
    CHANGE_STATE_SUBSCRIPTION_SUCCESS,
    GET_STATES_SUBSCRIPTION_ERROR,
    GET_STATES_SUBSCRIPTION_REQUESTING,
    GET_STATES_SUBSCRIPTION_SUCCESS,
    CREATE_SUBSCRIPTION_ERROR,
    CREATE_SUBSCRIPTION_REQUESTING,
    CREATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION_REQUESTING,
    UPDATE_SUBSCRIPTION_SUCCESS,
    SEARCH_SUBSCRIPTION_ERROR,
    SEARCH_SUBSCRIPTION_REQUESTING,
    SEARCH_SUBSCRIPTION_SUCCESS,
    SET_FOCUS_SUBSCRIPTION,
    ERROR_SUBSCRIPTION,
    SHOW_MODAL_EDIT_SUBSCRIPTION,
    HIDDEN_MODAL_EDIT_SUBSCRIPTION,
    GET_ALL_SUBSCRIPTION_ERROR,
    GET_ALL_SUBSCRIPTION_REQUESTING,
    GET_ALL_SUBSCRIPTION_SUCCESS
} from './constants';

//obtener todas las suscripciones

export const getAllSubscriptionRequesting = (token, user) => ({
    type: GET_ALL_SUBSCRIPTION_REQUESTING,
    token,
    user
});

export const getAllSubscriptionSuccess = (subscriptions) => ({
    type: GET_ALL_SUBSCRIPTION_SUCCESS,
    subscriptions
});

export const getAllSubscriptionError = (error) => ({
    type: GET_ALL_SUBSCRIPTION_ERROR,
    error
});

//obtener estados suscripcion
export const getStatesSubscriptionRequesting = (token) => ({
    type: GET_STATES_SUBSCRIPTION_REQUESTING,
    token
});

export const getStatesSubscriptionSuccess = (states) => ({
    type: GET_STATES_SUBSCRIPTION_SUCCESS,
    states
});

export const getStatesSubscriptionError = (error) => ({
    type: GET_STATES_SUBSCRIPTION_ERROR,
    error
});

//Actualizar suscripcion

export const updateSubscriptionRequesting = (subscription, token, subscriptionId) => ({
    type: UPDATE_SUBSCRIPTION_REQUESTING,
    subscription,
    token,
    subscriptionId
});

export const updateSubscriptionSuccess = (subscription) => ({
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    subscription
});

export const updateSubscriptionError = (error) => ({
    type: UPDATE_SUBSCRIPTION_ERROR,
    error
});

//Crear suscripcion

export const createSubscriptionRequesting = (subscription, token) => ({
    type: CREATE_SUBSCRIPTION_REQUESTING,
    subscription,
    token
});

export const createSubscriptionSuccess = (subscription) => ({
    type: CREATE_SUBSCRIPTION_SUCCESS,
    subscription
});

export const createSubscriptionError = (error) => ({
    type: CREATE_SUBSCRIPTION_ERROR,
    error
});

//Cambiar estado suscripcion

export const changeStateSubscriptionRequesting = (subscriptionId, token) => ({
    type: CHANGE_STATE_SUBSCRIPTION_REQUESTING,
    subscriptionId,
    token
});
export const changeStateSubscriptionSuccess = (subscription) => ({
    type: CHANGE_STATE_SUBSCRIPTION_SUCCESS,
    subscription
});
export const changeStateSubscriptionError = (error) => ({
    type: CHANGE_STATE_SUBSCRIPTION_ERROR,
    error
});

//Buscar suscripciones
export const searchSubscriptionRequesting = (search, token) => ({
    type: SEARCH_SUBSCRIPTION_REQUESTING,
    search,
    token
});
export const searchSubscriptionSuccess = (subscriptions) => ({
    type: SEARCH_SUBSCRIPTION_SUCCESS,
    subscriptions
});
export const searchSubscriptionError = (error) => ({
    type: SEARCH_SUBSCRIPTION_ERROR,
    error
});

//obtener suscripciones
export const getSubscriptionRequesting = (paginate, filter, token) => ({
    type: GET_SUBSCRIPTION_REQUESTING,
    paginate,
    filter,
    token
});

export const getSubscriptionSuccess = (subscriptions) => ({
    type: GET_SUBSCRIPTION_SUCCESS,
    subscriptions
});

export const getSubscriptionError = (error) => ({
    type: GET_SUBSCRIPTION_ERROR,
    error
});

export const setFocusSubscription = (subscription) => ({
    type: SET_FOCUS_SUBSCRIPTION,
    subscription
});

export const errorControlSubscription = () => ({
    type: ERROR_SUBSCRIPTION,
});


export const showModalEditSubscription = () => ({
    type: SHOW_MODAL_EDIT_SUBSCRIPTION
});

export const hiddenModalEditSubscription = () => ({
    type: HIDDEN_MODAL_EDIT_SUBSCRIPTION
});

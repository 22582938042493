import {
    CHECK_AUTH,
    CHECK_AUTH_ERROR,
    CHECK_AUTH_SUCCESS,
    CLIENT_SET,
    CLIENT_UNSET,
    CONTROL_MODAL_ADMIN
} from "./constans";

const initialState = {
    token: null,
    isLogged: false,
    requesting: false,
    isLoad: false,
    adminModal: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_SET :
            return {
                ...state,
                token: action.token,
                isLogged: true
            };
        case CLIENT_UNSET :
            return {
                ...state,
                token: '',
                isLogged: false
            };
        case CHECK_AUTH :
            return {
                ...state,
                requesting: true,
                isLoad: false
            };
        case CHECK_AUTH_SUCCESS :
            return {
                ...state,
                requesting: false,
                token: action.token,
                isLogged: true,
                isLoad: true
            };
        case CHECK_AUTH_ERROR :
            return {
                ...state,
                requesting: false,
                token: '',
                isLogged: false,
                isLoad: true
            };
        case CONTROL_MODAL_ADMIN :
            return {
                ...state,
                adminModal: !state.adminModal
            };
        default:
            return state;
    }
};

export default reducer;

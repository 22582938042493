import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';

import login from './auth/login/reducer';
import client from './client/reducer';
import me from './auth/user/reducer';
import module from './module/reducer';
import category from './category/reducer';
import subcategory from './subcategory/reducer';
import subscription from './subscription/reducer';
import publication from '../redux/publication/reducer';
import config from '../redux/config/reducer';
import user from '../redux/user/reducer';
import subscriptionUser from '../redux/subscription/user/reducer';
import paymentMethods from '../redux/payment-method/reducer';
import city from '../redux/city/reducer';
import typeProfile from '../redux/typeProfile/reducer';
import typePeople from '../redux/typePeople/reducer';
import gender from '../redux/gender/reducer';
import rol from '../redux/rol/reducer';
import typeProfessional from '../redux/typeProfessional/reducer';
import review from '../redux/review/reducer';

const IndexReducer = combineReducers({
    form,
    toastr: toastrReducer,
    login,
    module,
    me,
    category,
    subcategory,
    config,
    client,
    subscription,
    publication,
    user,
    subscriptionUser,
    paymentMethods,
    city,
    typeProfile,
    typePeople,
    typeProfessional,
    gender,
    rol,
    review
});

export default IndexReducer;
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    getAllPaymentMethodSuccess,
    getAllPaymentMethodError
} from "./actions";

import {
    GET_ALL_PAYMENT_METHOD_REQUESTING
} from "./constants";

const paymentMethodsURL = `${process.env.REACT_APP_API_URL}/paymentMethods`;

//Obtener todos los metodos de pago
const getAllPaymentMethodsApi = (token) => {

    return fetch(paymentMethodsURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getAllPaymentMethodsFLow(action) {
    try {
        const {token} = action;
        const getPaymentMethods = yield call(getAllPaymentMethodsApi,token);
        yield put(getAllPaymentMethodSuccess(getPaymentMethods));
    } catch (error) {
        yield put(getAllPaymentMethodError(error));
    }
}

function* usersWatcher() {
    yield all([
        takeEvery(GET_ALL_PAYMENT_METHOD_REQUESTING, getAllPaymentMethodsFLow),
    ])
}
export default usersWatcher
import React from 'react';
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import ImagesInput from './../../file-inputs/images';
import CategorySelectComponent from './../../category/select';
import {subcategoryUpdatePutRequesting} from "../../../redux/subcategory/actions";

class ModalEditSubCategory extends React.Component {

    handleSubmit = (values) => {
        const {client: {token}, subcategoryUpdatePutRequesting} = this.props;
        subcategoryUpdatePutRequesting(token, values);
    };

    render() {
        const {handleSubmit, initialValues} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Editar subcategoria</h1>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="item-modal">
                            <label>Nombre: </label>
                            <Field type="text" className="input input-name" name={'nombre'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Foto: </label>
                            <Field name={'photos'} maxFiles={1} className={'filepond'} component={ImagesInput}/>
                        </div>

                        <div className="item-modal">
                            <label>Categoría: </label>
                            <CategorySelectComponent type={'edit'} initialValues={initialValues.categoria}/>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal"  onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal">Guardar<i className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'editSubcategory'
})(ModalEditSubCategory);

const mapStateToProps = state => ({
    subcategory: state.subcategory,
    initialValues: state.subcategory.subcategory,
    client: state.client
});

const connected = connect(mapStateToProps, {
    subcategoryUpdatePutRequesting,
})(formed);

export default connected
